import { useState, useEffect } from "react";
import { Service } from "../../types/Service";
import { Event, EventType } from "../../types/Event";
import { Pagination } from "../../types/Pagination";
import { Place } from "../../types/Place";

const apiUrl = process.env.REACT_APP_API_URL;

export interface EventModel {
	data: Event;
}

export const useServiceEvent = (id: number) => {
	const [service, setService] = useState<Service<EventModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "events/" + id)
			.then((response) => response.json())
			.then((response) => {
				setService({ status: "loaded", payload: response });
			})
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface EventsModel extends Pagination {
	data: Event[];
}

export interface EventsPaginatorModel {
	data: EventsModel;
}

export const useServiceEvents = (page: number, queryParams?: string) => {
	const [service, setService] = useState<Service<EventsPaginatorModel>>({
		status: "loading",
	});

	if (!queryParams) queryParams = "";

	useEffect(() => {
		if (!page) {
			fetch(apiUrl + "events" + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		} else {
			fetch(apiUrl + "events/page/" + page + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		}
	}, [page, queryParams]);

	return service;
};

interface EventsLatestProps {
	data: Event[];
}

export const useServiceEventsLatest = () => {
	const [service, setService] = useState<Service<EventsLatestProps>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "events/latest")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export const useServiceEventsRelated = (id: number) => {
	const [service, setService] = useState<Service<EventsLatestProps>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "events/related/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

interface EventsTypesProps {
	data: EventType[];
}

export const useServiceEventsTypes = () => {
	const [service, setService] = useState<Service<EventsTypesProps>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "events/types")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

interface EventsPlacesProps {
	data: Place[];
}

export const useServiceEventsPlaces = () => {
	const [service, setService] = useState<Service<EventsPlacesProps>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "events/places")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};
