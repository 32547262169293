import React from "react";
import { Titration } from "../../types/Titration";
import { FieldArray } from "react-final-form-arrays";

const TitrationsCheckListChildren = (props: any) => {
 const titrations: Titration[] = props.titrations;

 const toggle = (
  event: React.ChangeEvent<HTMLInputElement>,
  option: Titration
 ) => {
  if (event.target.checked) {
   if (props.fields.value && props.fields.value.length) {
    const index = props.fields.value.indexOf(option.id.toString());
    if (index >= 0) props.fields.remove(index);
    else props.fields.push(option.id.toString());
   } else {
    props.fields.push(option.id.toString());
   }
  } else {
   if (props.fields.value && props.fields.value.length) {
    const index = props.fields.value.indexOf(option.id.toString());
    if (index >= 0) props.fields.remove(index);
   }
  }
 };

 return (
  <>
   {titrations.map((titration: Titration) => {
    const checked = props.fields.value?.indexOf(titration.id.toString()) >= 0;

    return (
     <div key={titration.id} className="form-check">
      <input
       checked={checked}
       className="form-check-input"
       type="checkbox"
       id={props.fields.name + titration.id}
       onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        toggle(event, titration)
       }
      />

      <label
       className="form-check-label"
       htmlFor={props.fields.name + titration.id}
      >
       {titration.title}
      </label>
     </div>
    );
   })}
  </>
 );
};

export type TitrationsCheckListProps = {
 name: string;
 titrations: Titration[];
};

export default function TitrationsCheckListField(
 props: TitrationsCheckListProps
) {
 return (
  <div className="form-check-list">
   <FieldArray
    name={props.name}
    component={TitrationsCheckListChildren}
    titrations={props.titrations}
   />
  </div>
 );
}
