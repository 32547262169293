import React, { useState, useEffect } from "react";
import NewsletterBanner from "../../components/Layout/NewsletterBanner";
import Metatags from "../../components/Layout/Metatags";
import Header from "../../components/Layout/Header";
import PageContainer from "../../components/Layout/PageContainer";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import { Project } from "../../types/Project";
import CardNewsletter from "../../components/Cards/CardNewsletter";
import { useServiceProjectDetail } from "./Services";
import Loader from "../../components/Layout/Loader";
import NotFound from "../Errors/NotFound";
import Page from "../../components/Layout/Page";

export default function ProjectDetail() {
	const { id } = useParams<{ id: string }>();

	const [project, setProject] = useState<Project>();
	const service = useServiceProjectDetail(id);

	let loaders = [];
	for (let i = 0; i < 4; i++) {
		loaders.push(i);
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [id]);

	useEffect(() => {
		if (service.status === "loaded") {
			setProject(service.payload.data);
		}
	}, [service]);

	return (
		<Page service={service} item={project}>
			<>
				<Header />

				{service.status === "loaded" && !project && <NotFound />}

				<main className="MagazineDetail">
					<PageContainer>
						{service.status === "loaded" && project ? (
							<>
								<Metatags
									title={project.title}
									description={project.description}
								/>

								<div className="container">
									<div className="row">
										<div className="col-md-12">
											<nav>
												<ol className="breadcrumb">
													<li className="breadcrumb-item">
														<Link to="/">Início</Link>
													</li>
													<li className="breadcrumb-item">Projetos</li>
													<li className="breadcrumb-item active">
														{project.title}
													</li>
												</ol>
											</nav>
										</div>
									</div>

									<div className="row">
										<div className="col-md-8">
											<h1 className="display-1">{project.title}</h1>
										</div>
									</div>

									{project.description && (
										<div className="row mb-5">
											<div className="col-md-8">
												<p
													className="lead-2"
													dangerouslySetInnerHTML={{
														__html: project.description,
													}}
												/>
											</div>
										</div>
									)}

									{project.newsletters && project.newsletters.length > 0 && (
										<div className="project.newsletters">
											<div className="row">
												<div className="col-md-12">
													<h3>Confira os boletins</h3>
												</div>
											</div>

											<div className="row">
												{project.newsletters?.map((newsletter) => (
													<div key={newsletter.id} className="col-md-6">
														<CardNewsletter newsletter={newsletter} />
													</div>
												))}
											</div>
										</div>
									)}
								</div>
							</>
						) : (
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="loader-description mb-5">
											<Loader className="line-1" />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-9">
										<div className="loader-title">
											<Loader className="line-1" />

											<Loader className="line-2" />
										</div>

										<div className="loader-description">
											<Loader className="line-1" />

											<Loader className="line-2" />

											<Loader className="line-1" />

											<Loader className="line-2" />

											<Loader className="line-3" />
										</div>
									</div>
								</div>

								<hr />

								<div className="row">
									<div className="col-md-12">
										<div className="loader-title">
											<Loader className="line-1" />
										</div>
									</div>
								</div>

								<div className="row">
									{loaders.map((loader) => (
										<div key={loader} className="col-md-6">
											<Loader className="loader-card-library mb-3" />
										</div>
									))}
								</div>

								<div className="row">
									<div className="col-md-6"></div>
								</div>
							</div>
						)}
					</PageContainer>
				</main>

				<NewsletterBanner />
				<Footer />
			</>
		</Page>
	);
}
