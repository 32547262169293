import React from "react";

interface NoResultProps {
	filter?: boolean;
}

export default function NoResult(props: NoResultProps) {
	return (
		<div className="no-result">
			<h2>Nenhum resultado encontrado...</h2>

			{/* {props.filter && (
				<>
					<p>Tente limpar seus filtros aplicados!</p>

					<button className="btn btn-lg btn-primary-outline">
						Limpar filtros
					</button>
				</>
			)} */}
		</div>
	);
}
