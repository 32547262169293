import React, { useState, useEffect } from "react";
import CardVolume from "../../../components/Cards/CardVolume";
import CardEbook from "../../../components/Cards/CardEbook";
import CardThesi from "../../../components/Cards/CardThesi";
import Paginator from "../../../components/Layout/Paginator";
import { Volume, CedocItemType } from "../../../types/Volume";
import { Ebook } from "../../../types/Ebook";
import { Thesi } from "../../../types/Thesi";
import { Audiovisual } from "../../../types/Audiovisual";
import { Magazine } from "../../../types/Magazine";
import { Pagination } from "../../../types/Pagination";
import {
	useServiceLibrary,
	useServiceLibraryAudiovisualTypes,
	useServiceLibraryCourses,
	useServiceLibraryInstitutions,
	useServiceLibraryLanguages,
	useServiceLibraryTitrations,
} from "../Services";
import LanguagesCheckListField from "../../../components/Filter/LanguagesCheckListField";
import { Language } from "../../../types/Language";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FormApi } from "final-form";
import NoResult from "../../../components/Layout/NoResult";
import Loader from "../../../components/Layout/Loader";
import { usePrevious } from "react-use";
import CardAudiovisual from "../../../components/Cards/CardAudiovisual";
import CedocItemTypeCheckListField from "../../../components/Filter/CedocItemTypeCheckListField";
import { Institution } from "../../../types/Institution";
import { CedocAudiovisualType } from "../../../types/CedocAudiovisualType";
import AudiovisualsTypesCheckListField from "../../../components/Filter/AudiovisualsTypesCheckListField";
import { Titration } from "../../../types/Titration";
import { Course } from "../../../types/Course";
import TitrationsCheckListField from "../../../components/Filter/TitrationsCheckListField";
import { CedocVolume } from "../../../types/CedocVolume";
import { CedocBook } from "../../../types/CedocBook";
import { CedocThesi } from "../../../types/CedocThesi";
import { CedocAudiovisual } from "../../../types/CedocAudiovisual";
import CardLegislation from "../../../components/Cards/CardLegislation";
import { Legislation } from "../../../types/Legislation";
import { Article } from "../../../types/Article";
import CardMagazine from "../../../components/Cards/CardMagazine";
import CardArticle from "../../../components/Cards/CardArticle";

interface AllFilterModel {
	q?: string;
	itemType?: CedocItemType[];
	institution?: string;
	languages?: string[];
	audiovisualsTypes?: string[];
	titrations?: string[];
	course?: string;
}

type LibraryItems =
	| Volume
	| CedocVolume
	| Ebook
	| CedocBook
	| Thesi
	| CedocThesi
	| Audiovisual
	| CedocAudiovisual
	| Magazine
	| Article
	| Legislation;

export interface AllContentProps {
	consultationText: string;
}

export default function AllContent(props: AllContentProps) {
	const { consultationText } = props;

	const history = useHistory();
	const location = useLocation();
	const { page } = useParams();
	const queryParams = location.search;
	const prev = usePrevious(location.pathname);
	const [prevIncludesPath, setPrevIncludesPath] = useState<boolean>(false);

	const [all, setAll] = useState<Array<LibraryItems>>();
	const [allPaginatorData, setAllPaginatorData] = useState<Pagination>();
	const [languages, setLanguages] = useState<Language[]>();
	const [institutions, setInstitutions] = useState<Institution[]>();
	const [audiovisualsTypes, setAudiovisualsTypes] = useState<
		CedocAudiovisualType[]
	>();
	const [courses, setCourses] = useState<Course[]>();
	const [titrations, setTitrations] = useState<Titration[]>();
	const [filtered, setFiltered] = useState<boolean>(false);

	const serviceAll = useServiceLibrary(page, queryParams);
	const serviceLanguages = useServiceLibraryLanguages();
	const serviceInstitutions = useServiceLibraryInstitutions();
	const serviceAudiovisualTypes = useServiceLibraryAudiovisualTypes();
	const serviceCourses = useServiceLibraryCourses();
	const serviceTitrations = useServiceLibraryTitrations();

	const searchParams = new URLSearchParams(queryParams);

	const initialFilterData: AllFilterModel = {
		q: searchParams.get("q") || undefined,
		itemType: searchParams.get("itemType")?.split(",") as CedocItemType[],
		institution: searchParams.get("institution") || undefined,
		languages: searchParams.get("languages")?.split(","),
		audiovisualsTypes: searchParams.get("audiovisualsTypes")?.split(","),
		course: searchParams.get("course") || undefined,
		titrations: searchParams.get("titrations")?.split(","),
	};

	let loaders = [];
	for (let i = 0; i < 8; i++) {
		loaders.push(i);
	}

	const onFilter = (values: AllFilterModel, form: FormApi) => {
		const searchParams = new URLSearchParams(queryParams);

		searchParams.delete("q");
		if (values.q) {
			searchParams.set("q", values.q);
		}

		searchParams.delete("itemType");
		if (values.itemType?.length) {
			searchParams.set("itemType", values.itemType.toString());
		}

		searchParams.delete("audiovisualsTypes");
		if (values.audiovisualsTypes?.length) {
			searchParams.set(
				"audiovisualsTypes",
				values.audiovisualsTypes.toString()
			);
		}

		searchParams.delete("institution");
		if (values.institution && values.institution !== "all") {
			searchParams.set("institution", values.institution);
		}

		searchParams.delete("titrations");
		if (values.titrations?.length) {
			searchParams.set("titrations", values.titrations.toString());
		}

		searchParams.delete("course");
		if (values.course && values.course !== "all") {
			searchParams.set("course", values.course);
		}

		searchParams.delete("languages");
		if (values.languages?.length) {
			searchParams.set("languages", values.languages.toString());
		}

		return history.push("/cedoc/todos?" + searchParams.toString());
	};

	useEffect(() => {
		if (
			initialFilterData.q ||
			initialFilterData.institution ||
			initialFilterData.languages ||
			initialFilterData.audiovisualsTypes ||
			initialFilterData.course ||
			initialFilterData.itemType ||
			initialFilterData.titrations
		) {
			setFiltered(true);
		}
	}, [initialFilterData]);

	useEffect(() => {
		if (serviceAll.status === "loaded") {
			const paginatorData: Pagination = {
				current_page: serviceAll.payload.data.current_page,
				first_page_url: serviceAll.payload.data.first_page_url,
				from: serviceAll.payload.data.from,
				last_page: serviceAll.payload.data.last_page,
				last_page_url: serviceAll.payload.data.last_page_url,
				next_page_url: serviceAll.payload.data.next_page_url,
				path: serviceAll.payload.data.path,
				per_page: serviceAll.payload.data.per_page,
				prev_page_url: serviceAll.payload.data.prev_page_url,
				to: serviceAll.payload.data.to,
				total: serviceAll.payload.data.total,
			};

			setAllPaginatorData(paginatorData);
			setAll(serviceAll.payload.data.data);
		}
	}, [serviceAll]);

	useEffect(() => {
		if (serviceAudiovisualTypes.status === "loaded") {
			setAudiovisualsTypes(serviceAudiovisualTypes.payload.data);
		}
	}, [serviceAudiovisualTypes]);

	useEffect(() => {
		if (serviceInstitutions.status === "loaded") {
			setInstitutions(serviceInstitutions.payload.data);
		}
	}, [serviceInstitutions]);

	useEffect(() => {
		if (serviceCourses.status === "loaded") {
			setCourses(serviceCourses.payload.data);
		}
	}, [serviceCourses]);

	useEffect(() => {
		if (serviceTitrations.status === "loaded") {
			setTitrations(serviceTitrations.payload.data);
		}
	}, [serviceTitrations]);

	useEffect(() => {
		if (serviceLanguages.status === "loaded") {
			setLanguages(serviceLanguages.payload.data);
		}
	}, [serviceLanguages]);

	useEffect(() => {
		if (prevIncludesPath && page) window.scrollTo(0, 0);
	}, [page, prevIncludesPath]);

	useEffect(() => {
		setPrevIncludesPath(!!prev?.includes("todos"));
	}, [prev]);

	return (
		<div className="row">
			<div className="col-md-12">
				<div className="container p-0">
					<div className="row">
						<div className="col-md-4">
							<div className="card filter">
								<div className="card-filter">
									<Form<AllFilterModel>
										onSubmit={onFilter}
										mutators={{ ...arrayMutators }}
										initialValues={initialFilterData}
										render={({ handleSubmit }) => (
											<form onSubmit={handleSubmit}>
												<div className="card-header">
													<h5>Pesquisar</h5>
												</div>

												<div className="card-body">
													<Field
														type="text"
														name="q"
														component="input"
														className="form-control"
														placeholder="Busque por título, assunto, palavras-chave..."
													/>

													<h5 className="mt-2">Formato</h5>

													<CedocItemTypeCheckListField name="itemType" />

													{audiovisualsTypes && audiovisualsTypes.length > 1 && (
														<>
															<h5 className="">Tipo de mídia</h5>

															<AudiovisualsTypesCheckListField
																name="audiovisualsTypes"
																items={audiovisualsTypes}
															/>
														</>
													)}

													{institutions && institutions.length > 1 && (
														<>
															<h5 className="mt-2">Instituição</h5>

															<Field
																name="institution"
																type="select"
																initialValue={"all"}
																render={({ input, meta }) => (
																	<div className="select-wrapper">
																		<select {...input} className="form-control">
																			<option value={"all"}>Todas</option>

																			{institutions.map((institution) => (
																				<option
																					key={institution.id}
																					value={institution.id}
																				>
																					{institution.title}
																				</option>
																			))}
																		</select>
																	</div>
																)}
															/>
														</>
													)}

													{courses && courses.length > 1 && (
														<>
															<h5 className="mt-2">Curso</h5>

															<Field
																name="course"
																type="select"
																initialValue={"all"}
																render={({ input, meta }) => (
																	<div className="select-wrapper">
																		<select {...input} className="form-control">
																			<option value={"all"}>Todos</option>

																			{courses.map((course) => (
																				<option
																					key={course.id}
																					value={course.id}
																				>
																					{course.title}
																				</option>
																			))}
																		</select>
																	</div>
																)}
															/>
														</>
													)}

													{titrations && titrations.length > 1 && (
														<>
															<h5 className="mt-2">Titulação</h5>

															<TitrationsCheckListField
																name="titrations"
																titrations={titrations}
															/>
														</>
													)}

													{languages && languages.length > 1 && (
														<>
															<h5 className="">Idioma</h5>

															<LanguagesCheckListField
																name="languages"
																languages={languages}
															/>
														</>
													)}

													<button
														className="btn btn-primary"
														onClick={handleSubmit}
													>
														Filtrar
													</button>
												</div>
											</form>
										)}
									/>
								</div>
							</div>
						</div>

						<div className="col-md-8">
							{all && allPaginatorData ? (
								all?.length > 0 ? (
									<>
										{all?.map((item: LibraryItems, index: number) => {
											switch (item.table_name) {
												case "magazines":
													return (
														<CardMagazine
															large
															key={index}
															magazine={item as Magazine}
														/>
													);

												case "volumes":
													return (
														<CardVolume
															large
															key={index}
															volume={item as Volume}
															consultationText={consultationText}
														/>
													);

												case "cedoc_volumes":
													return (
														<CardVolume
															large
															key={index}
															volume={item as CedocVolume}
															consultationText={consultationText}
														/>
													);

												case "articles":
													return (
														<CardArticle
															large
															key={index}
															article={item as Article}
															showImage
														/>
													);

												case "ebooks":
													return (
														<CardEbook
															large
															key={index}
															book={item as Ebook}
															consultationText={consultationText}
														/>
													);

												case "cedoc_books":
													return (
														<CardEbook
															large
															key={index}
															book={item as CedocBook}
															consultationText={consultationText}
														/>
													);

												case "thesis":
													return (
														<CardThesi
															large
															key={index}
															thesi={item as Thesi}
															consultationText={consultationText}
														/>
													);

												case "cedoc_thesis":
													return (
														<CardThesi
															large
															key={index}
															thesi={item as CedocThesi}
															consultationText={consultationText}
														/>
													);

												case "videos":
													return (
														<CardAudiovisual
															large
															key={index}
															audiovisual={item as Audiovisual}
															consultationText={consultationText}
														/>
													);

												case "cedoc_audiovisuals":
													return (
														<CardAudiovisual
															large
															key={index}
															audiovisual={item as CedocAudiovisual}
															consultationText={consultationText}
														/>
													);

												case "legislations":
													return (
														<CardLegislation
															large
															key={index}
															legislation={item as Legislation}
														/>
													);
											}

											return null;
										})}
									</>
								) : (
									<NoResult filter={filtered} />
								)
							) : (
								loaders?.map((loader) => (
									<Loader
										key={loader}
										className="loader-card-library card-large"
									/>
								))
							)}
						</div>
					</div>

					<div className="row">
						<div className="col-md-12">
							{all && all.length > 0 && allPaginatorData && (
								<Paginator
									path={"/cedoc/todos"}
									pagination={allPaginatorData}
									queryParams={queryParams}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
