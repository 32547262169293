import React from "react";

export default function IllustrationHomeLibrary() {
    return (
        <svg
            className="illustration illustration-home-library"
            width="425"
            height="504"
            viewBox="0 0 425 504"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M212.948 482.479L265.585 485.985C268.13 486.367 270.722 486.118 273.154 485.257C275.855 483.721 278.081 481.405 279.572 478.58C323.584 410.108 339.64 326.043 375.352 252.379C379.77 243.249 384.539 234.203 387.167 224.342C390.894 210.267 390.092 195.356 389.259 180.787C389.318 179.25 388.915 177.731 388.107 176.446C387.057 175.4 385.726 174.722 384.297 174.507C358.099 167.709 331.318 163.758 304.376 162.718C303.135 166.328 304.684 170.165 304.565 174.022C301.443 270.597 261.468 349.873 226.168 431.832C219.423 447.446 209.933 465.586 212.948 482.479Z"
                fill="#67639B"
            />
            <path
                d="M124.021 27.2795L170.034 35.2768L101.771 366.807L77.9721 457.458L26.7795 457.525L124.021 27.2795Z"
                fill="#D89CC6"
            />
            <path
                d="M89.1877 132.868C94.4243 91.4016 98.2482 48.2624 117.944 11.952C123.943 12.5322 129.829 14.0473 135.412 16.4476C132.759 27.0079 130.107 37.5763 127.455 48.1529C102.742 146.4 81.0583 245.431 62.4026 345.248C56.4482 377.188 50.7943 409.115 41.3941 440.075C39.351 446.693 33.0748 476.452 24.7585 475.333C12.7843 473.706 24.9727 442.948 26.4076 437.306C42.8753 372.751 58.7337 308.026 71.3877 242.556C78.4572 206.206 84.5454 169.654 89.1877 132.868Z"
                fill="#67639B"
            />
            <path
                d="M140.62 130.743C145.856 89.2768 149.657 46.1386 169.353 9.82822C175.359 10.405 181.254 11.9199 186.843 14.3229C184.191 24.8832 181.539 35.4516 178.887 46.0281C154.174 144.275 132.483 243.307 113.811 343.125C107.88 375.063 102.226 406.99 92.803 437.951C90.7598 444.57 84.5068 474.327 76.1674 473.209C64.2163 471.582 76.4047 440.823 77.8395 435.181C94.1916 370.631 110.096 305.904 122.82 240.431C129.889 204.082 135.977 167.529 140.62 130.743Z"
                fill="#67639B"
            />
            <path
                d="M128.532 276.643C131.903 253.392 141.908 229.208 144.008 206.034C145.955 184.573 150.534 163.48 157.631 143.279C172.25 101.815 171.158 63.0475 181.958 22.2287C182.137 21.0423 182.677 19.9506 183.497 19.1177C184.248 18.6775 185.086 18.4297 185.946 18.3941C186.806 18.3586 187.662 18.5364 188.446 18.9132L257.775 40.6482C252.444 62.4444 243.914 80.2769 237.796 100.399L211.538 188.153C198.293 232.584 185.087 276.842 173.598 321.736C160.955 371.213 150.683 421.445 135.054 469.85C134.839 470.946 134.419 471.985 133.816 472.906C133.214 473.827 132.442 474.611 131.546 475.212C130.115 475.722 128.573 475.786 127.106 475.395C112.803 472.914 87.9244 475.186 86.3509 455.601C84.899 437.278 93.2968 414.575 97.8365 397.176C108.663 357.014 122.008 317.797 128.532 276.643Z"
                fill="#D89CC6"
            />
            <path
                d="M185.546 489.267C187.478 490.082 189.548 490.474 191.626 490.417C193.705 490.359 195.748 489.855 197.631 488.934C199.514 488.014 201.196 486.696 202.573 485.064C203.951 483.431 204.995 481.517 205.641 479.44L264.364 291.559C267.862 280.346 271.36 269.133 274.554 257.835C284.671 222.36 292.185 186.114 299.279 149.812C299.69 147.722 299.698 145.565 299.302 143.469C298.906 141.372 298.114 139.379 296.974 137.607C295.833 135.836 294.368 134.321 292.663 133.154C290.958 131.987 289.05 131.191 287.051 130.813C274.143 128.464 261.473 124.824 249.213 119.943C247.181 119.137 245.01 118.797 242.847 118.946C240.683 119.096 238.577 119.732 236.671 120.811C234.764 121.89 233.101 123.387 231.793 125.202C230.485 127.016 229.563 129.106 229.089 131.331C224.026 155.115 215.52 178.017 207.571 200.921C203.628 211.368 200.601 222.175 198.527 233.208C197.591 239.146 197.168 245.186 196.069 251.106C194.346 260.418 190.96 269.285 188.341 278.243C177.242 316.856 173.17 352.862 158.776 390.246C155.45 398.891 153.634 411.571 149.46 419.886C143.652 431.243 129.892 455.923 142.009 468.295C148.466 474.879 160.109 478.03 168.205 481.67C173.967 484.308 179.631 486.829 185.546 489.267Z"
                fill="#67639B"
            />
            <path
                d="M239.137 371.737C256.688 322.765 276.814 274.418 286.974 223.167C293.408 191.061 295.978 157.945 306.024 126.909C307.812 121.399 311.687 114.827 317.161 116.112C320.647 116.943 322.634 120.762 323.893 124.318C334.062 152.885 325.854 184.674 316.551 213.509C298.505 269.572 276.597 324.112 255.7 379.025C245.064 406.989 234.663 435.041 225.158 463.47C222.871 470.342 218.973 495.419 211.027 495.991C197.172 497.051 206.255 474.685 207.84 469.305C217.197 436.364 227.629 403.841 239.137 371.737Z"
                fill="#D89CC6"
            />
            <path
                d="M284.318 461.124C290.102 450.497 295.333 439.546 299.99 428.32C305.014 415.922 309.032 403.274 313.235 390.618C333.105 330.481 356.436 271.689 379.769 212.946C387.854 192.767 395.978 172.391 408.675 155.069C412.884 164.427 409.77 175.405 406.558 185.143C387.594 242.715 366.973 299.64 344.696 355.918C334.563 381.472 324.077 406.894 311.886 431.412C302.583 450.154 292.612 469.533 280.618 486.509C278.425 489.501 270.482 497.411 268.439 489.451C267.278 484.891 281.867 465.711 284.318 461.124Z"
                fill="#D89CC6"
            />
        </svg>
    );
}
