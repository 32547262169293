import React from "react";
import { Helmet } from "react-helmet";

interface MetatagsProps {
	title: string;
	description?: string;
	image?: string;
	keywords?: string;
}

const DEFAULT_TITLE: string = "Instituto de Estudos de Gênero";

export default function Metatags(props: MetatagsProps) {
	return (
		<Helmet>
			<title>
				{props.title === DEFAULT_TITLE
					? DEFAULT_TITLE
					: props.title + " | " + DEFAULT_TITLE}
			</title>

			{props.description && (
				<meta name="description" content={props.description} />
			)}

			{props.keywords && <meta name="keywords" content={props.keywords} />}

			<meta property="og:title" content={props.title} />

			{props.description && (
				<meta property="og:description" content={props.description} />
			)}

			<meta property="og:url" content={window.location.href} />

			{props.image && <meta property="og:image" content={props.image} />}
		</Helmet>
	);
}
