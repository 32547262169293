import React from "react";
import PageNotFound from "../../pages/Errors/PageNotFound";
import { Service } from "../../types/Service";

interface PageProps {
	children: JSX.Element;
	service: Service<any>;
	item: any;
}

export default function Page(props: PageProps) {
	const { children, service, item } = props;

	const notItem = (item: any) => !item || item.msg === "not found";

	if (service.status === "error") {
		return children;
	} else if (service.status === "loaded" && notItem(item)) {
		return <PageNotFound />;
	} else {
		return children;
	}
}
