import { FileUploaded } from "../types/FileUploaded";

export function getFiles(t: string): FileUploaded[] {
	return JSON.parse(t) as FileUploaded[];
}

export function getFileLink(t: FileUploaded | undefined): string | false {
	if (t === undefined || t === null) {
		return false;
	}

	return getFiles(t as unknown as string)[0].download_link;
}

export function getFirstFileLink(
	t: FileUploaded[] | undefined
): string | false {
	if (t === undefined || t === null) {
		return false;
	}

	return getFiles(t as unknown as string)?.[0]?.download_link;
}
