import React from "react";
import { Icon } from "../../types/Icon";

export default function IconFacebook(props: Icon) {
 return (
  <svg
   className="icon icon-facebook"
   width={props.width || "10"}
   height={props.height || "18"}
   viewBox={
    props.width && props.height
     ? "0 0 " + props.width + " " + props.height
     : "0 0 10 18"
   }
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    d="M9.62343 0.0037452L7.22488 0C4.5302 0 2.78878 1.73871 2.78878 4.42982V6.47227H0.377148C0.168755 6.47227 0 6.63668 0 6.83949V9.79876C0 10.0016 0.168947 10.1658 0.377148 10.1658H2.78878V17.633C2.78878 17.8358 2.95753 18 3.16593 18H6.31242C6.52081 18 6.68957 17.8356 6.68957 17.633V10.1658H9.50932C9.71772 10.1658 9.88647 10.0016 9.88647 9.79876L9.88763 6.83949C9.88763 6.74211 9.84779 6.64886 9.77718 6.57994C9.70656 6.51103 9.61035 6.47227 9.51029 6.47227H6.68957V4.74086C6.68957 3.90868 6.89334 3.48622 8.00727 3.48622L9.62304 3.48566C9.83125 3.48566 10 3.32124 10 3.11863V0.370775C10 0.168347 9.83144 0.00411972 9.62343 0.0037452Z"
    className="fill"
   />
  </svg>
 );
}
