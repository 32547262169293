const parseDateFull = (start: string, end?: string): string | null => {
	const months = [
		"Janeiro",
		"Fevereiro",
		"Março",
		"Abril",
		"Maio",
		"Junho",
		"Julho",
		"Agosto",
		"Setembro",
		"Outubro",
		"Novembro",
		"Dezembro",
	];

	if (end && end !== start) {
		const startDate = formatDate(start);
		const endDate = formatDate(end);

		const startYear = ("000" + startDate.getFullYear()).slice(-4);
		const startMonth = months[startDate.getMonth()];
		const startDay = ("0" + startDate.getDate()).slice(-2);

		const endYear = ("000" + endDate.getFullYear()).slice(-4);
		const endMonth = months[endDate.getMonth()];
		const endDay = ("0" + endDate.getDate()).slice(-2);

		if (startDate.getFullYear() === endDate.getFullYear()) {
			if (startDate.getMonth() === endDate.getMonth()) {
				return `De ${startDay} à ${endDay} de ${startMonth} de ${startYear}`;
			} else {
				return `De ${startDay} de ${startMonth} à ${endDay} de ${endMonth} de ${startYear}`;
			}
		} else {
			return `${startDay} de ${startMonth} de ${startYear} à ${endDay} de ${endMonth} de ${endYear}`;
		}
	}

	const startDate = formatDate(start);

	const year = ("000" + startDate.getFullYear()).slice(-4);
	const month = months[startDate.getMonth()];
	const day = ("0" + startDate.getDate()).slice(-2);

	return `${day} de ${month} de ${year}`;
};

const parseDate = (value: Date): string | null => {
	if (!value) {
		return null;
	}

	const year = ("000" + value.getFullYear()).slice(-4);
	const month = ("0" + (value.getMonth() + 1)).slice(-2);
	const day = ("0" + value.getDate()).slice(-2);

	return `${day}/${month}/${year}`;
};

const formatDate = (value: string): Date => {
	const dateParts = value.split("-");
	const date = new Date(
		parseInt(dateParts[0], 10),
		parseInt(dateParts[1], 10) - 1,
		parseInt(dateParts[2], 10)
	);
	return date;
};

export const createTime = (value: string) => {
	const time = value.split(":");
	return new Date(
		0,
		0,
		0,
		parseInt(time[0], 10),
		parseInt(time[1], 10),
		parseInt(time[2], 10)
	);
};

export const parseTime = (value: Date): string => {
	const hour = value.getHours();
	const minute = value.getMinutes();

	return `${hour}:${minute === 0 ? "00" : minute}h`;
};

export const formatTime = (start: string, end?: string): string => {
	if (end && end !== start) {
		start.split(":");

		const startParts = start.split(":");
		const startDate = new Date(
			0,
			0,
			0,
			parseInt(startParts[0], 10),
			parseInt(startParts[1], 10),
			parseInt(startParts[2], 10)
		);

		const endParts = end.split(":");
		const endDate = new Date(
			0,
			0,
			0,
			parseInt(endParts[0], 10),
			parseInt(endParts[1], 10),
			parseInt(endParts[2], 10)
		);

		return "Das " + parseTime(startDate) + " às " + parseTime(endDate);
	}

	const timeParts = start.split(":");
	const time = new Date(
		0,
		0,
		0,
		parseInt(timeParts[0], 10),
		parseInt(timeParts[1], 10),
		parseInt(timeParts[2], 10)
	);

	return "Inicia às " + parseTime(time);
};

export const dateFormat = (value: string): string | null => {
	return value && parseDate(formatDate(value));
};

export const dateFormatFull = (start: string, end?: string): string | null => {
	return parseDateFull(start, end);
};

export const timeFormat = (start: string, end?: string): string | null => {
	return formatTime(start, end);
};
