import React, { useState, useEffect } from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import PageContainer from "../../components/Layout/PageContainer";
import { New } from "../../types/New";
import { dateFormatFull } from "../../util/DateTime";
import { useServiceNewsRelated, useServiceNew } from "./Sevices";
import Slider from "../../components/Layout/Slider";
import CardNew from "../../components/Cards/CardNew";
import NewsletterBanner from "../../components/Layout/NewsletterBanner";
import Loader, { useLoaderCounter } from "../../components/Layout/Loader";
import Metatags from "../../components/Layout/Metatags";
import { ButtonShare } from "../../components/Layout/ButtonShare";
import Page from "../../components/Layout/Page";

export default function NewDetail(props: any) {
	const id = props.match.params.id;

	const [newDetail, setNewDetail] = useState<New>();
	const [otherNews, setOtherNews] = useState<New[]>();
	const loaders = useLoaderCounter(3);

	const serviceNew = useServiceNew(id);
	const serviceNewRelated = useServiceNewsRelated(id);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [id]);

	useEffect(() => {
		if (serviceNew.status === "loaded") {
			setNewDetail(serviceNew.payload.data);
		}
	}, [serviceNew]);

	useEffect(() => {
		if (serviceNewRelated.status === "loaded") {
			setOtherNews(serviceNewRelated.payload.data);
		}
	}, [serviceNewRelated]);

	return (
		<Page service={serviceNew} item={newDetail}>
			<>
				{newDetail && (
					<Metatags
						title={newDetail.title}
						description={newDetail.description}
						image={
							newDetail.image &&
							process.env.REACT_APP_STORAGE_URL + newDetail.image
						}
					/>
				)}

				<Header />
				<main className="NewDetail">
					<PageContainer>
						{serviceNew.status === "loaded" && newDetail && (
							<>
								{newDetail.image && (
									<img
										className="new-image"
										src={process.env.REACT_APP_STORAGE_URL + newDetail.image}
										alt={newDetail.title}
									/>
								)}

								<div className="container">
									<div className="row">
										<div className="col-md-9">
											<div className="label">
												{"Publicada em " + dateFormatFull(newDetail.created_at)}
											</div>

											<h1 className="display-1">{newDetail.title}</h1>

											<p className="lead-1">{newDetail.description}</p>
										</div>
									</div>

									<hr />

									<div className="row">
										<div className="col-md-9">
											<div
												className="headings-formation"
												dangerouslySetInnerHTML={{
													__html: newDetail.body,
												}}
											/>
										</div>
									</div>

									<div className="row mt-3">
										<div className="col-md-9">
											<ButtonShare
												kind="text"
												size="lg"
												link={window.location.pathname}
												text="Compartilhar notícia"
											/>
										</div>
									</div>
								</div>
							</>
						)}

						{serviceNew.status === "loading" && (
							<>
								<Loader className="loader-image" />

								<div className="container">
									<div className="row">
										<div className="col-md-9">
											<div className="loader-title">
												<Loader className="line-1" />

												<Loader className="line-2" />
											</div>

											<div className="loader-description">
												<Loader className="line-1" />

												<Loader className="line-2" />

												<Loader className="line-3" />
											</div>
										</div>
									</div>

									<hr />

									<div className="row">
										<div className="col-md-9">
											<div className="loader-paragraph">
												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-2" />
											</div>

											<div className="loader-paragraph">
												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-2" />
											</div>

											<div className="loader-paragraph">
												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-2" />
											</div>
										</div>
									</div>
								</div>
							</>
						)}

						<div className="container">
							<hr />

							<div className="row">
								<div className="col-md-12">
									<h2 className="mt-4">Continue lendo</h2>
								</div>
							</div>

							{otherNews && otherNews.length > 0 ? (
								<div className="row">
									<div className="col-md-12">
										<Slider numberOf={3}>
											{otherNews?.map((niu) => (
												<CardNew key={niu.id} new={niu} />
											))}
										</Slider>
									</div>
								</div>
							) : (
								<div className="row">
									{loaders.map((key) => (
										<div className="col-md-4" key={key}>
											<Loader className="loader-card-library" />
										</div>
									))}
								</div>
							)}
						</div>
					</PageContainer>
				</main>

				<NewsletterBanner />
				<Footer />
			</>
		</Page>
	);
}
