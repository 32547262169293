import React from "react";
import { EventType } from "../../types/Event";
import { FieldArray } from "react-final-form-arrays";

const EventTypesCheckListChildren = (props: any) => {
	const eventTypes: EventType[] = props.eventTypes;

	const toggle = (
		event: React.ChangeEvent<HTMLInputElement>,
		option: EventType
	) => {
		if (event.target.checked) {
			if (props.fields.value && props.fields.value.length) {
				const index = props.fields.value.indexOf(option.id.toString());
				if (index >= 0) props.fields.remove(index);
				else props.fields.push(option.id.toString());
			} else {
				props.fields.push(option.id.toString());
			}
		} else {
			if (props.fields.value && props.fields.value.length) {
				const index = props.fields.value.indexOf(option.id.toString());
				if (index >= 0) props.fields.remove(index);
			}
		}
	};

	return (
		<>
			{eventTypes.map((eventType: EventType) => {
				const checked =
					props.fields.value?.indexOf(eventType.id.toString()) >= 0;

				return (
					<div key={eventType.id} className="form-check">
						<input
							checked={checked}
							className="form-check-input"
							type="checkbox"
							id={props.fields.name + eventType.id}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
								toggle(event, eventType)
							}
						/>

						<label
							className="form-check-label"
							htmlFor={props.fields.name + eventType.id}
						>
							{eventType.name}
						</label>
					</div>
				);
			})}
		</>
	);
};

export type EventTypesCheckListProps = {
	name: string;
	eventTypes: EventType[];
};

export default function EventTypesCheckListField(
	props: EventTypesCheckListProps
) {
	return (
		<div className="form-check-list">
			<FieldArray
				name={props.name}
				component={EventTypesCheckListChildren}
				eventTypes={props.eventTypes}
			/>
		</div>
	);
}
