import React, { useState, useEffect } from "react";
import Header from "../../../components/Layout/Header";
import Footer from "../../../components/Layout/Footer";
import PageContainer from "../../../components/Layout/PageContainer";
import { useServiceBookDetail, useServiceBooksRelated } from "../Services";
import { Link, useParams } from "react-router-dom";
import NewsletterBanner from "../../../components/Layout/NewsletterBanner";
import { Ebook } from "../../../types/Ebook";
import Slider from "../../../components/Layout/Slider";
import CardEbook from "../../../components/Cards/CardEbook";
import Metatags from "../../../components/Layout/Metatags";
import Loader, { useLoaderCounter } from "../../../components/Layout/Loader";
import NotFound from "../../Errors/NotFound";
import { getFileLink } from "../../../util/FileUploaded";
import { ButtonShare } from "../../../components/Layout/ButtonShare";
import Page from "../../../components/Layout/Page";

export default function BookDetail() {
	const { id } = useParams<{ id: string }>();

	const [ebook, setBook] = useState<Ebook>();
	const [relateds, setRelateds] = useState<Ebook[]>();
	const service = useServiceBookDetail(id);
	const serviceRelated = useServiceBooksRelated(id);
	const loaders = useLoaderCounter(2);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [id]);

	useEffect(() => {
		if (service.status === "loaded") {
			setBook(service.payload.data);
		}
	}, [service]);

	useEffect(() => {
		if (serviceRelated.status === "loaded") {
			setRelateds(serviceRelated.payload.data);
		}
	}, [serviceRelated]);

	return (
		<Page service={service} item={ebook}>
			<>
				<Header />

				{service.status === "loaded" && !ebook && <NotFound />}

				<main className="EbookDetail">
					<PageContainer>
						{service.status === "loaded" && ebook ? (
							<>
								<Metatags title={ebook.title} description={ebook.description} />

								<div className="container">
									<div className="row">
										<div className="col-md-12">
											<div className="inner">
												<nav>
													<ol className="breadcrumb">
														<li className="breadcrumb-item">
															<Link to="/cedoc">CEDOC</Link>
														</li>
														<li className="breadcrumb-item">
															<Link to="/cedoc/livros">Livros</Link>
														</li>
														<li className="breadcrumb-item active">
															{ebook.title}
														</li>
													</ol>
												</nav>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-md-8 offset-md-2">
											<div className="inner">
												<img
													src={
														process.env.REACT_APP_STORAGE_URL! + ebook.folder
													}
													alt={ebook.title}
												/>

												<h1>{ebook.title}</h1>

												<p className="authors">{ebook.author}</p>

												<p className="lead-1">{ebook.description}</p>

												{ebook.file && (
													<div className="actions">
														<a
															className="btn btn-lg btn-primary"
															href={
																process.env.REACT_APP_STORAGE_URL! +
																getFileLink(ebook.file)
															}
															target="_blank"
															rel="noopener noreferrer"
														>
															Ler
														</a>

														<ButtonShare
															kind="text"
															size="lg"
															skin="outline"
															link={window.location.pathname}
															text="Compartilhar"
														/>
													</div>
												)}
											</div>
										</div>
									</div>

									<hr />

									<div className="row">
										<div className="col-md-12">
											<h2 className="mt-4">Outros livros</h2>
										</div>
									</div>

									{relateds && relateds.length > 0 ? (
										<div className="row">
											<div className="col-md-12">
												<Slider numberOf={2}>
													{relateds?.map((related) => (
														<CardEbook key={related.id} book={related} large />
													))}
												</Slider>
											</div>
										</div>
									) : (
										<div className="row">
											{loaders.map((key) => (
												<div className="col-md-6" key={key}>
													<Loader className="loader-card-library" />
												</div>
											))}
										</div>
									)}
								</div>
							</>
						) : (
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="inner">
											<div className="loader-description">
												<Loader className="line-1" />
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-8 offset-md-2">
										<div className="inner loading">
											<Loader className="image-loader" />

											<div className="loader-title">
												<Loader className="line-1" />

												<Loader className="line-2" />
											</div>

											<div className="loader-description">
												<Loader className="line-1" />

												<Loader className="line-2" />

												<Loader className="line-1" />

												<Loader className="line-2" />

												<Loader className="line-3" />
											</div>
										</div>
									</div>
								</div>

								<hr />

								<div className="row">
									<div className="col-md-12">
										<h2 className="mt-4">Outros livros</h2>
									</div>
								</div>

								<div className="row">
									{loaders.map((key) => (
										<div className="col-md-6" key={key}>
											<Loader className="loader-card-library" />
										</div>
									))}
								</div>
							</div>
						)}
					</PageContainer>
				</main>

				<NewsletterBanner />
				<Footer />
			</>
		</Page>
	);
}
