import React from "react";
import { Link } from "react-router-dom";
import TruncateMarkup from "react-truncate-markup";
import { Newsletter } from "../../types/Newsletter";
import { getFileLink } from "../../util/FileUploaded";
import { ButtonShare } from "../Layout/ButtonShare";

interface CardNewsletterProps {
	large?: boolean;
	newsletter: Newsletter;
}

export default function CardNewsletter(props: CardNewsletterProps) {
	const newsletter = props.newsletter;
	const large = props.large;
	const link =
		"/projetos/" + newsletter.project_id + "/boletins/" + newsletter.id;

	return (
		<div
			className={
				"card card-library card-newsletter" +
				(large ? " card-large" : " card-small")
			}
		>
			{newsletter.image && (
				<img
					className="card-folder"
					src={process.env.REACT_APP_STORAGE_URL! + newsletter.image}
					alt={newsletter.title}
				/>
			)}

			<div className="card-content">
				<div className="card-body">
					<div className="card-label">Boletim especial</div>

					<TruncateMarkup lines={2}>
						<div className="card-title">{newsletter.title}</div>
					</TruncateMarkup>

					<TruncateMarkup lines={3}>
						<p className="card-description">{newsletter.description}</p>
					</TruncateMarkup>
				</div>

				<div className="card-actions">
					<Link to={link} className="btn btn-primary-outline">
						Ver mais
					</Link>

					{newsletter.file && (
						<a
							href={
								process.env.REACT_APP_STORAGE_URL! +
								getFileLink(newsletter.file)
							}
							target="_blank"
							rel="noopener noreferrer"
							className="btn btn-secondary-outline"
						>
							Ler
						</a>
					)}

					<ButtonShare kind="icon" size="md" link={link} />
				</div>
			</div>
		</div>
	);
}
