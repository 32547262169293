import React, { useState, useEffect } from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import PageBanner from "../../components/Layout/PageBanner";
import PageContainer from "../../components/Layout/PageContainer";
import CardFront from "../../components/Cards/CardFront";
import IllustrationFronts from "../../components/Illustrations/IllustrationFronts";
import { Front } from "../../types/Front";
import NewsletterBanner from "../../components/Layout/NewsletterBanner";
import Metatags from "../../components/Layout/Metatags";
import { useServiceFronts } from "./Services";
import Loader from "../../components/Layout/Loader";
import { Metatag } from "../../types/Metatag";
import { useServiceMetatag } from "../Services";

export default function Fronts() {
	const [fronts, setFronts] = useState<Front[]>();
	const [metatag, setMetatag] = useState<Metatag>();
	const service = useServiceFronts();
	const serviceMetatag = useServiceMetatag("institucional/nossas-frentes");

	useEffect(() => {
		if (serviceMetatag.status === "loaded") {
			setMetatag(serviceMetatag.payload.data[0]);
		}
	}, [serviceMetatag]);

	let loaders = [];
	for (let i = 0; i < 6; i++) {
		loaders.push(i);
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (service.status === "loaded") {
			setFronts(service.payload.data);
		}
	}, [service]);

	return (
		<>
			{metatag && (
				<Metatags title={metatag.title} description={metatag.description} />
			)}

			<Header />

			<main className="Fronts">
				<PageBanner loading={!metatag}>
					<div className="container">
						<div className="row">
							<div className="col-md-8">
								<h1 className="display-1">{metatag?.title}</h1>

								<p className="lead-1">{metatag?.description}</p>
							</div>
						</div>

						<IllustrationFronts />
					</div>
				</PageBanner>

				<PageContainer>
					<div className="container">
						{fronts ? (
							<div className="row justify-content-center">
								{fronts.map((front) => (
									<div key={front.id} className="col-md-4 d-flex">
										<CardFront front={front} />
									</div>
								))}
							</div>
						) : (
							<div className="row justify-content-center">
								{loaders?.map((loader) => (
									<div key={loader} className="col-md-4">
										<Loader className="loader-card-front" />
									</div>
								))}
							</div>
						)}
					</div>
				</PageContainer>
			</main>

			<NewsletterBanner />
			<Footer />
		</>
	);
}
