import React, { useState, useEffect } from "react";
import Header from "../../../components/Layout/Header";
import Footer from "../../../components/Layout/Footer";
import PageContainer from "../../../components/Layout/PageContainer";
import { Link } from "react-router-dom";
import {
	useServiceArticleDetail,
	useServiceArticlesRelated,
} from "../Services";
import NewsletterBanner from "../../../components/Layout/NewsletterBanner";
import { Article } from "../../../types/Article";
import Slider from "../../../components/Layout/Slider";
import CardArticle from "../../../components/Cards/CardArticle";
import Metatags from "../../../components/Layout/Metatags";
import Loader, { useLoaderCounter } from "../../../components/Layout/Loader";
import CopyToClipboard from "react-copy-to-clipboard";
import { useInterval } from "react-use";
import { getFileLink } from "../../../util/FileUploaded";
import { Magazine } from "../../../types/Magazine";
import { ButtonShare } from "../../../components/Layout/ButtonShare";
import Page from "../../../components/Layout/Page";

export default function ArticleDetail(props: any) {
	const id = props.match.params.id;
	const [article, setArticle] = useState<Article | null>(null);
	const [relateds, setRelateds] = useState<Article[]>();
	const [quoteTextState, setQuoteTextState] = useState<string | null>();
	const loaders = useLoaderCounter(2);
	const [fixMagazine, setFixMagazine] = useState<Magazine>();

	const service = useServiceArticleDetail(id);
	const serviceArticlesRelated = useServiceArticlesRelated(id);

	const onCopy = (text: string, result: boolean) => {
		if (text && result) setIsRunning(true);
	};

	const [isRunning, setIsRunning] = useState<boolean>(false);

	useInterval(
		() => {
			setIsRunning(false);
		},
		isRunning ? 1400 : null
	);

	useEffect(() => {
		if (service.status === "loaded") {
			setArticle(service.payload.data);
		}
	}, [service]);

	useEffect(() => {
		article && setQuoteTextState(quoteText(article));

		if (article?.mgz_category?.magazine) {
			setFixMagazine(article?.mgz_category?.magazine);
		}

		if (article?.volume?.magazine) {
			setFixMagazine(article?.volume?.magazine);
		}
	}, [article]);

	useEffect(() => {
		if (serviceArticlesRelated.status === "loaded") {
			setRelateds(serviceArticlesRelated.payload.data);
		}
	}, [serviceArticlesRelated]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [id]);

	return (
		<Page service={service} item={article}>
			<>
				{article && (
					<Metatags
						title={article.title}
						description={article.resumo}
						keywords={article.keywords}
					/>
				)}

				<Header />

				<main className="ArticleDetail">
					{article ? (
						<PageContainer>
							<div className="container">
								{fixMagazine && (
									<div className="row">
										<div className="col-md-12">
											<nav>
												<ol className="breadcrumb">
													<li className="breadcrumb-item">
														<Link to="/cedoc">CEDOC</Link>
													</li>

													<li className="breadcrumb-item">
														<Link to="/cedoc/revistas">Revistas</Link>
													</li>

													<li className="breadcrumb-item">
														<Link to={"/cedoc/revistas/" + fixMagazine.id}>
															{fixMagazine.title}
														</Link>
													</li>

													{article.volume && (
														<>
															<li className="breadcrumb-item">
																<Link
																	to={
																		"/cedoc/revistas/" +
																		fixMagazine.id +
																		"/volumes"
																	}
																>
																	Volumes
																</Link>
															</li>

															<li className="breadcrumb-item">
																<Link
																	to={
																		"/cedoc/revistas/" +
																		article.volume.id +
																		"/volumes-eletronicos/" +
																		article.volume.id
																	}
																>
																	{article.volume.title}
																</Link>
															</li>
														</>
													)}

													<li className="breadcrumb-item active">
														{article.title}
													</li>
												</ol>
											</nav>
										</div>
									</div>
								)}

								<div className="row article">
									<div className="col-md-8 d-flex flex-column align-items-start">
										<h1 className="title display-1">{article.title}</h1>

										{article.author && (
											<div className="author">{article.author}</div>
										)}

										{article.resumo && (
											<p className="lead-2 description">{article.resumo}</p>
										)}

										{article.keywords && (
											<div className="keywords">
												<div className="label">Palavras-chave</div>

												<p>{article.keywords}</p>
											</div>
										)}

										<div className="actions">
											{article.file && (
												<>
													<a
														className="btn btn-lg btn-primary"
														href={
															process.env.REACT_APP_STORAGE_URL! +
															getFileLink(article.file)
														}
														target="_blank"
														rel="noopener noreferrer"
													>
														Ler
													</a>

													<ButtonShare
														kind="text"
														size="lg"
														skin="outline"
														link={window.location.pathname}
														text="Compartilhar"
													/>
												</>
											)}
										</div>
									</div>
									<div className="col-md-4 cards">
										{article.volume && article.volume.magazine && (
											<div className="card card-info">
												{article.volume.image && (
													<img
														src={
															process.env.REACT_APP_STORAGE_URL +
															article.volume.image
														}
														alt={
															article.volume.magazine.title +
															", " +
															article.volume.title
														}
													/>
												)}
												<div className="label">Edição</div>
												<div className="value">{article.volume.title}</div>
												{article.mgz_category?.title && (
													<>
														<div className="label">Seção</div>
														<div className="value">
															{article.mgz_category?.title}
														</div>
													</>
												)}
											</div>
										)}

										{article.file && quoteTextState && (
											<div className="card card-quote">
												<div className="title">Como citar</div>

												<div className="quote">{quote(article)}</div>

												<CopyToClipboard onCopy={onCopy} text={quoteTextState}>
													<button className="btn btn-primary-outline">
														Copiar
													</button>
												</CopyToClipboard>

												<div className={"notify " + (isRunning ? "show" : "")}>
													Texto copiado!
												</div>
											</div>
										)}
									</div>
								</div>

								{relateds && relateds.length > 0 ? (
									<>
										<div className="row">
											<div className="col-md-12">
												<h2 className="mt-4">Outros artigos</h2>
											</div>
										</div>

										<div className="row">
											<div className="col-md-12">
												<Slider numberOf={2}>
													{relateds?.map((article) => (
														<CardArticle key={article.id} article={article} />
													))}
												</Slider>
											</div>
										</div>
									</>
								) : (
									<div className="row">
										{loaders.map((key) => (
											<div className="col-md-6" key={key}>
												<Loader className="loader-card-library" />
											</div>
										))}
									</div>
								)}
							</div>
						</PageContainer>
					) : (
						<PageContainer>
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<Loader className="loader-breadcrumb" />
									</div>
								</div>
								<div className="row">
									<div className="col-md-8">
										<div className="loader-title">
											<Loader className="line-1" />

											<Loader className="line-2" />
										</div>

										<div className="loader-description">
											<Loader className="line-1" />

											<Loader className="line-2" />

											<Loader className="line-1" />

											<Loader className="line-2" />

											<Loader className="line-1" />

											<Loader className="line-2" />

											<Loader className="line-3" />
										</div>
									</div>

									<div className="col-md-4 cards">
										<Loader />

										<Loader />
									</div>
								</div>

								<div className="row">
									<div className="col-md-12">
										<h2 className="mt-4">Outros artigos</h2>
									</div>
								</div>

								<div className="row">
									{loaders.map((key) => (
										<div className="col-md-6" key={key}>
											<Loader className="loader-card-library" />
										</div>
									))}
								</div>
							</div>
						</PageContainer>
					)}
				</main>

				<NewsletterBanner />
				<Footer />
			</>
		</Page>
	);
}

const quote = (article: Article) => {
	const today = new Date();

	return (
		article.file && (
			<>
				{article.author}. {article.title}.{" "}
				<strong>{article.volume.magazine.title}</strong>, {article.volume.title}
				, p. {article.page_start + "-" + article.page_end},{" "}
				{article.volume.year}. Disponível em: {" <"}
				<a
					href={process.env.REACT_APP_STORAGE_URL! + getFileLink(article.file)}
					target="_blank"
					rel="noopener noreferrer"
				>
					{process.env.REACT_APP_STORAGE_URL! + getFileLink(article.file)}
				</a>
				{">"}.{" "}
				{"Acesso em " +
					today.getDate() +
					"/" +
					(today.getMonth() + 1) +
					"/" +
					today.getFullYear()}
				.
			</>
		)
	);
};

const quoteText = (article: Article) => {
	const today = new Date();

	if (!article.volume) return null;

	return (
		article.file &&
		article.author +
			". " +
			article.title +
			". " +
			article.volume.magazine.title +
			", " +
			article.volume.title +
			", p. " +
			article.page_start +
			"-" +
			article.page_end +
			", " +
			article.volume.year +
			". Disponível em: <" +
			process.env.REACT_APP_STORAGE_URL! +
			getFileLink(article.file) +
			">. Acesso em " +
			today.getDate() +
			"/" +
			(today.getMonth() + 1) +
			"/" +
			today.getFullYear() +
			"."
	);
};
