import React from "react";
import { Icon } from "../../types/Icon";

export default function IconClose(props: Icon) {
	return (
		<svg
			className="icon icon-external-link"
			width="23"
			height="18"
			viewBox="0 0 23 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.63159 13.2632C8.66174 4.52631 16.4737 2.94708 16.4737 2.94708V0L22.7369 5.86853L16.4737 11.7892V8.8421C16.4737 8.8421 10.9532 8.69086 6.63159 13.2632Z"
				className="fill"
			/>
			<path
				d="M9.47369 2.84204C10.9087 2.84204 11.9594 2.84204 12.7538 2.96991C12.0308 3.38647 11.2349 3.93274 10.5198 4.45508C10.2067 4.45282 9.85992 4.45258 9.47369 4.45258H5.6842C4.29889 4.45258 3.42194 4.45599 2.78027 4.54224C2.18488 4.62231 2.03833 4.74615 1.97125 4.81329C1.90411 4.88037 1.78027 5.02692 1.7002 5.62231C1.61395 6.26398 1.61053 7.14093 1.61053 8.52625V12.3157C1.61053 13.701 1.61395 14.578 1.7002 15.2197C1.78027 15.8151 1.90411 15.9616 1.97125 16.0287C2.03833 16.0958 2.18488 16.2197 2.78027 16.2997C3.42194 16.386 4.29889 16.3894 5.6842 16.3894H9.47369C10.859 16.3894 11.736 16.386 12.3776 16.2997C12.973 16.2197 13.1196 16.0958 13.1866 16.0287C13.2537 15.9616 13.3776 15.8151 13.4577 15.2197C13.5439 14.578 13.5474 13.701 13.5474 12.3157V10.4507C14.3231 10.2154 14.8998 10.1416 15.1579 10.1188V12.3157C15.1579 14.9953 15.1579 16.3351 14.3254 17.1675C13.493 17.9999 12.1533 17.9999 9.47369 17.9999H5.6842C3.00464 17.9999 1.66486 17.9999 0.832458 17.1675C0 16.3351 0 14.9953 0 12.3157V8.52625C0 5.84668 0 4.5069 0.832458 3.6745C1.66486 2.84204 3.00464 2.84204 5.6842 2.84204H9.47369Z"
				className="fill"
			/>
		</svg>
	);
}
