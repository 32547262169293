import React from "react";
import { Front } from "../../types/Front";

interface CardFrontProps {
	front: Front;
}

export default function CardFront(props: CardFrontProps) {
	const front = props.front;

	return (
		<div className="card-front">
			<img
				src={process.env.REACT_APP_STORAGE_URL + front.image}
				alt={front.title}
			/>

			<p>{front.description}</p>

			<a
				href={front.url}
				target="_blank"
				rel="noopener noreferrer"
				className="btn btn-primary"
			>
				Ver mais
			</a>
		</div>
	);
}
