import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { usePrevious } from "react-use";
import { Language } from "../../../types/Language";
import { Legislation } from "../../../types/Legislation";
import { Pagination } from "../../../types/Pagination";
import arrayMutators from "final-form-arrays";
import {
	useServiceLegislationsLanguages,
	useServiceLegislations,
} from "../Services";
import CardLegislation from "../../../components/Cards/CardLegislation";
import { Form, Field } from "react-final-form";
import LanguagesCheckListField from "../../../components/Filter/LanguagesCheckListField";
import Loader from "../../../components/Layout/Loader";
import NoResult from "../../../components/Layout/NoResult";
import Paginator from "../../../components/Layout/Paginator";

interface LegislationsFilterModel {
	q?: string;
	languages?: string[];
}

export interface LegislationsContentProps {
	consultationText: string;
}

export default function LegislationsContent(props: LegislationsContentProps) {
	// const { consultationText } = props;

	const history = useHistory();
	const location = useLocation();
	const { page } = useParams();
	const queryParams = location.search;
	const prev = usePrevious(location.pathname);
	const [prevIncludesPath, setPrevIncludesPath] = useState<boolean>(false);

	const [legislations, setLegislations] = useState<Legislation[]>();
	const [legislationsPaginatorData, setLegislationsPaginatorData] =
		useState<Pagination>();
	const [languages, setLanguages] = useState<Language[]>();
	const [filtered, setFiltered] = useState<boolean>(false);

	const serviceLegislations = useServiceLegislations(page, queryParams);
	const serviceLanguages = useServiceLegislationsLanguages();

	const searchParams = new URLSearchParams(queryParams);

	const initialFilterData: LegislationsFilterModel = {
		q: searchParams.get("q") || undefined,
		languages: searchParams.get("languages")?.split(","),
	};

	let loaders = [];
	for (let i = 0; i < 8; i++) {
		loaders.push(i);
	}

	const onFilter = (values: LegislationsFilterModel) => {
		const searchParams = new URLSearchParams(queryParams);

		searchParams.delete("q");
		if (values.q) {
			searchParams.set("q", values.q);
		}

		searchParams.delete("languages");
		if (values.languages?.length) {
			searchParams.set("languages", values.languages.toString());
		}

		return history.push("/cedoc/legislacoes?" + searchParams.toString());
	};

	useEffect(() => {
		if (initialFilterData.q || initialFilterData.languages) {
			setFiltered(true);
		}
	}, [initialFilterData]);

	useEffect(() => {
		if (serviceLegislations.status === "loaded") {
			const paginatorData: Pagination = {
				current_page: serviceLegislations.payload.data.current_page,
				first_page_url: serviceLegislations.payload.data.first_page_url,
				from: serviceLegislations.payload.data.from,
				last_page: serviceLegislations.payload.data.last_page,
				last_page_url: serviceLegislations.payload.data.last_page_url,
				next_page_url: serviceLegislations.payload.data.next_page_url,
				path: serviceLegislations.payload.data.path,
				per_page: serviceLegislations.payload.data.per_page,
				prev_page_url: serviceLegislations.payload.data.prev_page_url,
				to: serviceLegislations.payload.data.to,
				total: serviceLegislations.payload.data.total,
			};

			setLegislationsPaginatorData(paginatorData);
			setLegislations(serviceLegislations.payload.data.data);
		}
	}, [serviceLegislations]);

	useEffect(() => {
		if (serviceLanguages.status === "loaded") {
			setLanguages(serviceLanguages.payload.data);
		}
	}, [serviceLanguages]);

	useEffect(() => {
		if (prevIncludesPath && page) window.scrollTo(0, 0);
	}, [page, prevIncludesPath]);

	useEffect(() => {
		setPrevIncludesPath(!!prev?.includes("legislacoes"));
	}, [prev]);

	return (
		<div className="container p-0">
			<div className="row">
				<div className="col-md-4">
					<div className="card filter">
						<div className="card-filter">
							<Form<LegislationsFilterModel>
								onSubmit={onFilter}
								mutators={{ ...arrayMutators }}
								initialValues={initialFilterData}
								render={({ handleSubmit }) => (
									<form onSubmit={handleSubmit}>
										<div className="card-header">
											<h5>Pesquisar</h5>
										</div>

										<div className="card-body">
											<Field
												type="text"
												name="q"
												component="input"
												className="form-control"
												placeholder="Busque por título, assunto, palavras-chave..."
											/>

											{languages && languages.length > 1 && (
												<>
													<h5 className="">Idioma</h5>

													<LanguagesCheckListField
														name="languages"
														languages={languages}
													/>
												</>
											)}

											<button
												className="btn btn-primary"
												onClick={handleSubmit}
											>
												Filtrar
											</button>
										</div>
									</form>
								)}
							/>
						</div>
					</div>
				</div>
				<div className="col-md-8">
					{legislations && legislationsPaginatorData ? (
						legislations?.length > 0 ? (
							<>
								{legislations?.map((legislation) => (
									<CardLegislation
										large
										key={legislation.id}
										legislation={legislation}
									/>
								))}
							</>
						) : (
							<NoResult filter={filtered} />
						)
					) : (
						loaders?.map((loader) => (
							<Loader
								key={loader}
								className="loader-card-legislation card-large"
							/>
						))
					)}
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					{legislations &&
						legislations.length > 0 &&
						legislationsPaginatorData && (
							<Paginator
								path={"/cedoc/midias"}
								pagination={legislationsPaginatorData}
								queryParams={queryParams}
							/>
						)}
				</div>
			</div>
		</div>
	);
}
