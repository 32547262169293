import { BrowserRouter, Switch, Route } from "react-router-dom";
import React from "react";
import "./App.scss";
import Home from "./pages/Home";
import Events from "./pages/Events/Events";
import EventDetail from "./pages/Events/EventDetail";
import News from "./pages/News/News";
import NewDetail from "./pages/News/NewDetail";
import NewsletterPage from "./pages/Newsletter/NewsletterPage";
import Cedoc from "./pages/Cedoc/Cedoc";
import BookDetail from "./pages/Cedoc/Books/BookDetail";
import Contact from "./pages/Contact";
import Laboratories from "./pages/About/Laboratories";
import AboutPage from "./pages/About/About";
import Fronts from "./pages/About/Fronts";
import Team from "./pages/About/Team";
import AudiovisualDetail from "./pages/Cedoc/Audiovisuals/AudiovisualDetail";
import ThesiDetail from "./pages/Cedoc/Thesis/ThesiDetail";
import PageNotFound from "./pages/Errors/PageNotFound";
import UnderConstruction from "./pages/UnderConstruction";
import MagazineDetail from "./pages/Cedoc/Magazines/MagazineDetail";
import VolumeDetail from "./pages/Cedoc/Magazines/VolumeDetail";
import ArticleDetail from "./pages/Cedoc/Magazines/ArticleDetail";
import NewsletterDetail from "./pages/Cedoc/Newsletters/NewsletterDetail";
import ProjectDetail from "./pages/Projects/ProjectDetail";
import Documents from "./pages/About/Documents";
import MagazineVolumes from "./pages/Cedoc/Magazines/MagazineVolumes";
import VolumeFisicoDetail from "./pages/Cedoc/Magazines/VolumeFisicoDetail";
import BookFisicoDetail from "./pages/Cedoc/Books/BookFisicoDetail";
import AudiovisualFisicoDetail from "./pages/Cedoc/Audiovisuals/AudiovisualFisicoDetail";
import ThesiFisicoDetail from "./pages/Cedoc/Thesis/ThesiFisicoDetail";
import LegislationDetail from "./pages/Cedoc/Legislations/LegislationDetail";

function App() {
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/" exact component={Home} />
				<Route path="/inicio" exact component={Home} />
				<Route path="/home" exact component={Home} />

				<Route path="/institucional/o-instituto" exact component={AboutPage} />
				<Route path="/institucional/nossas-frentes" exact component={Fronts} />

				<Route
					path="/institucional/nucleos-e-laboratorios"
					exact
					component={Laboratories}
				/>

				<Route path="/institucional/equipe" exact component={Team} />

				<Route
					path="/institucional/formularios"
					exact
					component={UnderConstruction}
				/>

				<Route path="/institucional/documentos" exact component={Documents} />

				<Route path="/institucional/contato" exact component={Contact} />

				<Route path="/cedoc/revistas/:id" exact component={MagazineDetail} />

				<Route
					path="/cedoc/revistas/:id/volumes/page/:page"
					exact
					component={MagazineVolumes}
				/>

				<Route
					path="/cedoc/revistas/:id/volumes"
					exact
					component={MagazineVolumes}
				/>

				<Route
					path="/cedoc/revistas/:magazineId/volumes-eletronicos/:id"
					exact
					component={VolumeDetail}
				/>

				<Route
					path="/cedoc/revistas/:magazineId/volumes-fisicos/:id"
					exact
					component={VolumeFisicoDetail}
				/>

				<Route
					path={"/cedoc/revistas/:magazineId/volumes-eletronicos/:volumeId/:id"}
					exact
					component={ArticleDetail}
				/>

				<Route
					path={"/cedoc/livros-eletronicos/:id"}
					exact
					component={BookDetail}
				/>

				<Route
					path={"/cedoc/livros-fisicos/:id"}
					exact
					component={BookFisicoDetail}
				/>

				<Route
					path="/cedoc/midias-eletronicas/:id"
					exact
					component={AudiovisualDetail}
				/>

				<Route
					path="/cedoc/midias-fisicas/:id"
					exact
					component={AudiovisualFisicoDetail}
				/>

				<Route
					path="/cedoc/teses-e-dissertacoes-eletronicas/:id"
					exact
					component={ThesiDetail}
				/>

				<Route
					path="/cedoc/teses-e-dissertacoes-fisicas/:id"
					exact
					component={ThesiFisicoDetail}
				/>

				<Route
					path="/cedoc/legislacoes/:id"
					exact
					component={LegislationDetail}
				/>

				<Route path="/cedoc" component={Cedoc} />

				<Route path="/eventos-e-cursos" exact component={Events} />
				<Route path="/eventos-e-cursos/page/:page" exact component={Events} />
				<Route path="/eventos-e-cursos/:id" exact component={EventDetail} />

				<Route path="/noticias" exact component={News} />
				<Route path="/noticias/page/:page" exact component={News} />
				<Route path="/noticias/:id" exact component={NewDetail} />

				<Route path="/boletim" exact component={NewsletterPage} />

				<Route path="/projetos/:id" exact component={ProjectDetail} />

				<Route
					path="/projetos/:projectId/boletins/:id"
					exact
					component={NewsletterDetail}
				/>

				<Route component={PageNotFound} />
			</Switch>
		</BrowserRouter>
	);
}

export default App;
