import React from "react";
import {
	FieldArray,
	FieldArrayProps,
	FieldArrayRenderProps,
} from "react-final-form-arrays";
import { CedocItemType } from "../../types/Volume";
import { Field } from "react-final-form";

const CedocItemTypeCheckListChildren = (
	props: FieldArrayRenderProps<CedocItemType, HTMLElement>
) => {
	const toggle = (
		event: React.ChangeEvent<HTMLInputElement>,
		option: CedocItemType
	) => {
		if (event.target.checked) {
			if (props.fields.value && props.fields.value.length) {
				const index = props.fields.value.indexOf(option);
				if (index >= 0) props.fields.remove(index);
				else props.fields.push(option);
			} else {
				props.fields.push(option);
			}
		} else {
			if (props.fields.value && props.fields.value.length) {
				const index = props.fields.value.indexOf(option);
				if (index >= 0) props.fields.remove(index);
			}
		}
	};

	return (
		<>
			<Field
				type="checkbox"
				name={props.fields.name}
				className="form-control"
				value={CedocItemType.ONLINE}
				render={(fieldProps) => (
					<div className="form-check">
						<input
							checked={props.fields.value?.indexOf(CedocItemType.ONLINE) >= 0}
							className="form-check-input"
							type="checkbox"
							id={"checkboxItemTypeOnline"}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
								toggle(event, CedocItemType.ONLINE)
							}
						/>
						<label
							className="form-check-label"
							htmlFor={"checkboxItemTypeOnline"}
						>
							{CedocItemType.ONLINE}
						</label>
					</div>
				)}
			/>

			<Field
				type="checkbox"
				name={props.fields.name}
				className="form-control"
				value={CedocItemType.FISICO}
				render={(fieldProps) => (
					<div className="form-check">
						<input
							checked={props.fields.value?.indexOf(CedocItemType.FISICO) >= 0}
							className="form-check-input"
							type="checkbox"
							id={"checkboxItemTypeFisico"}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
								toggle(event, CedocItemType.FISICO)
							}
						/>
						<label
							className="form-check-label"
							htmlFor={"checkboxItemTypeFisico"}
						>
							{CedocItemType.FISICO}
						</label>
					</div>
				)}
			/>
		</>
	);
};

export interface CedocItemTypeCheckListProps
	extends FieldArrayProps<CedocItemType, HTMLElement> {
	name: string;
}

export default function CedocItemTypeCheckListField(
	props: CedocItemTypeCheckListProps
) {
	const { name, ...rest } = props;
	return (
		<div className="form-check-list">
			<FieldArray<CedocItemType>
				name={name}
				component={CedocItemTypeCheckListChildren}
				{...rest}
			/>
		</div>
	);
}
