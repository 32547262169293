import React, { useState, useEffect } from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import PageBanner from "../../components/Layout/PageBanner";
import Paginator from "../../components/Layout/Paginator";
import PageContainer from "../../components/Layout/PageContainer";
import CardNew from "../../components/Cards/CardNew";
import IllustrationNews from "../../components/Illustrations/IllustrationNews";
import { New } from "../../types/New";
import { useServiceNews } from "./Sevices";
import { Pagination } from "../../types/Pagination";
import NewsletterBanner from "../../components/Layout/NewsletterBanner";
import { Form, Field } from "react-final-form";
import { FormApi } from "final-form";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Layout/Loader";
import NoResult from "../../components/Layout/NoResult";
import Metatags from "../../components/Layout/Metatags";
import { Metatag } from "../../types/Metatag";
import { useServiceMetatag } from "../Services";

interface NewsFilterModel {
	q?: string;
	dateStart?: string;
	dateEnd?: string;
}

export default function News(props: any) {
	const history = useHistory();
	const page = props.match.params.page;
	const queryParams = props.location.search;

	let loaders = [];
	for (let i = 0; i < 8; i++) {
		loaders.push(i);
	}

	const [filtered, setFiltered] = useState<boolean>(false);
	const [news, setNews] = useState<New[]>();
	const [paginatorData, setPaginatorData] = useState<Pagination>();
	const [metatag, setMetatag] = useState<Metatag>();
	const serviceMetatag = useServiceMetatag("noticias");

	const service = useServiceNews(page, queryParams);

	const searchParams = new URLSearchParams(queryParams);

	const initialFilterData: NewsFilterModel = {
		q: searchParams.get("q") || undefined,
		dateStart: searchParams.get("dateStart") || undefined,
		dateEnd: searchParams.get("dateEnd") || undefined,
	};

	const onFilter = (values: NewsFilterModel, form: FormApi) => {
		const searchParams = new URLSearchParams(queryParams);

		searchParams.delete("q");
		if (values.q) {
			searchParams.set("q", values.q);
		}

		searchParams.delete("dateStart");
		if (values.dateStart) {
			searchParams.set("dateStart", values.dateStart);
		}

		searchParams.delete("dateEnd");
		if (values.dateEnd) {
			searchParams.set("dateEnd", values.dateEnd);
		}

		return history.push("/noticias?" + searchParams.toString());
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [page]);

	useEffect(() => {
		if (service.status === "loaded") {
			const paginatorData: Pagination = {
				current_page: service.payload.data.current_page,
				first_page_url: service.payload.data.first_page_url,
				from: service.payload.data.from,
				last_page: service.payload.data.last_page,
				last_page_url: service.payload.data.last_page_url,
				next_page_url: service.payload.data.next_page_url,
				path: service.payload.data.path,
				per_page: service.payload.data.per_page,
				prev_page_url: service.payload.data.prev_page_url,
				to: service.payload.data.to,
				total: service.payload.data.total,
			};

			setPaginatorData(paginatorData);
			setNews(service.payload.data.data);
		}
	}, [service]);

	useEffect(() => {
		if (
			initialFilterData.q ||
			initialFilterData.dateStart ||
			initialFilterData.dateEnd
		) {
			setFiltered(true);
		}
	}, [initialFilterData]);

	useEffect(() => {
		if (serviceMetatag.status === "loaded") {
			setMetatag(serviceMetatag.payload.data[0]);
		}
	}, [serviceMetatag]);

	return (
		<>
			{metatag && (
				<Metatags title={metatag.title} description={metatag.description} />
			)}

			<Header />

			<main className="News">
				<PageBanner loading={!metatag}>
					<div className="container">
						<div className="row">
							<div className="col-md-6">
								<h1 className="display-1">{metatag?.title}</h1>

								<p className="lead-1">{metatag?.description}</p>
							</div>
						</div>

						<IllustrationNews />
					</div>
				</PageBanner>

				<PageContainer>
					<div className="container">
						<div className="row">
							{news && paginatorData ? (
								<>
									{news.length > 0 ? (
										<div className="col-md-8">
											{news?.map((news) => (
												<CardNew large key={news.id} new={news} />
											))}
										</div>
									) : (
										<div className="col-md-8">
											<NoResult filter={filtered} />
										</div>
									)}
								</>
							) : (
								<div className="col-md-8">
									{loaders?.map((loader) => (
										<Loader
											key={loader}
											className="loader-card-new card-large"
										/>
									))}
								</div>
							)}

							<div className="col-md-4">
								<div className="card filter">
									<Form<NewsFilterModel>
										onSubmit={onFilter}
										initialValues={initialFilterData}
										render={({ handleSubmit }) => (
											<form onSubmit={handleSubmit}>
												<div className="card-filter">
													<div className="card-body">
														<h5 className="mt-4">Pesquisar</h5>

														<Field
															type="text"
															name="q"
															component="input"
															className="form-control"
															placeholder="Busque por assunto, palavras-chave..."
														/>

														{/* <h5 className="mt-2">Período</h5>

															<div className="row">
																<div className="col">
																	<Field
																		type="text"
																		name="dateStart"
																		component="input"
																		className="form-control"
																		placeholder="De"
																	/>
																</div>
																<div className="col">
																	<Field
																		type="text"
																		name="dateEnd"
																		component="input"
																		className="form-control"
																		placeholder="Até"
																	/>
																</div>
															</div> */}

														<button
															className="btn btn-primary"
															onClick={handleSubmit}
														>
															Filtrar
														</button>
													</div>
												</div>
											</form>
										)}
									/>
								</div>
							</div>
						</div>
					</div>

					{news && news.length > 0 && paginatorData && (
						<div className="row">
							<div className="col-md-12">
								<Paginator
									path={"/noticias"}
									pagination={paginatorData}
									queryParams={queryParams}
								/>
							</div>
						</div>
					)}
				</PageContainer>
			</main>

			<NewsletterBanner />
			<Footer />
		</>
	);
}
