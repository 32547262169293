import React, { useState, useEffect } from "react";
import Header from "../../../components/Layout/Header";
import Footer from "../../../components/Layout/Footer";
import PageContainer from "../../../components/Layout/PageContainer";
import { Link, useParams } from "react-router-dom";
import { useServiceVolumeDetail, useServiceVolumesRelated } from "../Services";
import NewsletterBanner from "../../../components/Layout/NewsletterBanner";
import { Volume } from "../../../types/Volume";
import CardVolume, { volumeTitle } from "../../../components/Cards/CardVolume";
import Accordion from "react-bootstrap/Accordion";
import IconArrowDown from "../../../components/Icons/IconArrowDown";
import { MgzCategory } from "../../../types/MgzCategory";
import { Article } from "../../../types/Article";
import Slider from "../../../components/Layout/Slider";
import Metatags from "../../../components/Layout/Metatags";
import Loader, { useLoaderCounter } from "../../../components/Layout/Loader";
import { getFileLink } from "../../../util/FileUploaded";
import { ButtonShare } from "../../../components/Layout/ButtonShare";
import Page from "../../../components/Layout/Page";

export default function VolumeDetail() {
	const { id } = useParams<{ id: string }>();

	const [volume, setVolume] = useState<Volume>();
	const [relateds, setRelated] = useState<Volume[]>();
	const loaders = useLoaderCounter(2);

	const service = useServiceVolumeDetail(id);
	const serviceVolumesRelated = useServiceVolumesRelated(id);

	useEffect(() => {
		if (service.status === "loaded") {
			setVolume(service.payload.data);
		}
	}, [service]);

	useEffect(() => {
		if (serviceVolumesRelated.status === "loaded") {
			setRelated(serviceVolumesRelated.payload.data);
		}
	}, [serviceVolumesRelated]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [id]);

	return (
		<Page service={service} item={volume}>
			<>
				{volume && (
					<Metatags
						title={volumeTitle(volume)}
						description={volume.description}
					/>
				)}

				<Header />

				<main className="VolumeDetail">
					<PageContainer>
						<div className="container">
							{volume ? (
								<>
									<div className="row">
										<div className="col-md-12">
											<nav>
												<ol className="breadcrumb">
													<li className="breadcrumb-item">
														<Link to="/cedoc">CEDOC</Link>
													</li>
													<li className="breadcrumb-item">
														<Link to="/cedoc/revistas">Revistas</Link>
													</li>
													<li className="breadcrumb-item">
														<Link to={"/cedoc/revistas/" + volume.magazine.id}>
															{volume.magazine.title}
														</Link>
													</li>

													<li className="breadcrumb-item">
														<Link
															to={
																"/cedoc/revistas/" +
																volume.magazine.id +
																"/volumes"
															}
														>
															Volumes
														</Link>
													</li>

													<li className="breadcrumb-item active">
														{volume.title}
													</li>
												</ol>
											</nav>
										</div>
									</div>

									<div className="row mb-4">
										<div className="col-md-6 d-flex justify-content-center align-items-center">
											<img
												className="volume-image"
												src={process.env.REACT_APP_STORAGE_URL! + volume.image}
												alt={volumeTitle(volume)}
											/>
										</div>

										<div className="col-md-6">
											<p className="magazine">{volume.magazine.title}</p>

											<h1>{volumeTitle(volume)}</h1>

											<p className="lead-1">{volume.description}</p>

											<div className="mt-3">
												<ButtonShare
													kind="text"
													size="lg"
													link={window.location.pathname}
													text="Compartilhar"
												/>
											</div>
										</div>
									</div>

									{volume.mgz_categories &&
										volume.mgz_categories.length > 0 && (
											<div className="row">
												<div className="col-md-12">
													<div className="card card-summary">
														<ul className="summary-list">
															<Accordion defaultActiveKey="0">
																{volume.mgz_categories?.map(
																	(mgzCategory: MgzCategory, key: number) => (
																		<li className="summary-item" key={key}>
																			<Accordion.Toggle
																				className="accordion-toggle"
																				eventKey={"" + key}
																			>
																				<div className="title">
																					{mgzCategory.title}
																				</div>

																				<IconArrowDown />
																			</Accordion.Toggle>
																			<Accordion.Collapse
																				className="accordion-collapse"
																				eventKey={"" + key}
																			>
																				<ul className="article-list">
																					{mgzCategory.articles?.map(
																						(article: Article, key: number) => (
																							<li
																								key={key}
																								className="article-item"
																							>
																								<div className="content">
																									<div className="title">
																										{article.title}
																									</div>

																									{article.author && (
																										<div className="author">
																											{article.author}
																										</div>
																									)}
																								</div>

																								<div className="actions">
																									{article.file && (
																										<a
																											className="btn btn-secondary-outline"
																											href={
																												process.env
																													.REACT_APP_STORAGE_URL! +
																												getFileLink(
																													article.file
																												)
																											}
																											target="_blank"
																											rel="noopener noreferrer"
																										>
																											Pdf
																										</a>
																									)}

																									<Link
																										to={
																											"/cedoc/revistas/" +
																											(article.mgz_category
																												?.magazine?.id || "0") +
																											"/volumes-eletronicos/" +
																											(article.volume?.id ||
																												"0") +
																											"/" +
																											article.id
																										}
																										className="btn btn-primary-outline"
																									>
																										Ver mais
																									</Link>
																								</div>
																							</li>
																						)
																					)}
																				</ul>
																			</Accordion.Collapse>
																		</li>
																	)
																)}
															</Accordion>
														</ul>
													</div>
												</div>
											</div>
										)}
								</>
							) : (
								<>
									<div className="row">
										<div className="col-md-12">
											<Loader className="loader-breadcrumb" />
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<Loader style={{ height: "600px", margin: "0 64px" }} />
										</div>
										<div className="col-md-6">
											<div className="loader-title">
												<Loader className="line-1" />

												<Loader className="line-2" />
											</div>

											<div className="loader-description">
												<Loader className="line-1" />

												<Loader className="line-2" />

												<Loader className="line-1" />

												<Loader className="line-2" />

												<Loader className="line-1" />

												<Loader className="line-2" />

												<Loader className="line-3" />
											</div>
										</div>
									</div>
								</>
							)}

							<div className="row">
								<div className="col-md-12">
									<h2 className="mt-4">Outros volumes</h2>
								</div>
							</div>
							{relateds && relateds.length > 0 ? (
								<div className="row">
									<div className="col-md-12">
										<Slider numberOf={2}>
											{relateds?.map((volume) => (
												<CardVolume key={volume.id} volume={volume} />
											))}
										</Slider>
									</div>
								</div>
							) : (
								<div className="row">
									{loaders.map((key) => (
										<div className="col-md-6" key={key}>
											<Loader className="loader-card-library" />
										</div>
									))}
								</div>
							)}
						</div>
					</PageContainer>
				</main>

				<NewsletterBanner />
				<Footer />
			</>
		</Page>
	);
}
