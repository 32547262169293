import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { LinkContainer } from "react-router-bootstrap";
import IconArrowDown from "../Icons/IconArrowDown";
import Logo from "../../assets/logo-color.svg";
import IconMenu from "../Icons/IconMenu";
import IconClose from "../Icons/IconClose";
import Headroom from "react-headroom";
import { Overflow } from "./Overflow";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useServiceProjects } from "../../pages/Projects/Services";
import { Project } from "../../types/Project";

interface HeaderProps {
	onMenuClick?(): void;
}

export default function Header(props: HeaderProps) {
	const location = useLocation();
	const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
	const [sidebarTop, setSidebarTop] = useState<number>(144);
	const [sidebarHeight, setSidebarHeight] = useState<string>();
	const [projects, setProjects] = useState<Project[]>();
	const serviceProjects = useServiceProjects();

	useEffect(() => {
		if (serviceProjects.status === "loaded") {
			setProjects(serviceProjects.payload.data);
		}
	}, [serviceProjects]);

	useEffect(() => {
		sidebarOpen
			? document.body.classList.add("overflow")
			: document.body.classList.remove("overflow");
	}, [sidebarOpen]);

	useScrollPosition(() => {
		updateSidebar();
	}, []);

	useEffect(() => {
		updateSidebar();
	}, []);

	const updateSidebar = () => {
		const header = document
			.getElementsByClassName("headroom")[0]
			?.getBoundingClientRect();

		if (header) {
			setSidebarTop(header.height + header.top);
			setSidebarHeight(window.innerHeight - header.bottom + "px");
		}
	};

	return (
		<>
			<div
				onClick={() => setSidebarOpen(false)}
				style={{ top: sidebarTop }}
				className={"sidebar-wrapper" + (sidebarOpen ? " open" : "")}
			></div>
			<Headroom>
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="inner">
								<nav className="navbar">
									<button
										className={"btn-menu" + (sidebarOpen ? " active" : "")}
										onClick={() => setSidebarOpen(!sidebarOpen)}
									>
										{!sidebarOpen ? <IconMenu /> : <IconClose />}
									</button>

									<NavLink to="/" className="navbar-brand" href="#">
										<img src={Logo} height="112" alt="Logo" />
									</NavLink>

									<ul
										style={
											window.innerWidth <= Overflow.mdMax
												? { height: sidebarHeight }
												: {}
										}
										className={"navbar-nav" + (sidebarOpen ? " open" : "")}
									>
										<li className="nav-item">
											<NavLink
												exact
												to="/"
												className="nav-link"
												activeClassName="active"
												href="#"
											>
												Início
											</NavLink>
										</li>

										<Dropdown className="nav-item">
											<Dropdown.Toggle
												className={
													"nav-link" +
													(location.pathname.includes("/institucional/")
														? " active"
														: "")
												}
												id="dropdownAbout"
											>
												Institucional
												<IconArrowDown />
											</Dropdown.Toggle>

											<Dropdown.Menu>
												<LinkContainer
													to="/institucional/o-instituto"
													activeClassName="active"
												>
													<Dropdown.Item>O Instituto</Dropdown.Item>
												</LinkContainer>
												<LinkContainer
													to="/institucional/nossas-frentes"
													activeClassName="active"
												>
													<Dropdown.Item>Nossas frentes</Dropdown.Item>
												</LinkContainer>
												<LinkContainer
													to="/institucional/nucleos-e-laboratorios"
													activeClassName="active"
												>
													<Dropdown.Item>Núcleos e Laboratórios</Dropdown.Item>
												</LinkContainer>
												<LinkContainer
													to="/institucional/equipe"
													activeClassName="active"
												>
													<Dropdown.Item>Equipe</Dropdown.Item>
												</LinkContainer>

												<LinkContainer
													to="/institucional/documentos"
													activeClassName="active"
												>
													<Dropdown.Item>Documentos</Dropdown.Item>
												</LinkContainer>
											</Dropdown.Menu>
										</Dropdown>

										<li className="nav-item">
											<NavLink
												to="/cedoc"
												className="nav-link"
												activeClassName="active"
												href="#"
											>
												CEDOC
											</NavLink>
										</li>

										<li className="nav-item">
											<NavLink
												to="/eventos-e-cursos"
												className="nav-link"
												activeClassName="active"
												href="#"
											>
												Eventos & Cursos
											</NavLink>
										</li>

										<Dropdown className="nav-item">
											<Dropdown.Toggle
												className={
													"nav-link" +
													(location.pathname.includes("/projetos/")
														? " active"
														: "")
												}
												id="dropdownAbout"
											>
												Projetos
												<IconArrowDown />
											</Dropdown.Toggle>

											<Dropdown.Menu>
												{projects?.map((project) => (
													<LinkContainer
														to={`/projetos/${project.id}`}
														activeClassName="active"
													>
														<Dropdown.Item>{project.title}</Dropdown.Item>
													</LinkContainer>
												))}
											</Dropdown.Menu>
										</Dropdown>

										<li className="nav-item">
											<NavLink
												to="/noticias"
												className="nav-link"
												activeClassName="active"
												href="#"
											>
												Notícias
											</NavLink>
										</li>

										<li className="nav-item">
											<NavLink
												to="/boletim"
												className="nav-link"
												activeClassName="active"
												href="#"
											>
												Boletim
											</NavLink>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</Headroom>
		</>
	);
}
