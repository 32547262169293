import { useState, useEffect } from "react";
import { Service } from "../../types/Service";
import { Front } from "../../types/Front";
import { Laboratory } from "../../types/Laboratory";
import { Document } from "../../types/Document";
import { About } from "../../types/About";
import { TeamGroup } from "../../types/Team";

const apiUrl = process.env.REACT_APP_API_URL;

export interface AboutModel {
	data: About[];
}

export const useServiceAbout = () => {
	const [service, setService] = useState<Service<AboutModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "about")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface FrontsModel {
	data: Front[];
}

export const useServiceFronts = () => {
	const [service, setService] = useState<Service<FrontsModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "fronts")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface LaboratoriesModel {
	data: Laboratory[];
}

export const useServiceLaboratories = () => {
	const [service, setService] = useState<Service<LaboratoriesModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "laboratories")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface DocumentsModel {
	data: Document[];
}

export const useServiceDocuments = () => {
	const [service, setService] = useState<Service<DocumentsModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "documents")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface TeamModel {
	data: TeamGroup[];
}

export const useServiceTeam = () => {
	const [service, setService] = useState<Service<TeamModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "team")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};
