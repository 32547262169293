import { useState, useEffect } from "react";
import { Project } from "../../types/Project";
import { Service } from "../../types/Service";

const apiUrl = process.env.REACT_APP_API_URL;

export interface ProjectsModel {
	data: Project[];
}

export const useServiceProjects = () => {
	const [service, setService] = useState<Service<ProjectsModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "projects")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface ProjectDetailModel {
	data: Project;
}

export const useServiceProjectDetail = (id: string) => {
	const [service, setService] = useState<Service<ProjectDetailModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "projects/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};
