import React, { useState, useEffect } from "react";
import Header from "../../../components/Layout/Header";
import Footer from "../../../components/Layout/Footer";
import PageContainer from "../../../components/Layout/PageContainer";
import { Link } from "react-router-dom";
import {
	useServiceCedocParams,
	useServiceMagazineDetail,
	useServiceMagazinesRelated,
} from "../Services";
import { Magazine } from "../../../types/Magazine";
import NewsletterBanner from "../../../components/Layout/NewsletterBanner";
import CardVolume from "../../../components/Cards/CardVolume";
import Slider from "../../../components/Layout/Slider";
import CardMagazine from "../../../components/Cards/CardMagazine";
import Metatags from "../../../components/Layout/Metatags";
import Loader, { useLoaderCounter } from "../../../components/Layout/Loader";
import { CedocParams } from "../../../types/CedocParams";
import Page from "../../../components/Layout/Page";

export default function MagazineDetail(props: any) {
	const id = props.match.params.id;
	const [magazine, setMagazine] = useState<Magazine | null>(null);
	const [relateds, setRelated] = useState<Magazine[]>();
	const loaders = useLoaderCounter(2);

	const service = useServiceMagazineDetail(id);
	const serviceMagazinesRelated = useServiceMagazinesRelated(id);
	const [cedocParams, setCedocParams] = useState<CedocParams>();
	const serviceCedoc = useServiceCedocParams();

	useEffect(() => {
		if (serviceCedoc.status === "loaded") {
			setCedocParams(serviceCedoc.payload.data);
		}
	}, [serviceCedoc]);

	useEffect(() => {
		if (service.status === "loaded") {
			setMagazine(service.payload.data);
		}
	}, [service]);

	useEffect(() => {
		if (serviceMagazinesRelated.status === "loaded") {
			setRelated(serviceMagazinesRelated.payload.data);
		}
	}, [serviceMagazinesRelated]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [id]);

	return (
		<Page service={service} item={magazine}>
			<>
				{magazine && (
					<Metatags title={magazine.title} description={magazine.description} />
				)}

				<Header />

				<main className="MagazineDetail">
					<PageContainer>
						<div className="container">
							{magazine ? (
								<>
									<div className="row">
										<div className="col-md-12">
											<nav>
												<ol className="breadcrumb">
													<li className="breadcrumb-item">
														<Link to="/cedoc">CEDOC</Link>
													</li>
													<li className="breadcrumb-item">
														<Link to="/cedoc/revistas">Revistas</Link>
													</li>
													<li className="breadcrumb-item active">
														{magazine.title}
													</li>
												</ol>
											</nav>
										</div>
									</div>

									<div className="row">
										<div className="col-md-8">
											<h1 className="display-1">{magazine.title}</h1>
										</div>
									</div>

									<div className="row mb-5">
										<div className="col-md-8">
											<p className="lead-2">{magazine.description}</p>
										</div>

										<div className="col-md-4">
											<ul className="details">
												{magazine.issn && (
													<li>
														<div className="value">
															<span className="label">ISSN:</span>
															{magazine.issn}
														</div>
													</li>
												)}

												{magazine.issn_eletronico && (
													<li>
														<div className="value">
															<span className="label">ISSN Eletrônico:</span>
															{magazine.issn_eletronico}
														</div>
													</li>
												)}

												{magazine.address && (
													<li>
														<div className="value">
															<span className="label">Endereço:</span>
															{magazine.address}
														</div>
													</li>
												)}

												{magazine.assinaturas && (
													<li>
														<div className="value">
															<span className="label">Assinaturas:</span>
															{magazine.assinaturas}
														</div>
													</li>
												)}

												{magazine.link_norma_public && (
													<li>
														<div className="value">
															<a
																href={magazine.link_norma_public}
																target="_blank"
																rel="noopener noreferrer"
															>
																Normas da publicação
															</a>
														</div>
													</li>
												)}
											</ul>
										</div>
									</div>

									{magazine.volumes_online &&
										magazine.volumes_online.length > 0 && (
											<>
												<div className="row">
													<div className="col-md-12">
														<h3>Volumes eletrônicos</h3>
													</div>
												</div>

												<div className="row">
													<div className="col-md-12 mb-4">
														<Slider numberOf={2}>
															{magazine.volumes_online?.map((volume, key) => (
																<CardVolume
																	key={key}
																	volume={volume}
																	consultationText={
																		cedocParams?.consultation_text
																	}
																/>
															))}
														</Slider>
													</div>
												</div>
											</>
										)}

									{magazine.volumes_fisicos &&
										magazine.volumes_fisicos.length > 0 && (
											<>
												<div className="row">
													<div className="col-md-12">
														<h3>Volumes físicos</h3>
													</div>
												</div>

												<div className="row">
													<div className="col-md-12 mb-4">
														<Slider numberOf={2}>
															{magazine.volumes_fisicos?.map((volume, key) => (
																<CardVolume
																	key={key}
																	volume={volume}
																	consultationText={
																		cedocParams?.consultation_text
																	}
																/>
															))}
														</Slider>
													</div>
												</div>
											</>
										)}

									{(!magazine.volumes_fisicos ||
										magazine.volumes_fisicos.length <= 0) &&
									(!magazine.volumes_online ||
										magazine.volumes_online.length <= 0) ? (
										<div>Nenhum volume cadastrado</div>
									) : (
										<div className="row">
											<div className="col-md-12 d-flex justify-content-center mb-5">
												<Link
													to={"/cedoc/revistas/" + magazine.id + "/volumes"}
													className="btn btn-lg btn-primary"
												>
													Ver todos volumes
												</Link>
											</div>
										</div>
									)}

									<div className="row">
										<div className="col-md-12">
											{magazine?.img_patrocinador && (
												<div className="sponsor">
													<img
														src={
															process.env.REACT_APP_STORAGE_URL! +
															magazine.img_patrocinador
														}
														alt="Patrocínio"
													/>
												</div>
											)}
										</div>
									</div>
								</>
							) : (
								<>
									<div className="row">
										<div className="col-md-12">
											<Loader className="loader-breadcrumb" />
										</div>
									</div>
									<div className="row">
										<div className="col-md-8">
											<div className="loader-title">
												<Loader className="line-1" />

												<Loader className="line-2" />
											</div>

											<div className="loader-description">
												<Loader className="line-1" />

												<Loader className="line-2" />

												<Loader className="line-1" />

												<Loader className="line-2" />

												<Loader className="line-1" />

												<Loader className="line-2" />

												<Loader className="line-3" />
											</div>
										</div>
									</div>
								</>
							)}

							<hr />

							<div className="row">
								<div className="col-md-12">
									<h2 className="mt-4">Outras revistas</h2>
								</div>
							</div>

							{relateds && relateds.length > 0 ? (
								<div className="row">
									<div className="col-md-12">
										<Slider numberOf={2}>
											{relateds?.map((magazine) => (
												<CardMagazine key={magazine.id} magazine={magazine} />
											))}
										</Slider>
									</div>
								</div>
							) : (
								<div className="row">
									{loaders.map((key) => (
										<div className="col-md-6" key={key}>
											<Loader className="loader-card-library" />
										</div>
									))}
								</div>
							)}
						</div>
					</PageContainer>
				</main>

				<NewsletterBanner />
				<Footer />
			</>
		</Page>
	);
}
