import React from "react";
import { Icon } from "../../types/Icon";

export default function IconMagazine(props: Icon) {
	return (
		<svg
			className="icon icon-magazine"
			width="22"
			height="18"
			viewBox="0 0 22 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20 1C18.89 0.65 17.67 0.5 16.5 0.5C14.55 0.5 12.45 0.9 11 2C9.55 0.9 7.45 0.5 5.5 0.5C3.55 0.5 1.45 0.9 0 2V16.65C0 16.9 0.25 17.15 0.5 17.15C0.6 17.15 0.65 17.1 0.75 17.1C2.1 16.45 4.05 16 5.5 16C7.45 16 9.55 16.4 11 17.5C12.35 16.65 14.8 16 16.5 16C18.15 16 19.85 16.3 21.25 17.05C21.35 17.1 21.4 17.1 21.5 17.1C21.75 17.1 22 16.85 22 16.6V2C21.4 1.55 20.75 1.25 20 1ZM20 14.5C18.9 14.15 17.7 14 16.5 14C14.8 14 12.35 14.65 11 15.5V4C12.35 3.15 14.8 2.5 16.5 2.5C17.7 2.5 18.9 2.65 20 3V14.5Z"
				fill="#666666"
				className="fill"
			/>
			<path
				d="M16.5 6.5C17.38 6.5 18.23 6.59 19 6.76V5.24C18.21 5.09 17.36 5 16.5 5C14.8 5 13.26 5.29 12 5.83V7.49C13.13 6.85 14.7 6.5 16.5 6.5Z"
				fill="#666666"
				className="fill"
			/>
			<path
				d="M12 8.48991V10.1499C13.13 9.50991 14.7 9.15991 16.5 9.15991C17.38 9.15991 18.23 9.24991 19 9.41991V7.89991C18.21 7.74991 17.36 7.65991 16.5 7.65991C14.8 7.65991 13.26 7.95991 12 8.48991Z"
				fill="#666666"
				className="fill"
			/>
			<path
				d="M16.5 10.3301C14.8 10.3301 13.26 10.6201 12 11.1601V12.8201C13.13 12.1801 14.7 11.8301 16.5 11.8301C17.38 11.8301 18.23 11.9201 19 12.0901V10.5701C18.21 10.4101 17.36 10.3301 16.5 10.3301Z"
				fill="#666666"
				className="fill"
			/>
		</svg>
	);
}
