import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Thesi } from "../../types/Thesi";
import TruncateMarkup from "react-truncate-markup";
import { CedocItemType } from "../../types/Volume";
import { CedocThesi } from "../../types/CedocThesi";
import {
	AgendarCedocItemTypeEnum,
	AgendarCedocModal,
} from "../Modals/AgendarCedocModal";
import { CardCedocProps } from "./types";
import { ButtonShare } from "../Layout/ButtonShare";
import IconInstitution from "../Icons/IconInstitution";
import IconTitration from "../Icons/IconTitration";

interface CardThesiProps extends CardCedocProps {
	large?: boolean;
	thesi: Thesi | CedocThesi;
	type?: CedocItemType;
}

export default function CardThesi(props: CardThesiProps) {
	const { thesi, large, consultationText, type } = props;
	const [showAgendarModal, setShowAgendarModal] = useState<boolean>(false);
	const handleClose = () => setShowAgendarModal(false);

	return (
		<>
			<div
				className={
					"card card-library card-thesi" +
					(large ? " card-large" : " card-small")
				}
			>
				{large && <ThesiFolder thesi={thesi} className={"card-folder"} />}

				<div className="card-content">
					<div className="card-body">
						{(thesi.type === CedocItemType.ONLINE ||
							type === CedocItemType.ONLINE) && (
							<div className="card-label">Teses e Dissertações Eletrônicas</div>
						)}
						{(thesi.type === CedocItemType.FISICO ||
							type === CedocItemType.FISICO) && (
							<div className="card-label">Teses e Dissertações Físicas</div>
						)}

						<TruncateMarkup lines={2}>
							<div className="card-title">{thesi.title}</div>
						</TruncateMarkup>

						<TruncateMarkup lines={3}>
							<p className="card-description">{thesi.description}</p>
						</TruncateMarkup>

						<ul className="card-large-list">
							{(thesi.institution?.initials || thesi.course) && (
								<li>
									<IconInstitution />

									{thesi.institution?.initials && thesi.institution.initials}
									{thesi.institution?.initials && thesi.course && ", "}
									{thesi.course && thesi.course.title}
								</li>
							)}

							{thesi.titration && (
								<li>
									<IconTitration />

									{thesi.titration.title}
								</li>
							)}
						</ul>
					</div>

					<div className="card-actions">
						{(thesi.type === CedocItemType.FISICO ||
							type === CedocItemType.FISICO) && (
							<>
								<Link
									to={"/cedoc/teses-e-dissertacoes-fisicas/" + thesi.id}
									className="btn btn-primary-outline"
								>
									Ver mais
								</Link>

								<button
									className="btn btn-secondary-outline"
									onClick={() => setShowAgendarModal(true)}
								>
									Agendar consulta
								</button>

								<ButtonShare
									kind="icon"
									size="md"
									link={"/cedoc/teses-e-dissertacoes-fisicas/" + thesi.id}
								/>
							</>
						)}

						{(thesi.type === CedocItemType.ONLINE ||
							type === CedocItemType.ONLINE) && (
							<>
								<Link
									to={"/cedoc/teses-e-dissertacoes-eletronicas/" + thesi.id}
									className="btn btn-primary-outline"
								>
									Ver mais
								</Link>

								{(thesi as Thesi).link && (
									<a
										href={(thesi as Thesi).link}
										className="btn btn-secondary-outline"
										target="_blank"
										rel="noopener noreferrer"
									>
										Ler
									</a>
								)}

								<ButtonShare
									kind="icon"
									size="md"
									link={"/cedoc/teses-e-dissertacoes-eletronicas/" + thesi.id}
								/>
							</>
						)}
					</div>
				</div>
			</div>

			{thesi.type === CedocItemType.FISICO && (
				<AgendarCedocModal
					show={showAgendarModal}
					consultationText={consultationText}
					item={{
						type: AgendarCedocItemTypeEnum.CedocThesi,
						value: thesi as CedocThesi,
					}}
					onHide={handleClose}
				/>
			)}
		</>
	);
}

export const ThesiFolder = (props: {
	thesi: Thesi | CedocThesi;
	className?: string;
}) => {
	const { thesi, className } = props;

	return (
		<div className={"thesi-folder " + className}>
			{thesi.institution_name ? (
				<span>{thesi.institution_name}</span>
			) : (
				<span>{thesi.institution?.title ? thesi.institution.title : " "}</span>
			)}

			<span className="title">{thesi.title}</span>

			<span>{thesi.author ? thesi.author : " "}</span>
		</div>
	);
};
