import { useState, useEffect } from "react";
import { Service } from "../../types/Service";
import { NewsletterParams } from "../../types/NewsletterParams";

const apiUrl = process.env.REACT_APP_API_URL;

export interface NewsletterParamsModel {
	data: NewsletterParams[];
}

export const useServiceNewsletterParams = () => {
	const [service, setService] = useState<Service<NewsletterParamsModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "newsletterParams")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};
