import React from "react";

interface PageContainerProps {
 children?: React.ReactNode;
}

export default function PageContainer(props: PageContainerProps) {
 const children = props.children;

 return <div className="page-container">{children}</div>;
}
