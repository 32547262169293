import { useState, useEffect } from "react";
import { Service } from "../../types/Service";
import { Ebook } from "../../types/Ebook";
import { Thesi } from "../../types/Thesi";
import { Audiovisual } from "../../types/Audiovisual";
import { Volume } from "../../types/Volume";
import { Magazine } from "../../types/Magazine";
import { Pagination } from "../../types/Pagination";
import { Titration } from "../../types/Titration";
import { Language } from "../../types/Language";
import { Institution } from "../../types/Institution";
import { Course } from "../../types/Course";
import { Article } from "../../types/Article";
import { Newsletter } from "../../types/Newsletter";
import { CedocVolume } from "../../types/CedocVolume";
import { CedocParams } from "../../types/CedocParams";
import { CedocAudiovisualType } from "../../types/CedocAudiovisualType";
import { CedocThesi } from "../../types/CedocThesi";
import { CedocAudiovisual } from "../../types/CedocAudiovisual";
import { CedocBook } from "../../types/CedocBook";
import { Legislation } from "../../types/Legislation";

const apiUrl = process.env.REACT_APP_API_URL;

export interface LibraryModel extends Pagination {
	data: Array<Volume | Ebook | Thesi | Audiovisual | Magazine>;
}

export interface CedocParamsModel {
	data: CedocParams;
}

export const useServiceCedocParams = () => {
	const [service, setService] = useState<Service<CedocParamsModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "library/params")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface LibraryPaginatorModel {
	data: LibraryModel;
}

export const useServiceLibrary = (page: number, queryParams?: string) => {
	const [service, setService] = useState<Service<LibraryPaginatorModel>>({
		status: "loading",
	});

	if (!queryParams) queryParams = "";

	useEffect(() => {
		if (!page) {
			fetch(apiUrl + "library" + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		} else {
			fetch(apiUrl + "library/page/" + page + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		}
	}, [page, queryParams]);

	return service;
};

export interface LibraryLanguagesModel {
	data: Language[];
}

export const useServiceLibraryLanguages = () => {
	const [service, setService] = useState<Service<LibraryLanguagesModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "library/languages")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface LibraryInstitutionsModel {
	data: Institution[];
}

export const useServiceLibraryInstitutions = () => {
	const [service, setService] = useState<Service<LibraryInstitutionsModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "library/institutions")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface LibraryAudiovisualTypesModel {
	data: CedocAudiovisualType[];
}

export const useServiceLibraryAudiovisualTypes = () => {
	const [service, setService] = useState<Service<LibraryAudiovisualTypesModel>>(
		{
			status: "loading",
		}
	);

	useEffect(() => {
		fetch(apiUrl + "library/audiovisualTypes")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface LibraryCoursesModel {
	data: Course[];
}

export const useServiceLibraryCourses = () => {
	const [service, setService] = useState<Service<LibraryCoursesModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "library/courses")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface LibraryTitrationsModel {
	data: Titration[];
}

export const useServiceLibraryTitrations = () => {
	const [service, setService] = useState<Service<LibraryTitrationsModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "library/titrations")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface MagazineDetailModel {
	data: Magazine;
}

export const useServiceMagazineDetail = (id: string) => {
	const [service, setService] = useState<Service<MagazineDetailModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "magazines/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface MagazineVolumesPaginatedModel extends Pagination {
	data: Volume[];
}

export interface MagazineVolumesModelData {
	magazine: Magazine;
	volumes: MagazineVolumesPaginatedModel;
}

export interface MagazineVolumesModel {
	data: MagazineVolumesModelData;
}

export const useServiceMagazineWithVolumesDetail = (
	id: string,
	page: number,
	queryParams?: string
) => {
	const [service, setService] = useState<Service<MagazineVolumesModel>>({
		status: "loading",
	});

	if (!queryParams) queryParams = "";

	useEffect(() => {
		if (!page) {
			fetch(apiUrl + "magazines/volumes/" + id + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		} else {
			fetch(apiUrl + "magazines/volumes/" + id + "/page/" + page + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		}
	}, [id, page, queryParams]);

	return service;
};

export interface MagazinesModel extends Pagination {
	data: Magazine[];
}

export interface MagazinePaginatorModel {
	data: MagazinesModel;
}

export const useServiceMagazines = (page: number, queryParams?: string) => {
	const [service, setService] = useState<Service<MagazinePaginatorModel>>({
		status: "loading",
	});

	if (!queryParams) queryParams = "";

	useEffect(() => {
		if (!page) {
			fetch(apiUrl + "magazines" + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		} else {
			fetch(apiUrl + "magazines/page/" + page + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		}
	}, [page, queryParams]);

	return service;
};

interface MagazinesRelatedModel {
	data: Magazine[];
}

export const useServiceMagazinesRelated = (id: string) => {
	const [service, setService] = useState<Service<MagazinesRelatedModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "magazines/related/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface MagazinesLanguagesModel {
	data: Language[];
}

export const useServiceMagazinesLanguages = () => {
	const [service, setService] = useState<Service<MagazinesLanguagesModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "magazines/languages")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export const useServiceMagazineLanguages = (id: string) => {
	const [service, setService] = useState<Service<MagazinesLanguagesModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "magazines/languages/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface VolumeFisicoDetailModel {
	data: CedocVolume;
}

export const useServiceVolumeFisicoDetail = (id: string) => {
	const [service, setService] = useState<Service<VolumeFisicoDetailModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "cedoc_volumes/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface VolumeFisicoRelatedModel {
	data: CedocVolume[];
}

export const useServiceVolumeFisicoRelated = (id: string) => {
	const [service, setService] = useState<Service<VolumeFisicoRelatedModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "cedoc_volumes/related/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface VolumesModel {
	data: Volume[];
}

export const useServiceVolumes = () => {
	const [service, setService] = useState<Service<VolumesModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "volumes")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface VolumeDetailModel {
	data: Volume;
}

export const useServiceVolumeDetail = (id: string) => {
	const [service, setService] = useState<Service<VolumeDetailModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "volumes/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

interface VolumesRelatedModel {
	data: Volume[];
}

export const useServiceVolumesRelated = (id: string) => {
	const [service, setService] = useState<Service<VolumesRelatedModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "volumes/related/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export const useServiceVolumesOnlineFromMagazine = (id: string) => {
	const [service, setService] = useState<Service<VolumesModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "volumes/magazine/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface CedocVolumesModel {
	data: CedocVolume[];
}

export const useServiceVolumesFisicosFromMagazine = (id: string) => {
	const [service, setService] = useState<Service<CedocVolumesModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "volumes/magazine/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface ArticleDetailModel {
	data: Article;
}

export const useServiceArticleDetail = (id: string) => {
	const [service, setService] = useState<Service<ArticleDetailModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "articles/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface ArticlesRelatedModel {
	data: Article[];
}

export const useServiceArticlesRelated = (id: string) => {
	const [service, setService] = useState<Service<ArticlesRelatedModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "articles/related/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface BookDetailModel {
	data: Ebook;
}

export const useServiceBookDetail = (id: string) => {
	const [service, setService] = useState<Service<BookDetailModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "ebooks/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface BookFisicoDetailModel {
	data: CedocBook;
}

export const useServiceBookFisicoDetail = (id: string) => {
	const [service, setService] = useState<Service<BookFisicoDetailModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "cedoc_books/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface BookFisicoRelatedModel {
	data: CedocBook[];
}

export const useServiceBookFisicoRelated = (id: string) => {
	const [service, setService] = useState<Service<BookFisicoRelatedModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "cedoc_books/related/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface BooksModel extends Pagination {
	data: Ebook[];
}

export interface BooksPaginatorModel {
	data: BooksModel;
}

export const useServiceBooks = (page: number, queryParams?: string) => {
	const [service, setService] = useState<Service<BooksPaginatorModel>>({
		status: "loading",
	});

	if (!queryParams) queryParams = "";

	useEffect(() => {
		if (!page) {
			fetch(apiUrl + "ebooks" + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		} else {
			fetch(apiUrl + "ebooks/page/" + page + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		}
	}, [page, queryParams]);

	return service;
};

export interface BooksLanguagesModel {
	data: Language[];
}

export const useServiceBooksLanguages = () => {
	const [service, setService] = useState<Service<BooksLanguagesModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "ebooks/languages")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface BooksRelatedModel {
	data: Ebook[];
}

export const useServiceBooksRelated = (id: string) => {
	const [service, setService] = useState<Service<BooksRelatedModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "ebooks/related/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface ThesiDetailModel {
	data: Thesi;
}

export const useServiceThesiDetail = (id: string) => {
	const [service, setService] = useState<Service<ThesiDetailModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "thesis/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface ThesisModel extends Pagination {
	data: Thesi[];
}

export interface ThesisPaginatorModel {
	data: ThesisModel;
}

export const useServiceThesis = (page: number, queryParams?: string) => {
	const [service, setService] = useState<Service<ThesisPaginatorModel>>({
		status: "loading",
	});

	if (!queryParams) queryParams = "";

	useEffect(() => {
		if (!page) {
			fetch(apiUrl + "thesis" + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		} else {
			fetch(apiUrl + "thesis/page/" + page + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		}
	}, [page, queryParams]);

	return service;
};

export interface ThesisRelatedModel {
	data: Thesi[];
}

export const useServiceThesisRelated = (id: string) => {
	const [service, setService] = useState<Service<ThesisRelatedModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "thesis/related/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface ThesisInstitutionsModel {
	data: Institution[];
}

export const useServiceThesisInstitutions = () => {
	const [service, setService] = useState<Service<ThesisInstitutionsModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "thesis/institutions")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface ThesisCoursesModel {
	data: Course[];
}

export const useServiceThesisCourses = () => {
	const [service, setService] = useState<Service<ThesisCoursesModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "thesis/courses")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface ThesisTitrationsModel {
	data: Titration[];
}

export const useServiceThesisTitrations = () => {
	const [service, setService] = useState<Service<ThesisTitrationsModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "thesis/titrations")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface ThesisLanguagesModel {
	data: Language[];
}

export const useServiceThesisLanguages = () => {
	const [service, setService] = useState<Service<ThesisLanguagesModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "thesis/languages")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface ThesiFisicaDetailModel {
	data: CedocThesi;
}

export const useServiceThesiFisicaDetail = (id: string) => {
	const [service, setService] = useState<Service<ThesiFisicaDetailModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "cedoc_thesis/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface ThesisFisicasRelatedModel {
	data: CedocThesi[];
}

export const useServiceThesisFisicasRelated = (id: string) => {
	const [service, setService] = useState<Service<ThesisFisicasRelatedModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "cedoc_thesis/related/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface NewsletterDetailModel {
	data: Newsletter;
}

export const useServiceNewsletterDetail = (id: string) => {
	const [service, setService] = useState<Service<NewsletterDetailModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "newsletters/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface NewslettersModel extends Pagination {
	data: Newsletter[];
}

export interface NewslettersPaginatorModel {
	data: NewslettersModel;
}

export const useServiceNewsletters = () => {
	const [service, setService] = useState<Service<NewslettersPaginatorModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "newsletters")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export const useServiceNewslettersPaged = (
	page: number,
	queryParams?: string
) => {
	const [service, setService] = useState<Service<NewslettersPaginatorModel>>({
		status: "loading",
	});

	if (!queryParams) queryParams = "";

	useEffect(() => {
		if (!page) {
			fetch(apiUrl + "newsletters" + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		} else {
			fetch(apiUrl + "newsletters/page/" + page + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		}
	}, [page, queryParams]);

	return service;
};

export interface NewslettersRelatedModel {
	data: Newsletter[];
}

export const useServiceNewslettersRelated = (id: string) => {
	const [service, setService] = useState<Service<NewslettersRelatedModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "newsletters/related/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface NewslettersLanguagesModel {
	data: Language[];
}

export const useServiceNewslettersLanguages = () => {
	const [service, setService] = useState<Service<NewslettersLanguagesModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "newsletters/languages")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface AudiovisualDetailModel {
	data: Audiovisual;
}

export const useServiceAudiovisualDetail = (id: string) => {
	const [service, setService] = useState<Service<AudiovisualDetailModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "videos/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface AudiovisualsModel extends Pagination {
	data: Audiovisual[];
}

export interface AudiovisualsPaginatorModel {
	data: AudiovisualsModel;
}

export const useServiceAudiovisuals = (page: number, queryParams?: string) => {
	const [service, setService] = useState<Service<AudiovisualsPaginatorModel>>({
		status: "loading",
	});

	if (!queryParams) queryParams = "";

	useEffect(() => {
		if (!page) {
			fetch(apiUrl + "videos" + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		} else {
			fetch(apiUrl + "videos/page/" + page + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		}
	}, [page, queryParams]);

	return service;
};

export interface AudiovisualsRelatedModel {
	data: Audiovisual[];
}

export const useServiceAudiovisualsRelated = (id: string) => {
	const [service, setService] = useState<Service<AudiovisualsRelatedModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "videos/related/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface AudiovisualsLanguagesModel {
	data: Language[];
}

export const useServiceAudiovisualsLanguages = () => {
	const [service, setService] = useState<Service<AudiovisualsLanguagesModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "videos/languages")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface AudiovisualsInstitutionsModel {
	data: Institution[];
}

export const useServiceAudiovisualsInstitutions = () => {
	const [service, setService] = useState<
		Service<AudiovisualsInstitutionsModel>
	>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "videos/institutions")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface AudiovisualsTypesModel {
	data: CedocAudiovisualType[];
}

export const useServiceAudiovisualsTypes = () => {
	const [service, setService] = useState<Service<AudiovisualsTypesModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "videos/types")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface AudiovisualFisicoDetailModel {
	data: CedocAudiovisual;
}

export const useServiceAudiovisualFisicoDetail = (id: string) => {
	const [service, setService] = useState<Service<AudiovisualFisicoDetailModel>>(
		{
			status: "loading",
		}
	);

	useEffect(() => {
		fetch(apiUrl + "cedoc_audiovisuals/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface AudiovisualFisicoRelatedModel {
	data: CedocAudiovisual[];
}

export const useServiceAudiovisualFisicoRelated = (id: string) => {
	const [service, setService] = useState<
		Service<AudiovisualFisicoRelatedModel>
	>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "cedoc_audiovisuals/related/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface LegislationsModel extends Pagination {
	data: Legislation[];
}

export interface LegislationsPaginatorModel {
	data: LegislationsModel;
}

export const useServiceLegislations = (page: number, queryParams?: string) => {
	const [service, setService] = useState<Service<LegislationsPaginatorModel>>({
		status: "loading",
	});

	if (!queryParams) queryParams = "";

	useEffect(() => {
		if (!page) {
			fetch(apiUrl + "legislations" + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		} else {
			fetch(apiUrl + "legislations/page/" + page + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		}
	}, [page, queryParams]);

	return service;
};

export interface LegislationDetailModel {
	data: Legislation;
}

export const useServiceLegislationDetail = (id: string) => {
	const [service, setService] = useState<Service<LegislationDetailModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "legislations/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface LegislationRelatedModel {
	data: Legislation[];
}

export const useServiceLegislationRelated = (id: string) => {
	const [service, setService] = useState<Service<LegislationRelatedModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "legislations/related/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface LegislationsLanguagesModel {
	data: Language[];
}

export const useServiceLegislationsLanguages = () => {
	const [service, setService] = useState<Service<LegislationsLanguagesModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "legislations/languages")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export interface ServiceCedocEmailModel {
	data: { status: string; message: string };
}

export const useServiceCedocEmail = (email: string) => {
	const [service, setService] = useState<Service<ServiceCedocEmailModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "mails/send/" + email)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [email]);

	return service;
};
