import React from "react";
import Header from "../../components/Layout/Header";
import NewsletterBanner from "../../components/Layout/NewsletterBanner";
import Footer from "../../components/Layout/Footer";
import Metatags from "../../components/Layout/Metatags";
import NotFound from "./NotFound";

export default function PageNotFound(props: any) {
	return (
		<>
			<Metatags title="Página não encontrada" />

			<Header />

			<NotFound />

			<NewsletterBanner />
			<Footer />
		</>
	);
}
