import React from "react";

export default function IllustrationNewsletter() {
 return (
  <svg
   className="illustration illustration-newsletter"
   width="536"
   height="465"
   viewBox="0 0 536 465"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <g clipPath="url(#clip0)">
    <path
     d="M333.84 473.571L339.846 473.535C342.348 466.097 345.04 476.986 345.662 469.168C346.116 463.567 344.695 445.064 345.662 439.527C346.357 435.572 345.403 425.618 345.726 421.614C347.955 393.835 348.214 365.935 346.502 338.12C342.416 338.12 338.861 337.403 334.766 337.403"
     fill="#C8B55D"
    />
    <path
     d="M337.903 336.247C337.118 387.492 336.328 443.386 335.538 494.641C328.682 495.634 323.756 494.913 316.96 493.561C315.816 442.361 314.69 383.093 313.532 331.893"
     fill="#F1ECD6"
    />
    <path
     d="M330.353 158.462C331.588 143.991 333.077 129.415 331.415 114.989C329.753 100.563 324.605 86.0606 314.304 75.8115C297.533 59.1091 271.454 57.0729 247.773 56.7645C243.675 56.6379 239.573 56.7545 235.488 57.1137C234.276 57.2452 233.068 57.4085 231.856 57.6307C224.188 59.0502 217.037 62.5829 210.581 66.9592C189.81 81.0177 175.995 103.597 167.959 127.347C159.923 151.097 157.127 176.248 154.38 201.168L144.95 286.825C143.997 295.45 143.048 304.08 142.103 312.715C141.908 314.493 141.804 316.542 143.084 317.794C144.124 318.81 145.722 318.923 147.17 318.964C170.565 319.644 193.778 321.418 217.183 321.168C233.241 320.996 249.621 322.492 265.521 324.742C273.647 325.894 307.743 331.417 312.964 330.066C330.185 325.622 325.913 255.692 326.162 240.727C326.622 213.269 328.019 185.847 330.353 158.462Z"
     fill="#C8B55D"
    />
    <path
     d="M309.805 262.621C301.587 248.885 289.402 238.051 277.407 227.448L250.493 203.653C230.258 185.762 172.404 135.628 149.345 121.57C148.587 132.322 154.226 142.335 158.911 152.045C168.377 171.659 174.511 192.669 182.229 213.027C188.808 230.378 196.544 247.27 203.717 264.39C206.123 270.136 208.466 275.909 210.809 281.677C216.13 294.775 221.449 307.873 226.767 320.973C235.035 320.039 243.384 321.109 251.633 322.175L326.794 331.893L330.703 230.981C327.361 234.704 324.705 239.144 322.304 243.565C318.558 250.463 315.412 257.134 309.805 262.621Z"
     fill="#F1ECD6"
    />
    <path
     d="M253.649 156.004C239.97 152.974 225.955 151.074 212.816 146.226C205.356 143.478 198.287 139.814 191.064 136.49C177.626 130.315 163.674 125.321 149.368 121.565C172.427 135.623 230.281 185.762 250.516 203.648L277.398 227.448C289.393 238.051 301.578 248.885 309.796 262.621C315.403 257.125 318.549 250.463 322.295 243.574C324.696 239.153 327.352 234.713 330.694 230.99L332.56 183.032C306.768 172.556 280.83 162.035 253.649 156.004Z"
     fill="#E3DAAE"
    />
    <path
     d="M334.507 171.645C330.78 175.876 326.971 180.234 325.023 185.527C323.207 190.515 323.139 195.957 323.366 201.263C323.938 214.841 326.14 228.518 324.038 241.946C323.525 245.234 322.757 248.481 322.39 251.787C321.259 261.968 323.979 272.231 323.393 282.462C323.039 288.702 321.459 294.797 320.238 300.929C318.252 310.881 317.188 320.994 317.06 331.141C320.537 331.381 324.56 332.996 328.037 333.236"
     fill="#C8B55D"
    />
    <path
     d="M490.663 184.905C487.862 181.844 484.688 179.141 481.528 176.447C474.119 170.135 466.092 162.92 456.34 163.047C431.349 149.024 407.234 133.496 384.13 116.549C376.004 110.608 365.425 102.781 357.271 96.8764C338.076 82.9722 318.34 68.247 296.671 58.6238C292.235 56.6556 287.731 54.7736 282.991 53.7215C277.543 52.5106 271.914 52.429 266.329 52.4108C255.937 52.3836 244.7 52.9278 235.489 57.1C239.573 56.7409 243.676 56.6243 247.774 56.7508C271.455 57.0728 297.533 59.109 314.304 75.7978C324.601 86.0469 329.74 100.55 331.416 114.976C333.091 129.401 331.588 143.977 330.353 158.448C328.02 185.803 326.621 213.219 326.158 240.695C325.909 255.66 330.181 325.608 312.96 330.034C307.721 331.395 273.643 325.862 265.517 324.71C249.626 322.442 233.237 320.964 217.178 321.136C193.774 321.386 170.561 319.613 147.166 318.932C145.713 318.892 144.115 318.778 143.08 317.762C141.8 316.511 141.904 314.461 142.099 312.683C143.053 304.055 144.005 295.424 144.955 286.793C140.588 298.017 137.691 309.831 139.08 321.617C161.868 325.323 184.92 327.176 208.008 327.159C215.163 327.159 222.341 326.973 229.45 327.785C239.089 328.891 248.423 331.812 257.998 333.376C282.941 337.458 310.386 330.442 334.162 339.018C357.939 347.594 384.294 343.553 408.96 348.8C421.495 351.475 433.399 356.509 445.793 359.797C450.365 361.008 454.996 361.974 459.622 362.944L476.875 366.572C480.28 367.284 483.753 368.001 487.208 367.602C490.663 367.203 495.539 365.23 497.196 362.16C496.356 358.269 497.473 354.26 498.104 350.328C499.92 338.687 497.237 326.746 498.812 315.05C499.266 311.803 500.02 308.606 500.628 305.386C502.726 294.235 502.898 282.807 504.664 271.596C506.512 259.873 505.355 248.499 506.067 236.677C507.125 218.215 503.144 198.56 490.663 184.905Z"
     fill="#E3DAAE"
    />
    <path
     d="M396.057 209.716C396.57 203 400.229 196.977 402.118 190.51C403.516 185.722 403.934 180.715 404.329 175.74C406.049 154.058 407.379 132.349 408.714 110.64L411.379 67.2948L414.68 13.605C426.129 16.8661 436.066 24.045 442.751 33.8855C444.031 35.7902 445.207 37.8445 445.548 40.112C445.82 41.926 445.548 43.74 445.289 45.5223C443.791 55.6625 442.294 65.8012 440.799 75.9385C435.712 71.0666 429.764 67.1808 423.256 64.4785C417.917 103.827 414.547 143.383 413.145 183.146C413.045 185.953 412.955 188.769 413.068 191.576C413.327 198.093 414.666 204.555 414.516 211.077C414.394 216.632 412.923 227.512 405.159 226.65C398.072 225.865 395.625 215.439 396.057 209.716Z"
     fill="#D6C785"
    />
    <path
     d="M428.722 327.072C422.401 330.01 415.418 331.204 408.506 331.063C388.691 330.651 376.012 312.642 377.807 293.758C380.033 270.314 398.477 253.745 422.359 257.667C438.463 260.312 453.618 276.058 451.707 293.679C450.463 305.182 442.269 314.211 429.707 313.834C425.254 313.701 421.382 310.676 422.334 304.313L421.887 304.271C418.01 309.604 411.88 313.206 405.272 312.626C397.744 311.967 393.856 304.775 394.459 297.738C395.658 283.73 409.117 273.214 423.306 275.315C428.034 276.017 432.301 277.391 435.118 278.942C433.21 285.011 431.32 291.088 429.449 297.171C426.995 304.88 428.161 308.793 431.661 308.864C439.901 309.031 444.821 298.34 445.675 291.094C447.469 275.701 435.208 264.792 421.008 262.374C400.647 258.908 386.066 274.59 384.215 293.908C382.647 310.272 393.452 326.121 410.214 325.946C415.959 325.888 421.945 325.333 427.389 322.633C427.916 324.417 428.186 325.304 428.722 327.072ZM426.966 282.115C425.189 281.411 423.326 280.946 421.426 280.732C411.037 279.482 402.745 288.191 401.859 298.083C401.417 302.901 403.811 306.879 408.482 306.985C415.126 307.137 421.653 300.129 423.474 293.654C424.866 289.019 425.566 286.709 426.966 282.115Z"
     fill="#F1ECD6"
    />
    <path
     d="M405.678 219.798C406.495 220.705 408.025 220.541 408.969 219.798C409.913 219.054 410.399 217.866 410.785 216.723C411.648 214.179 414.871 199.739 407.766 202.791C405.119 203.916 404.157 218.133 405.678 219.798Z"
     fill="#E3DAAE"
    />
   </g>
   <defs>
    <clipPath id="clip0">
     <rect width="536" height="465" fill="white" />
    </clipPath>
   </defs>
  </svg>
 );
}
