import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { CedocAudiovisual } from "../../types/CedocAudiovisual";
import { CedocBook } from "../../types/CedocBook";
import { CedocThesi } from "../../types/CedocThesi";
import { CedocVolume } from "../../types/CedocVolume";
import IconInfo from "../Icons/IconInfo";
import NoFolder from "../../assets/no-folder.svg";
import { volumeTitle } from "../Cards/CardVolume";
import { ThesiFolder } from "../Cards/CardThesi";
import IconClose from "../Icons/IconClose";
import { Field, Form } from "react-final-form";
import { AgendarCedocCallbackModal } from "./AgendarCedocCallbackModal";

export enum AgendarCedocItemTypeEnum {
	CedocBook,
	CedocAudiovisual,
	CedocVolume,
	CedocThesi,
}

export interface AgendarCedocIdemModel {
	type: AgendarCedocItemTypeEnum;
	value: CedocBook | CedocVolume | CedocThesi | CedocAudiovisual;
}

export interface AgendarCedocModalProps {
	show?: boolean;
	item: AgendarCedocIdemModel;
	consultationText?: string;
	onHide(): void;
}

export interface CedocEmailModel {
	email: string;
}

export const AgendarCedocModal = (props: AgendarCedocModalProps) => {
	const { show, item, consultationText, onHide } = props;
	const [toEmail, setToEmail] = useState<string>();
	const [showCallback, setShowCallback] = useState<boolean>(false);

	const handleSubmit = (values: CedocEmailModel) => {
		if (values.email) {
			setToEmail(values.email);
			setShowCallback(true);
			onHide();
		}
	};

	return (
		<>
			<Modal className="modal-cedoc-agendar" show={show} onHide={onHide}>
				<Modal.Header>
					<Modal.Title>Agendar consulta</Modal.Title>

					<button className="btn btn-ghost btn-close" onClick={onHide}>
						<IconClose />
					</button>
				</Modal.Header>
				<Modal.Body>
					<Form<CedocEmailModel>
						onSubmit={handleSubmit}
						validate={(values) => {
							const errors = {} as CedocEmailModel;
							const value = values?.email;

							if (!value) {
								errors.email = "Campo obrigatório.";
							} else {
								if (!value.includes("@")) {
									errors.email = "E-mail inválido.";
								} else {
									const usuario =
										value && value.substring(0, value.indexOf("@"));
									const dominio =
										value &&
										value.substring(value.indexOf("@") + 1, value.length);

									if (
										!(
											usuario.length >= 1 &&
											dominio.length >= 3 &&
											usuario.search("@") === -1 &&
											dominio.search("@") === -1 &&
											usuario.search(" ") === -1 &&
											dominio.search(" ") === -1 &&
											dominio.search(".") !== -1 &&
											dominio.indexOf(".") >= 1 &&
											dominio.lastIndexOf(".") < dominio.length - 1
										)
									) {
										errors.email = "E-mail inválido.";
									}
								}
							}

							return errors;
						}}
						render={(renderProps) => (
							<>
								<div className="content">
									{consultationText && (
										<div className="info">
											<IconInfo />

											<p>{consultationText}</p>
										</div>
									)}

									<div className="label">Material</div>

									<div className="material-group">
										{item.type === AgendarCedocItemTypeEnum.CedocThesi ? (
											<ThesiFolder
												thesi={item.value as CedocThesi}
												className="image"
											/>
										) : item.value.folder || item.value.image ? (
											item.value.table_name ? (
												<img
													className="card-folder"
													src={
														process.env.REACT_APP_STORAGE_URL! +
														item.value.image
													}
													alt={item.value.title}
												/>
											) : (
												<img
													className="card-folder"
													src={
														process.env.REACT_APP_STORAGE_URL! +
														item.value.folder
													}
													alt={item.value.title}
												/>
											)
										) : (
											<img
												className="card-folder"
												src={NoFolder}
												alt="Capa não cadastrada"
											/>
										)}

										<div className="material-group-content">
											{getLabel(item.type)}

											<div className="material-group-content-title">
												{item.type === AgendarCedocItemTypeEnum.CedocVolume
													? volumeTitle(item.value as CedocVolume)
													: item.value.title}
											</div>

											{item.value.description && (
												<div className="material-group-content-paragraph">
													{item.value.description}
												</div>
											)}
										</div>
									</div>

									<div className="label">Seu e-mail</div>

									<Field
										name="email"
										type="email"
										render={({ input, meta }) => {
											const error = meta.error && meta.touched;
											return (
												<>
													<input
														className={
															"form-control " + (error ? "is-invalid" : "")
														}
														{...input}
														placeholder="Digite seu e-mail"
													/>
													{meta.error && meta.touched && (
														<span className="invalid-feedback">
															{meta.error}
														</span>
													)}
												</>
											);
										}}
									/>
								</div>

								<div className="actions">
									<button className="btn btn-dark-outline" onClick={onHide}>
										Cancelar
									</button>

									<button
										type="submit"
										className="btn btn-primary"
										onClick={renderProps.handleSubmit}
									>
										Agendar
									</button>
								</div>
							</>
						)}
					/>
				</Modal.Body>
			</Modal>

			{toEmail && showCallback && (
				<AgendarCedocCallbackModal
					show={showCallback}
					email={toEmail}
					onHide={() => setShowCallback(false)}
				/>
			)}
		</>
	);
};

const getLabel = (type: AgendarCedocItemTypeEnum) => {
	switch (type) {
		case AgendarCedocItemTypeEnum.CedocAudiovisual: {
			return (
				<div className="material-group-content-label cedoc-audiovisual">
					Mídia
				</div>
			);
		}

		case AgendarCedocItemTypeEnum.CedocBook: {
			return (
				<div className="material-group-content-label cedoc-book">Livro</div>
			);
		}

		case AgendarCedocItemTypeEnum.CedocThesi: {
			return (
				<div className="material-group-content-label cedoc-thesi">
					Teses e dissertações
				</div>
			);
		}

		case AgendarCedocItemTypeEnum.CedocVolume: {
			return (
				<div className="material-group-content-label cedoc-volume">Volume</div>
			);
		}
	}
};
