import React from "react";
import { Link } from "react-router-dom";
import IllustrationNotFound from "../../components/Illustrations/IllustrationNotFound";
import PageContainer from "../../components/Layout/PageContainer";

export default function NotFound(props: any) {
	return (
		<main className="PageNotFound">
			<PageContainer>
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="inner">
								<h1 className="display-1">Página não encontrada</h1>

								<IllustrationNotFound />

								<Link to="/" className="btn btn-primary btn-lg">
									Voltar para o início
								</Link>
							</div>
						</div>
					</div>
				</div>
			</PageContainer>
		</main>
	);
}
