import React, { useState, useEffect } from "react";
import Header from "../../../components/Layout/Header";
import Footer from "../../../components/Layout/Footer";
import PageContainer from "../../../components/Layout/PageContainer";
import {
	useServiceNewslettersRelated,
	useServiceNewsletterDetail,
} from "../Services";
import { Link, useParams } from "react-router-dom";
import NewsletterBanner from "../../../components/Layout/NewsletterBanner";
import { Newsletter } from "../../../types/Newsletter";
import Slider from "../../../components/Layout/Slider";
import CardNewsletter from "../../../components/Cards/CardNewsletter";
import Metatags from "../../../components/Layout/Metatags";
import Loader, { useLoaderCounter } from "../../../components/Layout/Loader";
import { getFileLink } from "../../../util/FileUploaded";
import { ButtonShare } from "../../../components/Layout/ButtonShare";
import Page from "../../../components/Layout/Page";

export default function NewsletterDetail() {
	const { id } = useParams<{ id: string }>();

	const [newsletter, setNewsletter] = useState<Newsletter>();
	const [relateds, setRelateds] = useState<Newsletter[]>();
	const service = useServiceNewsletterDetail(id);
	const serviceRelated = useServiceNewslettersRelated(id);
	const loaders = useLoaderCounter(2);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [id]);

	useEffect(() => {
		if (service.status === "loaded") {
			setNewsletter(service.payload.data);
		}
	}, [service]);

	useEffect(() => {
		if (serviceRelated.status === "loaded") {
			setRelateds(serviceRelated.payload.data);
		}
	}, [serviceRelated]);

	return (
		<Page service={service} item={newsletter}>
			<>
				{newsletter && (
					<Metatags
						title={newsletter.title}
						description={newsletter.description}
					/>
				)}

				<Header />
				<main className="NewsletterDetail">
					<PageContainer>
						{newsletter && (
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="inner">
											<nav>
												<ol className="breadcrumb">
													<li className="breadcrumb-item">
														<Link to={"/projetos/" + newsletter.project_id}>
															{newsletter.project.title}
														</Link>
													</li>
													<li className="breadcrumb-item active">
														{newsletter.title}
													</li>
												</ol>
											</nav>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-8 offset-md-2">
										<div className="inner">
											{newsletter.image && (
												<img
													src={
														process.env.REACT_APP_STORAGE_URL! +
														newsletter.image
													}
													alt={newsletter.title}
												/>
											)}

											<h1>{newsletter.title}</h1>

											<p className="lead-1">{newsletter.description}</p>

											<div className="actions">
												{newsletter.file && (
													<a
														className="btn btn-lg btn-primary"
														href={
															process.env.REACT_APP_STORAGE_URL! +
															getFileLink(newsletter.file)
														}
														target="_blank"
														rel="noopener noreferrer"
													>
														Ler
													</a>
												)}

												<ButtonShare
													kind="text"
													size="lg"
													skin="outline"
													link={window.location.pathname}
													text="Compartilhar"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}

						<div className="container">
							<hr />

							<div className="row">
								<div className="col-md-12">
									<h2 className="mt-4">Outros boletins</h2>
								</div>
							</div>

							{relateds && relateds.length > 0 ? (
								<div className="row">
									<div className="col-md-12">
										<Slider numberOf={2}>
											{relateds?.map((related) => (
												<CardNewsletter key={related.id} newsletter={related} />
											))}
										</Slider>
									</div>
								</div>
							) : (
								<div className="row">
									{loaders.map((key) => (
										<div className="col-md-6" key={key}>
											<Loader className="loader-card-library" />
										</div>
									))}
								</div>
							)}
						</div>
					</PageContainer>
				</main>

				<NewsletterBanner />
				<Footer />
			</>
		</Page>
	);
}
