import React, { useState, useEffect } from "react";
import { Audiovisual } from "../../../types/Audiovisual";
import { Pagination } from "../../../types/Pagination";
import {
	useServiceAudiovisuals,
	useServiceAudiovisualsInstitutions,
	useServiceAudiovisualsLanguages,
	useServiceAudiovisualsTypes,
} from "../Services";
import Paginator from "../../../components/Layout/Paginator";
import { Language } from "../../../types/Language";
import { Field, Form } from "react-final-form";
import { FormApi } from "final-form";
import { useHistory, useLocation, useParams } from "react-router-dom";
import arrayMutators from "final-form-arrays";
import LanguagesCheckListField from "../../../components/Filter/LanguagesCheckListField";
import Loader from "../../../components/Layout/Loader";
import NoResult from "../../../components/Layout/NoResult";
import { usePrevious } from "react-use";
import CardAudiovisual from "../../../components/Cards/CardAudiovisual";
import { CedocItemType } from "../../../types/Volume";
import CedocItemTypeCheckListField from "../../../components/Filter/CedocItemTypeCheckListField";
import { Institution } from "../../../types/Institution";
import { CedocAudiovisualType } from "../../../types/CedocAudiovisualType";
import AudiovisualsTypesCheckListField from "../../../components/Filter/AudiovisualsTypesCheckListField";

interface AudiovisualsFilterModel {
	q?: string;
	languages?: string[];
	institution?: string;
	itemType?: CedocItemType[];
	audiovisualsTypes?: string[];
}

export interface AudiovisualsContentProps {
	consultationText: string;
}

export default function AudiovisualsContent(props: AudiovisualsContentProps) {
	const { consultationText } = props;

	const history = useHistory();
	const location = useLocation();
	const { page } = useParams();
	const queryParams = location.search;
	const prev = usePrevious(location.pathname);
	const [prevIncludesPath, setPrevIncludesPath] = useState<boolean>(false);

	const [videos, setAudiovisuals] = useState<Audiovisual[]>();
	const [videosPaginatorData, setAudiovisualsPaginatorData] = useState<
		Pagination
	>();
	const [languages, setLanguages] = useState<Language[]>();
	const [institutions, setInstitutions] = useState<Institution[]>();
	const [audiovisualsTypes, setAudiovisualsTypes] = useState<
		CedocAudiovisualType[]
	>();
	const [filtered, setFiltered] = useState<boolean>(false);

	const serviceAudiovisuals = useServiceAudiovisuals(page, queryParams);
	const serviceLanguages = useServiceAudiovisualsLanguages();
	const serviceInstitutions = useServiceAudiovisualsInstitutions();
	const serviceAudiovisualsTypes = useServiceAudiovisualsTypes();

	const searchParams = new URLSearchParams(queryParams);

	const initialFilterData: AudiovisualsFilterModel = {
		q: searchParams.get("q") || undefined,
		itemType: searchParams.get("itemType")?.split(",") as CedocItemType[],
		institution: searchParams.get("institution") || undefined,
		languages: searchParams.get("languages")?.split(","),
		audiovisualsTypes: searchParams.get("audiovisualsTypes")?.split(","),
	};

	let loaders = [];
	for (let i = 0; i < 8; i++) {
		loaders.push(i);
	}

	const onFilter = (values: AudiovisualsFilterModel, form: FormApi) => {
		const searchParams = new URLSearchParams(queryParams);

		searchParams.delete("q");
		if (values.q) {
			searchParams.set("q", values.q);
		}

		searchParams.delete("itemType");
		if (values.itemType?.length) {
			searchParams.set("itemType", values.itemType.toString());
		}

		searchParams.delete("institution");
		if (values.institution && values.institution !== "all") {
			searchParams.set("institution", values.institution);
		}

		searchParams.delete("audiovisualsTypes");
		if (values.audiovisualsTypes?.length) {
			searchParams.set(
				"audiovisualsTypes",
				values.audiovisualsTypes.toString()
			);
		}

		searchParams.delete("languages");
		if (values.languages?.length) {
			searchParams.set("languages", values.languages.toString());
		}

		return history.push("/cedoc/midias?" + searchParams.toString());
	};

	useEffect(() => {
		if (
			initialFilterData.q ||
			initialFilterData.languages ||
			initialFilterData.institution
		) {
			setFiltered(true);
		}
	}, [initialFilterData]);

	useEffect(() => {
		if (serviceAudiovisuals.status === "loaded") {
			const paginatorData: Pagination = {
				current_page: serviceAudiovisuals.payload.data.current_page,
				first_page_url: serviceAudiovisuals.payload.data.first_page_url,
				from: serviceAudiovisuals.payload.data.from,
				last_page: serviceAudiovisuals.payload.data.last_page,
				last_page_url: serviceAudiovisuals.payload.data.last_page_url,
				next_page_url: serviceAudiovisuals.payload.data.next_page_url,
				path: serviceAudiovisuals.payload.data.path,
				per_page: serviceAudiovisuals.payload.data.per_page,
				prev_page_url: serviceAudiovisuals.payload.data.prev_page_url,
				to: serviceAudiovisuals.payload.data.to,
				total: serviceAudiovisuals.payload.data.total,
			};

			setAudiovisualsPaginatorData(paginatorData);
			setAudiovisuals(serviceAudiovisuals.payload.data.data);
		}
	}, [serviceAudiovisuals]);

	useEffect(() => {
		if (serviceInstitutions.status === "loaded") {
			setInstitutions(serviceInstitutions.payload.data);
		}
	}, [serviceInstitutions]);

	useEffect(() => {
		if (serviceAudiovisualsTypes.status === "loaded") {
			setAudiovisualsTypes(serviceAudiovisualsTypes.payload.data);
		}
	}, [serviceAudiovisualsTypes]);

	useEffect(() => {
		if (serviceLanguages.status === "loaded") {
			setLanguages(serviceLanguages.payload.data);
		}
	}, [serviceLanguages]);

	useEffect(() => {
		if (prevIncludesPath && page) window.scrollTo(0, 0);
	}, [page, prevIncludesPath]);

	useEffect(() => {
		setPrevIncludesPath(!!prev?.includes("audiovisual"));
	}, [prev]);

	return (
		<div className="container p-0">
			<div className="row">
				<div className="col-md-4">
					<div className="card filter">
						<div className="card-filter">
							<Form<AudiovisualsFilterModel>
								onSubmit={onFilter}
								mutators={{ ...arrayMutators }}
								initialValues={initialFilterData}
								render={({ handleSubmit }) => (
									<form onSubmit={handleSubmit}>
										<div className="card-header">
											<h5>Pesquisar</h5>
										</div>

										<div className="card-body">
											<Field
												type="text"
												name="q"
												component="input"
												className="form-control"
												placeholder="Busque por título, assunto, palavras-chave..."
											/>

											<h5 className="mt-2">Formato</h5>

											<CedocItemTypeCheckListField name="itemType" />

											{audiovisualsTypes && audiovisualsTypes.length > 1 && (
												<>
													<h5 className="">Tipo de mídia</h5>

													<AudiovisualsTypesCheckListField
														name="audiovisualsTypes"
														items={audiovisualsTypes}
													/>
												</>
											)}

											{institutions && institutions.length > 1 && (
												<>
													<h5 className="mt-2">Instituição</h5>

													<Field
														name="institution"
														type="select"
														initialValue={"all"}
														render={({ input, meta }) => (
															<div className="select-wrapper">
																<select {...input} className="form-control">
																	<option value={"all"}>Todas</option>

																	{institutions.map((institution) => (
																		<option
																			key={institution.id}
																			value={institution.id}
																		>
																			{institution.title}
																		</option>
																	))}
																</select>
															</div>
														)}
													/>
												</>
											)}

											{languages && languages.length > 1 && (
												<>
													<h5 className="">Idioma</h5>

													<LanguagesCheckListField
														name="languages"
														languages={languages}
													/>
												</>
											)}

											<button
												className="btn btn-primary"
												onClick={handleSubmit}
											>
												Filtrar
											</button>
										</div>
									</form>
								)}
							/>
						</div>
					</div>
				</div>
				<div className="col-md-8">
					{videos && videosPaginatorData ? (
						videos?.length > 0 ? (
							<>
								{videos?.map((video) => (
									<CardAudiovisual
										large
										key={video.id}
										audiovisual={video}
										consultationText={consultationText}
									/>
								))}
							</>
						) : (
							<NoResult filter={filtered} />
						)
					) : (
						loaders?.map((loader) => (
							<Loader key={loader} className="loader-card-video card-large" />
						))
					)}
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					{videos && videos.length > 0 && videosPaginatorData && (
						<Paginator
							path={"/cedoc/midias"}
							pagination={videosPaginatorData}
							queryParams={queryParams}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
