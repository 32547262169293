import React, { useEffect, useState } from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import PageBanner from "../../components/Layout/PageBanner";
import PageContainer from "../../components/Layout/PageContainer";
import NewsletterBanner from "../../components/Layout/NewsletterBanner";
import Metatags from "../../components/Layout/Metatags";
import { useServiceDocuments } from "./Services";
import { Document } from "../../types/Document";
import { getFileLink } from "../../util/FileUploaded";
import { dateFormatFull } from "../../util/DateTime";
import { Metatag } from "../../types/Metatag";
import { useServiceMetatag } from "../Services";

export default function Documents() {
	const [documents, setDocuments] = useState<Document[]>();
	const service = useServiceDocuments();
	const [metatag, setMetatag] = useState<Metatag>();
	const serviceMetatag = useServiceMetatag("institucional/documentos");

	useEffect(() => {
		if (serviceMetatag.status === "loaded") {
			setMetatag(serviceMetatag.payload.data[0]);
		}
	}, [serviceMetatag]);

	useEffect(() => {
		if (service.status === "loaded") {
			setDocuments(service.payload.data);
		}
	}, [service]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			{metatag && (
				<Metatags title={metatag.title} description={metatag.description} />
			)}

			<Header />

			<main className="Documents">
				<PageBanner loading={!metatag}>
					<div className="container">
						<div className="row">
							<div className="col-xl-6 col-md-6">
								<h1 className="display-1">{metatag?.title}</h1>

								<p className="lead-1">{metatag?.description}</p>
							</div>
						</div>
					</div>
				</PageBanner>

				<PageContainer>
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="card">
									<ul className="documents-list">
										{documents?.map((document, key) => {
											const link = getFileLink(document.file);

											return (
												<li key={key} className="documents-item">
													<div className="content">
														<div className="title">{document.title}</div>
														{link && (
															<div className="info">
																<span>{link.split(".")[1]}</span>
																{" | Publicado em " +
																	dateFormatFull(document.created_at)}
															</div>
														)}
													</div>

													<div className="actions">
														<a
															href={process.env.REACT_APP_STORAGE_URL! + link}
															target="_blank"
															rel="noopener noreferrer"
															className="btn btn-primary"
														>
															Download
														</a>
													</div>
												</li>
											);
										})}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</PageContainer>
			</main>

			<NewsletterBanner />
			<Footer />
		</>
	);
}
