import React from "react";
import { Overflow } from "./Overflow";

interface LoaderProps {
	className?: string;
	style?: React.CSSProperties;
}

export default function Loader(props: LoaderProps) {
	const { className, style } = props;

	return <div className={"loader " + className} style={style}></div>;
}

export function useLoaderCounter(n: number): Array<number> {
	if (window.innerWidth <= Overflow.mdMax) return [0];

	let loaders: number[] = [];
	for (let i = 0; i < n; i++) {
		loaders.push(i);
	}

	return loaders;
}
