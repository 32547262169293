import React, { useState, useEffect } from "react";
import Header from "../../../components/Layout/Header";
import Footer from "../../../components/Layout/Footer";
import PageContainer from "../../../components/Layout/PageContainer";
import {
	useServiceBookFisicoDetail,
	useServiceBookFisicoRelated,
	useServiceCedocParams,
} from "../Services";
import { Link, useParams } from "react-router-dom";
import NewsletterBanner from "../../../components/Layout/NewsletterBanner";
import Slider from "../../../components/Layout/Slider";
import CardEbook from "../../../components/Cards/CardEbook";
import Metatags from "../../../components/Layout/Metatags";
import Loader, { useLoaderCounter } from "../../../components/Layout/Loader";
import { CedocBook } from "../../../types/CedocBook";
import { CedocParams } from "../../../types/CedocParams";
import {
	AgendarCedocItemTypeEnum,
	AgendarCedocModal,
} from "../../../components/Modals/AgendarCedocModal";
import { ButtonShare } from "../../../components/Layout/ButtonShare";
import Page from "../../../components/Layout/Page";

export default function BookFisicoDetail() {
	const { id } = useParams<{ id: string }>();

	const [book, setBook] = useState<CedocBook>();
	const [relateds, setRelateds] = useState<CedocBook[]>();
	const service = useServiceBookFisicoDetail(id);
	const serviceRelated = useServiceBookFisicoRelated(id);
	const loaders = useLoaderCounter(2);

	const [cedocParams, setCedocParams] = useState<CedocParams>();
	const serviceCedoc = useServiceCedocParams();

	const [showAgendarModal, setShowAgendarModal] = useState<boolean>(false);
	const handleClose = () => setShowAgendarModal(false);

	useEffect(() => {
		if (serviceCedoc.status === "loaded") {
			setCedocParams(serviceCedoc.payload.data);
		}
	}, [serviceCedoc]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [id]);

	useEffect(() => {
		if (service.status === "loaded") {
			setBook(service.payload.data);
		}
	}, [service]);

	useEffect(() => {
		if (serviceRelated.status === "loaded") {
			setRelateds(serviceRelated.payload.data);
		}
	}, [serviceRelated]);

	return (
		<Page service={service} item={book}>
			<>
				<Header />

				<main className="EbookDetail">
					<PageContainer>
						{service.status === "loaded" && book ? (
							<>
								<Metatags title={book.title} description={book.description} />

								<div className="container">
									<div className="row">
										<div className="col-md-12">
											<div className="inner">
												<nav>
													<ol className="breadcrumb">
														<li className="breadcrumb-item">
															<Link to="/cedoc">CEDOC</Link>
														</li>
														<li className="breadcrumb-item">
															<Link to="/cedoc/livros">Livros</Link>
														</li>
														<li className="breadcrumb-item active">
															{book.title}
														</li>
													</ol>
												</nav>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-md-8 offset-md-2">
											<div className="inner">
												{book.folder && (
													<img
														src={
															process.env.REACT_APP_STORAGE_URL! + book.folder
														}
														alt={book.title}
													/>
												)}

												<h1>{book.title}</h1>

												{book.author && (
													<p className="authors">{book.author}</p>
												)}

												{book.description && (
													<p className="lead-1">{book.description}</p>
												)}

												{(book.isbn ||
													// book.author ||
													book.publisher ||
													book.address ||
													book.n_pages ||
													book.obs1 ||
													book.obs2) && (
													<ul className="list-detail">
														{book.isbn && (
															<li>
																<div className="label">ISBN</div>

																<div className="value">{book.isbn}</div>
															</li>
														)}

														{book.publisher?.title && (
															<li>
																<div className="label">Editora</div>

																<div className="value">
																	{book.publisher?.title}
																</div>
															</li>
														)}

														{!!book.n_pages && (
															<li>
																<div className="label">N. de páginas</div>

																<div className="value">{book.n_pages}</div>
															</li>
														)}

														{book.address && (
															<li>
																<div className="label">Local</div>

																<div className="value">{book.address}</div>
															</li>
														)}

														{book.obs1 && (
															<li>
																<div className="label">Obs. 1</div>

																<div className="value">{book.obs1}</div>
															</li>
														)}

														{book.obs2 && (
															<li>
																<div className="label">Obs. 2</div>

																<div className="value">{book.obs2}</div>
															</li>
														)}
													</ul>
												)}

												<div className="buttons-spacing-actions">
													<button
														className="btn btn-lg btn-primary"
														onClick={() => setShowAgendarModal(true)}
													>
														Agendar consulta
													</button>

													<ButtonShare
														kind="text"
														size="lg"
														skin="outline"
														link={window.location.pathname}
														text="Compartilhar"
													/>
												</div>

												<AgendarCedocModal
													show={showAgendarModal}
													consultationText={cedocParams?.consultation_text}
													item={{
														type: AgendarCedocItemTypeEnum.CedocBook,
														value: book,
													}}
													onHide={handleClose}
												/>
											</div>
										</div>
									</div>

									<hr />

									<div className="row">
										<div className="col-md-12">
											<h2 className="mt-4">Outros livros</h2>
										</div>
									</div>

									{relateds && relateds.length > 0 ? (
										<div className="row">
											<div className="col-md-12">
												<Slider numberOf={2}>
													{relateds?.map((related) => (
														<CardEbook
															large
															key={related.id}
															book={related}
															consultationText={cedocParams?.consultation_text}
														/>
													))}
												</Slider>
											</div>
										</div>
									) : (
										<div className="row">
											{loaders.map((key) => (
												<div className="col-md-6" key={key}>
													<Loader className="loader-card-library" />
												</div>
											))}
										</div>
									)}
								</div>
							</>
						) : (
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="inner">
											<div className="loader-description">
												<Loader className="line-1" />
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-8 offset-md-2">
										<div className="inner loading">
											<Loader className="image-loader" />

											<div className="loader-title">
												<Loader className="line-1" />

												<Loader className="line-2" />
											</div>

											<div className="loader-description">
												<Loader className="line-1" />

												<Loader className="line-2" />

												<Loader className="line-1" />

												<Loader className="line-2" />

												<Loader className="line-3" />
											</div>
										</div>
									</div>
								</div>

								<hr />

								<div className="row">
									<div className="col-md-12">
										<h2 className="mt-4">Outros livros</h2>
									</div>
								</div>

								<div className="row">
									{loaders.map((key) => (
										<div className="col-md-6" key={key}>
											<Loader className="loader-card-library" />
										</div>
									))}
								</div>
							</div>
						)}
					</PageContainer>
				</main>

				<NewsletterBanner />
				<Footer />
			</>
		</Page>
	);
}
