import React, { useEffect } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import PageContainer from "../components/Layout/PageContainer";
import NewsletterBanner from "../components/Layout/NewsletterBanner";
import IllustrationUnderConstruction from "../components/Illustrations/IllustrationUnderConstruction";
import Metatags from "../components/Layout/Metatags";

export default function UnderConstruction() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Metatags title="Página em construção" />

			<Header />

			<main className="UnderConstruction">
				<PageContainer>
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="inner">
									<h1 className="display-1 text-center">
										Página em construção
									</h1>

									<IllustrationUnderConstruction />
								</div>
							</div>
						</div>
					</div>
				</PageContainer>
			</main>

			<NewsletterBanner />
			<Footer />
		</>
	);
}
