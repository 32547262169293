import React from "react";

export default function IconTime() {
 return (
  <svg
   className="icon icon-time"
   width="16"
   height="16"
   viewBox="0 0 16 16"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    d="M7.992 0C3.576 0 0 3.584 0 8C0 12.416 3.576 16 7.992 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 7.992 0ZM8 14.4C4.464 14.4 1.6 11.536 1.6 8C1.6 4.464 4.464 1.6 8 1.6C11.536 1.6 14.4 4.464 14.4 8C14.4 11.536 11.536 14.4 8 14.4Z"
    className="fill"
   />
   <path
    d="M8.25 4.625C8.25 4.27982 7.97018 4 7.625 4V4C7.27982 4 7 4.27982 7 4.625V8.69377C7 9.03741 7.17645 9.35698 7.46726 9.54005L10.8314 11.6578C11.1285 11.8448 11.5214 11.7481 11.6978 11.4446V11.4446C11.8661 11.1549 11.7739 10.7838 11.4894 10.6067L8.72152 8.88369C8.42827 8.70114 8.25 8.38017 8.25 8.03475V4.625Z"
    className="fill"
   />
  </svg>
 );
}
