import React from "react";
import { Icon } from "../../types/Icon";

export default function IconMail(props: Icon) {
 return (
  <svg
   className="icon icon-mail"
   width={props.width || "22"}
   height={props.height || "17"}
   viewBox={
    props.width && props.height
     ? "0 0 " + props.width + " " + props.height
     : "0 0 22 17"
   }
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2.57812 0H19.4219C20.8435 0 22 1.18115 22 2.63298V13.867C22 15.3188 20.8435 16.5 19.4219 16.5H2.57812C1.15655 16.5 0 15.3188 0 13.867V2.63298C0 1.18115 1.15655 0 2.57812 0ZM19.4219 14.7447C19.8957 14.7447 20.2812 14.351 20.2812 13.867V3.17136L11.3027 9.99174C11.124 10.1275 10.8766 10.1275 10.6978 9.99174L1.71875 3.17094V13.867C1.71875 14.351 2.10427 14.7447 2.57812 14.7447H19.4219ZM2.73511 1.75532L10.6978 7.80409C10.8766 7.93989 11.124 7.93989 11.3027 7.80409L19.2654 1.75532H2.73511Z"
    className="fill"
   />
  </svg>
 );
}
