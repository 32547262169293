import React from "react";
import { Icon } from "../../types/Icon";

export default function IconEbook(props: Icon) {
	return (
		<svg
			className="icon icon-ebook"
			width="24"
			height="17"
			viewBox="0 0 24 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22 15V0H2V15H0V17H24V15H22ZM14 15H10V14H14V15ZM20 12H4V2H20V12Z"
				fill="#666666"
				className="fill"
			/>
		</svg>
	);
}
