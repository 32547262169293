import React, { useState, useEffect } from "react";
import { Thesi } from "../../../types/Thesi";
import { Pagination } from "../../../types/Pagination";
import {
	useServiceThesis,
	useServiceThesisTitrations,
	useServiceThesisLanguages,
	useServiceThesisInstitutions,
	useServiceThesisCourses,
} from "../Services";
import CardThesi from "../../../components/Cards/CardThesi";
import Paginator from "../../../components/Layout/Paginator";
import { Language } from "../../../types/Language";
import { Titration } from "../../../types/Titration";
import { Institution } from "../../../types/Institution";
import { Course } from "../../../types/Course";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import TitrationsCheckListField from "../../../components/Filter/TitrationsCheckListField";
import { FormApi } from "final-form";
import LanguagesCheckListField from "../../../components/Filter/LanguagesCheckListField";
import NoResult from "../../../components/Layout/NoResult";
import Loader from "../../../components/Layout/Loader";
import { usePrevious } from "react-use";
import CedocItemTypeCheckListField from "../../../components/Filter/CedocItemTypeCheckListField";
import { CedocItemType } from "../../../types/Volume";

interface ThesisFilterModel {
	q?: string;
	itemType?: CedocItemType[];
	titrations?: string[];
	institution?: string;
	course?: string;
	languages?: string[];
}

export interface ThesisContentProps {
	consultationText: string;
}

export default function ThesisContent(props: ThesisContentProps) {
	const { consultationText } = props;

	const history = useHistory();
	const location = useLocation();
	const { page } = useParams();
	const queryParams = location.search;
	const prev = usePrevious(location.pathname);
	const [prevIncludesPath, setPrevIncludesPath] = useState<boolean>(false);

	const [thesis, setThesis] = useState<Thesi[]>();
	const [thesisPaginatorData, setThesisPaginatorData] = useState<Pagination>();
	const [institutions, setInstitutions] = useState<Institution[]>();
	const [courses, setCourses] = useState<Course[]>();
	const [languages, setLanguages] = useState<Language[]>();
	const [titrations, setTitrations] = useState<Titration[]>();
	const [filtered, setFiltered] = useState<boolean>(false);

	const serviceThesis = useServiceThesis(page, queryParams);
	const serviceInstitutions = useServiceThesisInstitutions();
	const serviceCourses = useServiceThesisCourses();
	const serviceTitrations = useServiceThesisTitrations();
	const serviceLanguages = useServiceThesisLanguages();

	const searchParams = new URLSearchParams(queryParams);

	const initialFilterData: ThesisFilterModel = {
		q: searchParams.get("q") || undefined,
		itemType: searchParams.get("itemType")?.split(",") as CedocItemType[],
		institution: searchParams.get("institution") || undefined,
		course: searchParams.get("course") || undefined,
		titrations: searchParams.get("titrations")?.split(","),
		languages: searchParams.get("languages")?.split(","),
	};

	let loaders = [];
	for (let i = 0; i < 8; i++) {
		loaders.push(i);
	}

	const onFilter = (values: ThesisFilterModel, form: FormApi) => {
		const searchParams = new URLSearchParams(queryParams);

		searchParams.delete("q");
		if (values.q) {
			searchParams.set("q", values.q);
		}

		searchParams.delete("itemType");
		if (values.itemType?.length) {
			searchParams.set("itemType", values.itemType.toString());
		}

		searchParams.delete("titrations");
		if (values.titrations?.length) {
			searchParams.set("titrations", values.titrations.toString());
		}

		searchParams.delete("institution");
		if (values.institution && values.institution !== "all") {
			searchParams.set("institution", values.institution);
		}

		searchParams.delete("course");
		if (values.course && values.course !== "all") {
			searchParams.set("course", values.course);
		}

		searchParams.delete("languages");
		if (values.languages?.length) {
			searchParams.set("languages", values.languages.toString());
		}

		return history.push(
			"/cedoc/teses-e-dissertacoes?" + searchParams.toString()
		);
	};

	useEffect(() => {
		if (
			initialFilterData.q ||
			initialFilterData.languages ||
			initialFilterData.course ||
			initialFilterData.institution ||
			initialFilterData.titrations
		) {
			setFiltered(true);
		}
	}, [initialFilterData]);

	useEffect(() => {
		if (serviceThesis.status === "loaded") {
			const paginatorData: Pagination = {
				current_page: serviceThesis.payload.data.current_page,
				first_page_url: serviceThesis.payload.data.first_page_url,
				from: serviceThesis.payload.data.from,
				last_page: serviceThesis.payload.data.last_page,
				last_page_url: serviceThesis.payload.data.last_page_url,
				next_page_url: serviceThesis.payload.data.next_page_url,
				path: serviceThesis.payload.data.path,
				per_page: serviceThesis.payload.data.per_page,
				prev_page_url: serviceThesis.payload.data.prev_page_url,
				to: serviceThesis.payload.data.to,
				total: serviceThesis.payload.data.total,
			};

			setThesisPaginatorData(paginatorData);
			setThesis(serviceThesis.payload.data.data);
		}
	}, [serviceThesis]);

	useEffect(() => {
		if (serviceCourses.status === "loaded") {
			setCourses(serviceCourses.payload.data);
		}
	}, [serviceCourses]);

	useEffect(() => {
		if (serviceInstitutions.status === "loaded") {
			setInstitutions(serviceInstitutions.payload.data);
		}
	}, [serviceInstitutions]);

	useEffect(() => {
		if (serviceTitrations.status === "loaded") {
			setTitrations(serviceTitrations.payload.data);
		}
	}, [serviceTitrations]);

	useEffect(() => {
		if (serviceLanguages.status === "loaded") {
			setLanguages(serviceLanguages.payload.data);
		}
	}, [serviceLanguages]);

	useEffect(() => {
		if (prevIncludesPath && page) window.scrollTo(0, 0);
	}, [page, prevIncludesPath]);

	useEffect(() => {
		setPrevIncludesPath(!!prev?.includes("teses-e-dissertacoes"));
	}, [prev]);

	return (
		<div className="container p-0">
			<div className="row">
				<div className="col-md-4">
					<div className="card filter">
						<Form<ThesisFilterModel>
							onSubmit={onFilter}
							mutators={{ ...arrayMutators }}
							initialValues={initialFilterData}
							render={({ handleSubmit }) => (
								<form onSubmit={handleSubmit}>
									<div className="card-filter">
										<div className="card-header">
											<h5>Pesquisar</h5>
										</div>

										<div className="card-body">
											<Field
												type="text"
												name="q"
												component="input"
												className="form-control"
												placeholder="Busque por título, assunto, palavras-chave..."
											/>

											<h5 className="mt-2">Formato</h5>

											<CedocItemTypeCheckListField name="itemType" />

											{institutions && institutions.length > 1 && (
												<>
													<h5 className="mt-2">Instituição</h5>

													<Field
														name="institution"
														type="select"
														initialValue={"all"}
														render={({ input, meta }) => (
															<div className="select-wrapper">
																<select {...input} className="form-control">
																	<option value={"all"}>Todas</option>

																	{institutions.map((institution) => (
																		<option
																			key={institution.id}
																			value={institution.id}
																		>
																			{institution.title}
																		</option>
																	))}
																</select>
															</div>
														)}
													/>
												</>
											)}

											{courses && courses.length > 1 && (
												<>
													<h5 className="mt-2">Curso</h5>

													<Field
														name="course"
														type="select"
														initialValue={"all"}
														render={({ input, meta }) => (
															<div className="select-wrapper">
																<select {...input} className="form-control">
																	<option value={"all"}>Todos</option>

																	{courses.map((course) => (
																		<option key={course.id} value={course.id}>
																			{course.title}
																		</option>
																	))}
																</select>
															</div>
														)}
													/>
												</>
											)}

											{titrations && titrations.length > 1 && (
												<>
													<h5 className="mt-2">Titulação</h5>

													<TitrationsCheckListField
														name="titrations"
														titrations={titrations}
													/>
												</>
											)}

											{languages && languages.length > 1 && (
												<>
													<h5 className="mt-2">Idioma</h5>

													<LanguagesCheckListField
														name="languages"
														languages={languages}
													/>
												</>
											)}

											<button
												className="btn btn-primary"
												onClick={handleSubmit}
											>
												Filtrar
											</button>
										</div>
									</div>
								</form>
							)}
						/>
					</div>
				</div>

				<div className="col-md-8">
					{thesis && thesisPaginatorData ? (
						thesis?.length > 0 ? (
							<>
								{thesis?.map((thesi) => (
									<CardThesi
										large
										key={thesi.id}
										thesi={thesi}
										consultationText={consultationText}
									/>
								))}
							</>
						) : (
							<NoResult filter={filtered} />
						)
					) : (
						loaders?.map((loader) => (
							<Loader key={loader} className="loader-card-library card-large" />
						))
					)}
				</div>
			</div>

			<div className="row">
				<div className="col-md-12">
					{thesis && thesis.length > 0 && thesisPaginatorData && (
						<Paginator
							path={"/cedoc/teses-e-dissertacoes"}
							pagination={thesisPaginatorData}
							queryParams={queryParams}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
