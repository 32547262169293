import React from "react";
import IconShare from "../Icons/IconShare";
import SharePopup from "./SharePopup";

export interface ButtonShareProps {
	link: string;
	kind: "icon" | "text";
	size: "md" | "lg";
	skin?: "primary" | "outline";
	text?: string;
}

export function ButtonShare(props: ButtonShareProps) {
	const { link, kind, size, text, skin } = props;

	return (
		<SharePopup placement="top" link={window.location.origin + link}>
			<button
				className={
					"btn " +
					(kind === "icon"
						? "btn-dark-outline btn-share btn-margin"
						: (skin === "outline" ? "btn-primary-outline" : "btn-primary") +
						  (size === "lg" ? " btn-lg" : ""))
				}
			>
				{kind === "icon" ? <IconShare /> : text}
			</button>
		</SharePopup>
	);
}
