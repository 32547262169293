import React from "react";
import { Icon } from "../../types/Icon";

export default function IconYoutube(props: Icon) {
	return (
		<svg
			className="icon icon-youtube"
			width="29"
			height="20"
			viewBox="0 0 29 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M27.9764 3.12952C27.6473 1.90578 26.6824 0.941123 25.4588 0.611608C23.2235 0 14.2822 0 14.2822 0C14.2822 0 5.34112 0 3.10582 0.58842C1.90579 0.917586 0.917414 1.90596 0.588247 3.12952C0 5.36465 0 10 0 10C0 10 0 14.6587 0.588247 16.8705C0.917763 18.094 1.88225 19.0587 3.10599 19.3882C5.36466 20 14.2825 20 14.2825 20C14.2825 20 23.2235 20 25.4588 19.4116C26.6826 19.0822 27.6473 18.1176 27.9768 16.894C28.5648 14.6587 28.5648 10.0235 28.5648 10.0235C28.5648 10.0235 28.5884 5.36465 27.9764 3.12952V3.12952ZM11.4354 14.2823V5.7177L18.8706 10L11.4354 14.2823Z"
				className="fill"
			/>
		</svg>
	);
}
