import React from "react";
import { Icon } from "../../types/Icon";

export default function IconInfo(props: Icon) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="icon icon-info"
		>
			<path
				d="M12 0C5.36705 0 0 5.36752 0 12C0 18.633 5.36752 24 12 24C18.633 24 24 18.6325 24 12C24 5.36705 18.6324 0 12 0ZM12 22.125C6.40345 22.125 1.875 17.5962 1.875 12C1.875 6.40345 6.40383 1.875 12 1.875C17.5965 1.875 22.125 6.40383 22.125 12C22.125 17.5965 17.5961 22.125 12 22.125Z"
				fill="#524E7B"
			/>
			<path
				d="M12 10.0469C11.4822 10.0469 11.0625 10.4666 11.0625 10.9844V17.0216C11.0625 17.5393 11.4822 17.9591 12 17.9591C12.5178 17.9591 12.9375 17.5393 12.9375 17.0215V10.9844C12.9375 10.4666 12.5178 10.0469 12 10.0469Z"
				fill="#524E7B"
			/>
			<path
				d="M12 8.89893C12.699 8.89893 13.2656 8.33229 13.2656 7.6333C13.2656 6.93432 12.699 6.36768 12 6.36768C11.301 6.36768 10.7344 6.93432 10.7344 7.6333C10.7344 8.33229 11.301 8.89893 12 8.89893Z"
				fill="#524E7B"
			/>
		</svg>
	);
}
