import React, { useState, useEffect } from "react";
import Header from "../../../components/Layout/Header";
import Footer from "../../../components/Layout/Footer";
import PageContainer from "../../../components/Layout/PageContainer";
import { Link } from "react-router-dom";
import {
	useServiceCedocParams,
	useServiceThesiFisicaDetail,
	useServiceThesisFisicasRelated,
} from "../Services";
import Slider from "../../../components/Layout/Slider";
import CardThesi from "../../../components/Cards/CardThesi";
import NewsletterBanner from "../../../components/Layout/NewsletterBanner";
import Metatags from "../../../components/Layout/Metatags";
import Loader, { useLoaderCounter } from "../../../components/Layout/Loader";
import { CedocThesi } from "../../../types/CedocThesi";
import { CedocParams } from "../../../types/CedocParams";
import {
	AgendarCedocItemTypeEnum,
	AgendarCedocModal,
} from "../../../components/Modals/AgendarCedocModal";
import { CedocItemType } from "../../../types/Volume";
import { ButtonShare } from "../../../components/Layout/ButtonShare";
import Page from "../../../components/Layout/Page";

export default function ThesiFisicoDetail(props: any) {
	const id = props.match.params.id;
	const [thesi, setThesi] = useState<CedocThesi>();
	const [relateds, setRelateds] = useState<CedocThesi[]>();
	const loaders = useLoaderCounter(2);

	const service = useServiceThesiFisicaDetail(id);
	const serviceRelated = useServiceThesisFisicasRelated(id);

	const [cedocParams, setCedocParams] = useState<CedocParams>();
	const serviceCedoc = useServiceCedocParams();

	const [showAgendarModal, setShowAgendarModal] = useState<boolean>(false);
	const handleClose = () => setShowAgendarModal(false);

	useEffect(() => {
		if (serviceCedoc.status === "loaded") {
			setCedocParams(serviceCedoc.payload.data);
		}
	}, [serviceCedoc]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [id]);

	useEffect(() => {
		if (service.status === "loaded") {
			setThesi(service.payload.data);
		}
	}, [service]);

	useEffect(() => {
		if (serviceRelated.status === "loaded") {
			setRelateds(serviceRelated.payload.data);
		}
	}, [serviceRelated]);

	return (
		<Page service={service} item={thesi}>
			<>
				{thesi && (
					<Metatags
						title={thesi.title}
						description={thesi.description}
						keywords={thesi.keywords}
					/>
				)}

				<Header />

				<main className="ThesiDetail">
					<PageContainer>
						{thesi ? (
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<nav>
											<ol className="breadcrumb">
												<li className="breadcrumb-item">
													<Link to="/cedoc">CEDOC</Link>
												</li>
												<li className="breadcrumb-item">
													<Link to="/cedoc/teses-e-dissertacoes-fisicas">
														Teses e Dissertações Físicas
													</Link>
												</li>
												<li className="breadcrumb-item active">
													{thesi.title}
												</li>
											</ol>
										</nav>
									</div>
								</div>

								<div className="row">
									<div className="col-md-8 d-flex flex-column align-items-start">
										<h1 className="display-1">{thesi.title}</h1>

										{thesi.keywords && (
											<div className="keywords">
												<div className="label">Palavras-chave</div>

												<p>{thesi.keywords}</p>
											</div>
										)}

										<p className="lead-2 description">{thesi.description}</p>

										{(thesi.author ||
											thesi.institution?.title ||
											thesi.titration) && (
											<ul>
												{thesi.author && (
													<li>
														<div className="label">Autor(es)</div>

														<div className="value">{thesi.author}</div>
													</li>
												)}

												{thesi.titration?.title && (
													<li>
														<div className="label">Titulação</div>

														<div className="value">
															{thesi.titration?.title}
														</div>
													</li>
												)}

												{thesi.institution?.title && (
													<li>
														<div className="label">Instituição</div>

														<div className="value">
															{thesi.institution?.title}
														</div>
													</li>
												)}
											</ul>
										)}

										<div className="buttons-spacing-actions">
											<button
												className="btn btn-lg btn-primary"
												onClick={() => setShowAgendarModal(true)}
											>
												Agendar consulta
											</button>

											<ButtonShare
												kind="text"
												size="lg"
												skin="outline"
												link={window.location.pathname}
												text="Compartilhar"
											/>
										</div>

										<AgendarCedocModal
											show={showAgendarModal}
											consultationText={cedocParams?.consultation_text}
											item={{
												type: AgendarCedocItemTypeEnum.CedocThesi,
												value: thesi,
											}}
											onHide={handleClose}
										/>
									</div>
								</div>

								<hr />

								<div className="row">
									<div className="col-md-12">
										<h2 className="mt-4">Relacionadas</h2>
									</div>
								</div>

								{relateds && relateds.length > 0 ? (
									<div className="row">
										<div className="col-md-12">
											<Slider numberOf={2}>
												{relateds?.map((related) => (
													<CardThesi
														key={related.id}
														thesi={related}
														type={CedocItemType.FISICO}
														large
													/>
												))}
											</Slider>
										</div>
									</div>
								) : (
									<div className="row">
										{loaders.map((key) => (
											<div className="col-md-6" key={key}>
												<Loader className="loader-card-library" />
											</div>
										))}
									</div>
								)}
							</div>
						) : (
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<Loader className="loader-breadcrumb" />
									</div>
								</div>
								<div className="row">
									<div className="col-md-8">
										<div className="loader-title">
											<Loader className="line-1" />

											<Loader className="line-2" />
										</div>

										<div className="loader-description">
											<Loader className="line-1" />

											<Loader className="line-2" />

											<Loader className="line-1" />

											<Loader className="line-2" />

											<Loader className="line-1" />

											<Loader className="line-2" />

											<Loader className="line-3" />
										</div>
									</div>

									<div className="col-md-4 cards">
										<Loader />

										<Loader />
									</div>
								</div>

								<div className="row">
									<div className="col-md-12">
										<h2 className="mt-4">Relacionadas</h2>
									</div>
								</div>

								<div className="row">
									{loaders.map((key) => (
										<div className="col-md-6" key={key}>
											<Loader className="loader-card-library" />
										</div>
									))}
								</div>
							</div>
						)}
					</PageContainer>
				</main>

				<NewsletterBanner />
				<Footer />
			</>
		</Page>
	);
}
