import React from "react";
import Loader from "./Loader";

export interface PageBannerProps {
	className?: string;
	children?: React.ReactNode;
	loading: boolean;
}

export default function PageBanner(props: PageBannerProps) {
	const { children, className, loading } = props;

	return (
		<div
			className={`jumbotron page-banner ${
				loading ? "page-banner-loading" : ""
			} ${className}`}
		>
			{loading ? (
				<Loader style={{ width: "100%", height: "100%" }} />
			) : (
				children
			)}
		</div>
	);
}
