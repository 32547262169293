import React, { useState, useEffect } from "react";
import Header from "../../../components/Layout/Header";
import Footer from "../../../components/Layout/Footer";
import PageContainer from "../../../components/Layout/PageContainer";
import { Link } from "react-router-dom";
import NewsletterBanner from "../../../components/Layout/NewsletterBanner";
import Loader, { useLoaderCounter } from "../../../components/Layout/Loader";
import Metatags from "../../../components/Layout/Metatags";
import { Legislation } from "../../../types/Legislation";
import {
	useServiceLegislationDetail,
	useServiceLegislationRelated,
} from "../Services";
import CardLegislation from "../../../components/Cards/CardLegislation";
import Slider from "../../../components/Layout/Slider";
import { getFileLink } from "../../../util/FileUploaded";
import { ButtonShare } from "../../../components/Layout/ButtonShare";
import Page from "../../../components/Layout/Page";

export default function LegislationDetail(props: any) {
	const id = props.match.params.id;
	const [legislation, setLegislation] = useState<Legislation>();
	const [relateds, setRelateds] = useState<Legislation[]>();
	const loaders = useLoaderCounter(2);

	const service = useServiceLegislationDetail(id);
	const serviceRelated = useServiceLegislationRelated(id);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [id]);

	useEffect(() => {
		if (service.status === "loaded") {
			setLegislation(service.payload.data);
		}
	}, [service]);

	useEffect(() => {
		if (serviceRelated.status === "loaded") {
			setRelateds(serviceRelated.payload.data);
		}
	}, [serviceRelated]);

	return (
		<Page service={service} item={legislation}>
			<>
				{legislation && (
					<Metatags
						title={legislation.title}
						description={legislation.description}
					/>
				)}

				<Header />

				<main className="LegislationDetail">
					<PageContainer>
						{legislation ? (
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<nav>
											<ol className="breadcrumb">
												<li className="breadcrumb-item">
													<Link to="/cedoc">CEDOC</Link>
												</li>
												<li className="breadcrumb-item">
													<Link to="/cedoc/legislacoes">Legislações</Link>
												</li>
												<li className="breadcrumb-item active">
													{legislation.title}
												</li>
											</ol>
										</nav>
									</div>
								</div>

								<div className="row">
									<div className="col-md-8 d-flex flex-column align-items-start">
										<h1 className="display-1">{legislation.title}</h1>

										<p className="lead-2 mb-5">{legislation.description}</p>

										<div className="buttons-spacing-actions">
											{legislation.file && (
												<a
													href={
														process.env.REACT_APP_STORAGE_URL! +
														getFileLink(legislation.file)
													}
													className="btn btn-lg btn-primary"
													target="_blank"
													rel="noopener noreferrer"
												>
													Ler
												</a>
											)}

											<ButtonShare
												kind="text"
												size="lg"
												skin="outline"
												link={window.location.pathname}
												text="Compartilhar"
											/>
										</div>
									</div>
								</div>
							</div>
						) : (
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<Loader className="loader-breadcrumb" />
									</div>
								</div>
								<div className="row">
									<div className="col-md-8">
										<div className="loader-title">
											<Loader className="line-1" />

											<Loader className="line-2" />
										</div>

										<div className="loader-description">
											<Loader className="line-1" />

											<Loader className="line-2" />

											<Loader className="line-3" />
										</div>
									</div>
								</div>
							</div>
						)}

						<div className="container">
							<hr />

							<div className="row">
								<div className="col-md-12">
									<h2 className="mt-4">Outras legislações</h2>
								</div>
							</div>

							{relateds && relateds.length > 0 ? (
								<div className="row">
									<div className="col-md-12">
										<Slider numberOf={3}>
											{relateds?.map((related) => (
												<CardLegislation
													key={related.id}
													legislation={related}
												/>
											))}
										</Slider>
									</div>
								</div>
							) : (
								<div className="row">
									{loaders.map((key) => (
										<div className="col-md-6" key={key}>
											<Loader className="loader-card-library" />
										</div>
									))}
								</div>
							)}
						</div>
					</PageContainer>
				</main>

				<NewsletterBanner />
				<Footer />
			</>
		</Page>
	);
}
