import React, { useState } from "react";
import { Ebook } from "../../types/Ebook";
import { Link } from "react-router-dom";
import TruncateMarkup from "react-truncate-markup";
import { CedocItemType } from "../../types/Volume";
import NoFolder from "../../assets/no-folder.svg";
import { getFileLink } from "../../util/FileUploaded";
import {
	AgendarCedocItemTypeEnum,
	AgendarCedocModal,
} from "../Modals/AgendarCedocModal";
import { CedocBook } from "../../types/CedocBook";
import { CardCedocProps } from "./types";
import { ButtonShare } from "../Layout/ButtonShare";
import { FileUploaded } from "../../types/FileUploaded";
import IconAuthors from "../Icons/IconAuthors";

interface CardEbookProps extends CardCedocProps {
	large?: boolean;
	book: Ebook | CedocBook;
	type?: CedocItemType;
}

export default function CardEbook(props: CardEbookProps) {
	const { book, large, consultationText, type } = props;
	const [showAgendarModal, setShowAgendarModal] = useState<boolean>(false);

	const handleClose = () => setShowAgendarModal(false);

	return (
		<>
			<div
				className={
					"card card-library card-book" +
					(large ? " card-large" : " card-small")
				}
			>
				{book.folder || book.image ? (
					book.table_name ? (
						<img
							className="card-folder"
							src={process.env.REACT_APP_STORAGE_URL! + book.image}
							alt={book.title}
						/>
					) : (
						<img
							className="card-folder"
							src={process.env.REACT_APP_STORAGE_URL! + book.folder}
							alt={book.title}
						/>
					)
				) : (
					<img
						className="card-folder"
						src={NoFolder}
						alt="Capa não cadastrada"
					/>
				)}

				<div className="card-content">
					<div className="card-body">
						{(book.type === CedocItemType.ONLINE ||
							type === CedocItemType.ONLINE) && (
							<div className="card-label">Livro eletrônico</div>
						)}

						{(book.type === CedocItemType.FISICO ||
							type === CedocItemType.FISICO) && (
							<div className="card-label">Livro físico</div>
						)}

						<TruncateMarkup lines={2}>
							<div className="card-title">{book.title}</div>
						</TruncateMarkup>

						<TruncateMarkup lines={3}>
							<p className="card-description">{book.description}</p>
						</TruncateMarkup>

						{book.author && (
							<ul className="card-large-list">
								<li>
									<IconAuthors />

									{book.author}
								</li>
							</ul>
						)}
					</div>

					<div className="card-actions">
						{(book.type === CedocItemType.ONLINE ||
							type === CedocItemType.ONLINE) && (
							<>
								<Link
									to={"/cedoc/livros-eletronicos/" + book.id}
									className="btn btn-primary-outline"
								>
									Ver mais
								</Link>

								{book.file ? (
									<a
										href={
											process.env.REACT_APP_STORAGE_URL! +
											getFileLink(book.file)
										}
										target="_blank"
										rel="noopener noreferrer"
										className="btn btn-secondary-outline"
									>
										Ler
									</a>
								) : (
									book.link && (
										<a
											href={
												process.env.REACT_APP_STORAGE_URL! +
												getFileLink(book.link as FileUploaded)
											}
											target="_blank"
											rel="noopener noreferrer"
											className="btn btn-secondary-outline"
										>
											Ler
										</a>
									)
								)}

								<ButtonShare
									kind="icon"
									size="md"
									link={"/cedoc/livros-eletronicos/" + book.id}
								/>
							</>
						)}

						{(book.type === CedocItemType.FISICO ||
							type === CedocItemType.FISICO) && (
							<>
								<Link
									to={"/cedoc/livros-fisicos/" + book.id}
									className="btn btn-primary-outline"
								>
									Ver mais
								</Link>

								<button
									className="btn btn-secondary-outline"
									onClick={() => setShowAgendarModal(true)}
								>
									Agendar consulta
								</button>

								<ButtonShare
									kind="icon"
									size="md"
									link={"/cedoc/livros-fisicos/" + book.id}
								/>
							</>
						)}
					</div>
				</div>
			</div>

			{(book.type === CedocItemType.FISICO ||
				type === CedocItemType.FISICO) && (
				<AgendarCedocModal
					show={showAgendarModal}
					consultationText={consultationText}
					item={{
						type: AgendarCedocItemTypeEnum.CedocBook,
						value: book as CedocBook,
					}}
					onHide={handleClose}
				/>
			)}
		</>
	);
}
