import React from "react";

export default function IllustrationUnderConstruction() {
	return (
		<svg
			className="illustration illustration-under-connstruction"
			width="640"
			height="566"
			viewBox="0 0 640 566"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M88.9088 238.789C62.8507 290.83 54.506 349.622 42.6717 406.669C31.701 459.532 17.4495 511.661 0 562.753C14.2239 563.018 30.6098 564.535 44.8337 564.801C49.6178 540.101 55.9521 515.727 63.7989 491.823C69.7161 473.654 77.378 456.092 84.0917 438.189C107.065 376.881 119.033 311.997 119.443 246.527C111.136 244.175 97.3294 240.648 88.9088 238.789Z"
				fill="#8581AE"
			/>
			<path
				d="M166.058 205.296C139.962 257.375 131.617 316.167 119.783 373.214C108.817 426.066 94.5656 478.182 77.1113 529.26C91.3352 529.563 107.759 531.081 121.983 531.346C126.749 506.642 133.083 482.267 140.948 458.368C146.865 440.199 154.527 422.638 161.241 404.734C184.223 343.364 196.178 278.414 196.554 212.882C188.247 210.569 174.479 207.041 166.058 205.296Z"
				fill="#524E7B"
			/>
			<path
				d="M167.008 204.727C172.887 262.685 195.835 317.646 216.317 372.114C235.283 422.637 248.369 478.319 260.999 530.928C284.402 530.928 271.013 532.939 296.388 531.194C286.716 507.07 275.147 471.15 271.241 446.23C267.979 425.254 256.979 396.731 251.062 378.524C230.238 314.687 224.776 268.526 197.504 212.465C189.273 214.93 173.456 207.648 167.008 204.727Z"
				fill="#BAA234"
			/>
			<path
				d="M88.9082 238.789C92.187 237.763 95.1157 235.846 97.3667 233.251C113.335 219.103 137.421 217.737 157.031 208.52C160.026 206.762 163.276 205.482 166.666 204.727C169.263 204.789 171.83 205.302 174.252 206.244C182.634 208.293 189.12 210.455 197.503 212.465C186.124 216.258 176.793 219.33 165.414 222.972C155.814 225.799 146.412 229.258 137.269 233.327C132.415 235.601 127.726 238.212 123.235 241.14C121.718 242.164 120.504 246.451 118.684 246.868C116.863 247.285 112.653 245.199 110.415 244.63C104.08 243.226 95.2805 240.344 88.9082 238.789Z"
				fill="#D6C785"
			/>
			<path
				d="M109.582 296.632C112.86 295.605 115.788 293.688 118.041 291.095C134.047 276.909 158.133 275.581 177.743 266.326C180.58 264.722 183.672 263.618 186.884 263.064C189.613 262.939 192.344 263.273 194.963 264.05L220.149 270.157C208.77 273.95 197.391 277.136 186.012 280.778C176.42 283.628 167.019 287.087 157.867 291.133C153.032 293.435 148.356 296.058 143.871 298.984C142.316 300.008 141.14 304.294 139.282 304.674C137.423 305.053 133.289 303.005 131.051 302.474C124.868 300.881 115.954 298.188 109.582 296.632Z"
				fill="#D6C785"
			/>
			<path
				d="M124.334 350.873C127.642 349.864 130.599 347.945 132.868 345.335C148.837 331.149 172.961 329.822 192.533 320.605C195.363 318.978 198.457 317.861 201.674 317.305C204.418 317.191 207.162 317.537 209.791 318.329L234.977 324.436C223.598 328.229 212.219 331.377 200.84 335.018C191.245 337.875 181.844 341.347 172.695 345.411C167.841 347.686 163.151 350.297 158.661 353.225C157.144 354.249 155.93 358.535 154.109 358.952C152.289 359.37 148.078 357.283 145.841 356.715C139.582 355.159 130.706 352.428 124.334 350.873Z"
				fill="#D6C785"
			/>
			<path
				d="M137.008 393.621C140.295 392.611 143.237 390.707 145.504 388.121C161.511 373.935 185.597 372.57 205.207 363.353C208.049 361.752 211.139 360.637 214.348 360.053C217.079 359.939 219.81 360.285 222.427 361.077L247.651 367.184C236.272 370.977 224.893 374.163 213.513 377.804C203.914 380.631 194.512 384.091 185.369 388.159C180.51 390.438 175.82 393.062 171.335 396.011C169.78 396.997 168.604 401.283 166.783 401.7C164.963 402.117 160.752 400.031 158.514 399.5C152.18 397.907 143.19 395.214 137.008 393.621Z"
				fill="#D6C785"
			/>
			<path
				d="M151 435.42C154.312 434.421 157.272 432.5 159.534 429.883C175.503 415.734 199.627 414.369 219.199 405.152C222.037 403.542 225.128 402.426 228.34 401.852C231.084 401.738 233.828 402.084 236.457 402.876L261.643 408.983C250.264 412.776 238.885 415.962 227.506 419.603C217.908 422.437 208.507 425.896 199.361 429.958C194.515 432.225 189.838 434.836 185.365 437.772C183.81 438.796 182.596 443.082 180.775 443.5C178.955 443.917 174.782 441.831 172.507 441.262C166.248 439.706 157.372 437.013 151 435.42Z"
				fill="#D6C785"
			/>
			<path
				d="M161.695 477.219C165.001 476.205 167.957 474.287 170.23 471.682C186.198 457.496 210.322 456.168 229.932 446.951C232.746 445.319 235.829 444.202 239.035 443.651C241.779 443.537 244.523 443.883 247.152 444.675L272.338 450.744C260.959 454.537 249.58 457.723 238.201 461.365C228.615 464.232 219.215 467.69 210.057 471.72C205.217 474.014 200.541 476.637 196.06 479.571C194.505 480.595 193.291 484.881 191.471 485.299C189.65 485.716 185.478 483.63 183.24 483.061C176.981 481.506 168.068 478.775 161.695 477.219Z"
				fill="#D6C785"
			/>
			<path
				d="M174.938 523.039C178.25 522.04 181.21 520.119 183.472 517.501C199.441 503.353 223.564 501.988 243.136 492.771C245.982 491.177 249.07 490.062 252.278 489.471C255.021 489.357 257.765 489.703 260.395 490.495L285.58 496.602C274.201 500.395 262.822 503.581 251.443 507.222C241.846 510.056 232.444 513.515 223.299 517.577C218.453 519.844 213.775 522.455 209.302 525.391C207.747 526.415 206.534 530.701 204.713 531.118C202.892 531.535 198.72 529.449 196.444 528.88C190.375 527.325 181.462 524.632 174.938 523.039Z"
				fill="#D6C785"
			/>
			<path
				d="M88.9082 238.789C94.7874 296.709 117.735 351.67 138.218 406.176C157.183 456.661 170.269 512.381 182.9 564.991C206.303 564.991 199.437 566.584 224.623 564.801C215.33 541.36 207.782 491.216 201.524 466.789C196.82 448.241 193.937 428.858 187.944 410.652C167.121 346.777 153.807 301.868 119.253 246.527C110.453 243.72 95.7736 240.003 88.9082 238.789Z"
				fill="#C8B55D"
			/>
			<path
				d="M93.4978 255.061C94.2226 255.974 95.1925 256.663 96.294 257.046C97.3955 257.428 98.5832 257.49 99.7184 257.223C100.683 257.093 101.604 256.742 102.411 256.199C103.213 255.644 103.77 254.802 103.967 253.847C104.006 253.031 103.873 252.217 103.574 251.457C103.275 250.697 102.818 250.009 102.234 249.439C101.649 248.869 100.95 248.429 100.183 248.149C99.4161 247.87 98.5983 247.756 97.7839 247.816C94.484 248.423 91.4875 251.685 93.4978 255.061Z"
				fill="#95822A"
			/>
			<path
				d="M172.546 233.289C173.135 234.05 173.933 234.624 174.842 234.94C175.751 235.257 176.732 235.302 177.667 235.071C178.468 234.972 179.234 234.686 179.905 234.237C180.233 234.016 180.514 233.731 180.729 233.398C180.944 233.066 181.09 232.693 181.156 232.303C181.197 231.63 181.092 230.956 180.849 230.327C180.606 229.698 180.231 229.129 179.749 228.658C179.267 228.187 178.69 227.824 178.055 227.596C177.421 227.368 176.745 227.278 176.074 227.334C173.343 227.789 170.725 230.52 172.546 233.289Z"
				fill="#95822A"
			/>
			<path
				d="M107.723 0.47234C102.18 3.11654 97.9114 7.84925 95.8508 13.6342C93.8286 19.362 92.7773 25.3873 92.7405 31.4615C92.267 37.4659 92.8186 43.5072 94.3715 49.3267C96.4673 54.8164 99.1641 60.0574 102.413 64.954C110.606 79.102 141.784 101.291 147.55 116.577C153.619 119.915 151.002 134.632 153.657 128.222C154.827 124.797 155.152 121.141 154.605 117.563C154.227 111.7 152.948 105.93 150.812 100.457C146.905 90.7087 124.374 58.2403 118.04 49.7818C111.706 41.3234 106.661 30.2098 110.454 20.272C112.161 15.7583 115.612 11.8894 116.257 7.11016C116.414 6.02312 116.281 4.91386 115.87 3.89517C115.46 2.87647 114.787 1.98465 113.92 1.31034C113.053 0.636037 112.023 0.203306 110.934 0.0561162C109.846 -0.0910741 108.738 0.0525276 107.723 0.47234V0.47234Z"
				fill="#66629A"
			/>
			<path
				d="M299.383 28.5025C301.381 30.7249 302.922 33.3192 303.918 36.1372C304.914 38.9552 305.344 41.9418 305.186 44.9264C304.89 50.9974 303.609 56.9798 301.393 62.6399C299.602 68.3777 296.886 73.7845 293.352 78.6465C289.471 83.0769 285.108 87.059 280.342 90.5187C267.825 100.76 231.829 110.47 220.981 122.532C214.343 123.48 211.27 138.083 211.232 131.18C211.466 127.577 212.517 124.075 214.305 120.939C216.734 115.625 219.943 110.703 223.825 106.336C231.108 98.7496 263.197 76.4845 272.111 70.9087C281.024 65.3329 289.521 56.7607 289.748 46.1781C289.748 41.3989 288.231 36.5437 289.407 31.8783C289.591 30.8159 290.058 29.8228 290.759 29.0037C291.461 28.1846 292.37 27.5698 293.391 27.2242C294.412 26.8786 295.508 26.8149 296.563 27.0399C297.617 27.2648 298.591 27.7701 299.383 28.5025V28.5025Z"
				fill="#66629A"
			/>
			<path
				d="M219.466 48.9095C220.263 41.7407 213.435 35.9752 206.608 33.7373C199.78 31.4995 192.27 31.3477 185.898 28.0478C184.877 27.4276 183.716 27.0753 182.522 27.0237C180.974 27.2786 179.542 28.0075 178.426 29.1099C169.891 35.2167 157.071 31.8029 148.65 38.0235C146.323 39.8132 144.543 42.2179 143.511 44.9659C142.479 47.714 142.236 50.6959 142.809 53.575C150.493 55.5113 158.404 56.3918 166.326 56.1922H199.174C206.39 55.2803 213.317 52.7941 219.466 48.9095Z"
				fill="#8581AE"
			/>
			<path
				d="M148.384 129.777C148.384 141.156 148.384 170.059 148.384 181.476C148.299 182.362 148.456 183.253 148.839 184.056C149.494 185.05 150.494 185.766 151.646 186.066C157.071 187.655 162.732 188.284 168.373 187.925L218.707 187.621C220.432 169.226 220.775 150.727 219.731 132.281"
				fill="#66629A"
			/>
			<path
				d="M229.176 59.1509C227.393 52.551 223.562 45.0408 216.735 44.5097C213.422 44.5959 210.176 45.453 207.252 47.0131C185.215 55.7751 166.781 50.0476 143.757 44.2063C140.959 55.4339 141.116 67.1946 144.212 78.3437C147.336 91.2223 146.273 104.761 141.178 116.995C139.091 122.305 136.285 127.653 136.702 133.343C137.119 139.032 142.543 144.722 148.081 143.015C150.546 142.181 152.405 139.981 154.946 139.222C158.16 138.877 161.398 139.596 164.163 141.27C171.657 144.354 179.909 145.094 187.832 143.394C193.787 142.105 199.97 139.601 205.811 141.194C209.604 142.332 213.132 145.329 217.19 144.646C226.635 142.939 226.635 120.295 228.569 112.519C233.294 95.0689 233.503 76.7038 229.176 59.1509V59.1509Z"
				fill="#C8B55D"
			/>
			<path
				d="M151.229 184.624C147.702 183.865 144.136 182.955 140.609 182.044L122.668 177.493H122.402C109.62 174.231 108.406 188.531 111.933 201.237L116.144 222.478C127.523 225.361 127.523 223.995 137.271 230.633L131.43 200.023C138.947 204.904 147.371 208.22 156.198 209.772C171.939 212.806 171.37 207.989 181.194 205.22C176.188 222.971 183.318 249.03 182.636 267.426C196.708 263.064 199.098 270.005 213.587 267.426C220.073 244.668 211.88 209.772 216.014 186.558C196.329 184.624 170.574 188.872 151.229 184.624Z"
				fill="#8581AE"
			/>
			<path
				d="M112.428 210.91C111.982 210.777 111.508 210.777 111.062 210.91C110.735 211.127 110.464 211.419 110.273 211.762C110.082 212.106 109.975 212.489 109.962 212.882C109.591 215.316 109.879 217.804 110.797 220.089C111.69 222.186 112.977 224.092 114.59 225.702C118.762 230.027 140.382 240.647 139.168 226.765C138.486 218.496 119.255 211.554 112.428 210.91Z"
				fill="#3D3B5C"
			/>
			<path
				d="M204.217 273.837C206.522 273.775 208.802 273.339 210.968 272.547C212.044 272.136 213.027 271.515 213.861 270.721C214.694 269.926 215.361 268.974 215.823 267.919C216.224 266.694 216.441 265.416 216.468 264.126C216.567 263.448 216.488 262.755 216.241 262.116C215.779 261.389 215.083 260.84 214.268 260.561C207.201 257.572 199.666 255.839 192.003 255.44C187.869 255.137 183.621 254.871 179.486 254.985C174.517 254.985 174.024 256.882 175.162 261.813C178.121 274.633 193.407 274.443 204.217 273.837Z"
				fill="#3D3B5C"
			/>
			<path
				d="M498.039 359.646C497.772 358.276 497.044 357.039 495.976 356.14C494.946 355.614 493.789 355.386 492.636 355.48H476.715C482.489 366.864 484.428 379.733 487.15 392.23C490.353 406.871 494.346 421.328 499.111 435.537C501.05 441.394 502.163 444.859 508.804 445.849C511.083 446.421 513.482 446.295 515.689 445.485C517.896 444.676 519.808 443.222 521.177 441.312C525.755 432.857 514.248 410.955 511.526 402.294C507.016 388.105 502.521 373.89 498.039 359.646Z"
				fill="#66629A"
			/>
			<path
				d="M478.654 425.887C479.842 425.971 481.036 425.887 482.201 425.639C483.318 425.336 484.398 424.907 485.418 424.361L511.485 411.698C508.11 412.425 504.695 412.948 501.257 413.266C499.194 413.266 497.132 413.266 495.152 413.637C488.181 414.491 481.465 416.795 475.437 420.401C474.365 420.979 468.302 423.536 469.085 425.516C469.374 426.794 477.169 425.887 478.654 425.887Z"
				fill="#BAA234"
			/>
			<path
				d="M478.653 454.511C480.385 421.514 499.605 389.426 496.677 356.636C490.129 355.501 483.467 355.168 476.838 355.646C474.57 355.852 465.991 412.853 464.836 418.586C463.268 426.175 458.566 437.353 459.969 445.148C461.412 453.645 471.435 453.108 478.653 454.511Z"
				fill="#95822A"
			/>
			<path
				d="M375.541 359.646C375.274 358.276 374.546 357.039 373.478 356.14C372.434 355.611 371.263 355.382 370.096 355.48H354.217C359.991 366.864 361.93 379.733 364.652 392.23C367.855 406.871 371.848 421.328 376.613 435.537C378.551 441.394 379.624 444.859 386.306 445.849C388.585 446.421 390.984 446.295 393.191 445.486C395.398 444.676 397.31 443.222 398.679 441.312C403.257 432.857 391.75 410.955 389.028 402.294C384.518 388.105 380.023 373.89 375.541 359.646Z"
				fill="#66629A"
			/>
			<path
				d="M355.99 425.887C357.178 425.971 358.372 425.887 359.537 425.639C360.654 425.336 361.733 424.907 362.754 424.361L388.821 411.698C385.446 412.425 382.03 412.948 378.593 413.266C376.53 413.266 374.468 413.266 372.488 413.637C365.516 414.491 358.801 416.795 352.773 420.401C351.701 420.979 345.638 423.536 346.421 425.516C346.875 426.794 354.67 425.887 355.99 425.887Z"
				fill="#BAA234"
			/>
			<path
				d="M356.032 454.511C357.764 421.514 376.984 389.426 374.056 356.636C367.508 355.501 360.846 355.168 354.217 355.646C351.949 355.852 343.37 412.853 342.215 418.586C340.647 426.175 335.945 437.353 337.348 445.148C338.915 453.645 348.938 453.108 356.032 454.511Z"
				fill="#95822A"
			/>
			<path
				d="M507.753 355.562C507.753 355.562 495.175 354.476 495.408 354.52C483.762 354.129 382.833 352.869 359.774 353.477C359.541 356.431 359.774 358.776 359.541 361.73C381.784 361.513 408.065 362.425 430.308 362.251C445.836 362.251 460.665 362.251 477.28 361.73C485.028 361.605 492.763 362.465 500.338 364.293"
				fill="#D6C785"
			/>
			<path
				d="M499.225 357.135V364.064C502.074 363.99 504.879 363.345 507.474 362.167V355.444H499.225"
				fill="#BAA234"
			/>
			<path
				d="M615.796 243.047C615.525 241.657 614.782 240.404 613.693 239.5C612.661 238.979 611.502 238.765 610.352 238.881H594.473C600.247 250.306 602.186 263.174 604.908 275.672C608.111 290.313 612.104 304.77 616.869 318.979C618.807 324.836 619.88 328.301 626.561 329.291C628.841 329.852 631.237 329.719 633.441 328.911C635.645 328.103 637.558 326.655 638.935 324.754C643.513 316.504 632.006 294.397 629.284 285.694C624.719 271.506 620.223 257.29 615.796 243.047Z"
				fill="#524E7B"
			/>
			<path
				d="M596.244 309.41C597.432 309.452 598.621 309.369 599.791 309.163C600.908 308.859 601.987 308.43 603.008 307.884L629.075 295.222C625.7 295.945 622.284 296.468 618.846 296.789C616.784 296.789 614.722 296.789 612.701 297.119C605.744 298.008 599.046 300.325 593.027 303.924C591.955 304.502 585.891 307.059 586.675 309.039C587.129 310.152 594.924 309.41 596.244 309.41Z"
				fill="#BAA234"
			/>
			<path
				d="M596.284 337.91C598.016 304.914 617.195 272.825 614.308 240.035C607.762 238.879 601.098 238.547 594.469 239.045C592.16 239.251 583.622 296.252 582.426 301.985C580.9 309.575 576.156 320.752 577.6 328.547C579.167 337.044 589.19 336.467 596.284 337.91Z"
				fill="#95822A"
			/>
			<path
				d="M493.296 243.047C493.025 241.657 492.282 240.404 491.193 239.5C490.161 238.979 489.002 238.765 487.852 238.881H471.973C477.747 250.306 479.686 263.174 482.408 275.672C485.611 290.313 489.604 304.77 494.369 318.979C496.307 324.836 497.38 328.301 504.061 329.291C506.341 329.852 508.737 329.719 510.941 328.911C513.145 328.103 515.058 326.655 516.435 324.754C521.013 316.504 509.506 294.397 506.784 285.694C502.219 271.506 497.723 257.29 493.296 243.047Z"
				fill="#95822A"
			/>
			<path
				d="M473.743 309.41C474.931 309.452 476.12 309.369 477.29 309.163C478.407 308.859 479.486 308.43 480.507 307.884L506.574 295.222C503.198 295.945 499.783 296.468 496.345 296.789C494.283 296.789 492.221 296.789 490.2 297.119C483.243 298.008 476.545 300.325 470.526 303.924C469.453 304.502 463.349 307.059 464.174 309.039C464.628 310.152 472.423 309.41 473.743 309.41Z"
				fill="#BAA234"
			/>
			<path
				d="M473.79 337.91C475.522 304.914 494.701 272.825 491.814 240.035C485.268 238.879 478.604 238.547 471.975 239.045C469.666 239.251 461.087 296.252 459.932 301.985C458.406 309.575 453.662 320.752 455.106 328.547C456.673 337.044 466.696 336.467 473.79 337.91Z"
				fill="#D6C785"
			/>
			<path
				d="M626.287 238.198C626.081 238.198 612.923 237.167 613.13 237.167C600.756 236.837 493.518 235.641 469.06 236.219C468.812 239.023 469.06 241.251 468.812 244.055C492.446 244.055 520.369 244.715 544.003 244.509C560.501 244.509 576.256 244.509 593.909 244.055C602.142 243.937 610.361 244.753 618.409 246.489"
				fill="#D6C785"
			/>
			<path
				d="M618.08 239.56V246.49C620.928 246.41 623.732 245.765 626.329 244.592V237.828H618.08"
				fill="#BAA234"
			/>
			<path
				d="M449.772 358.625C448.512 359.885 449.404 362.039 451.186 362.039H489.166C489.697 362.039 490.206 361.829 490.581 361.454L626.526 225.508C627.786 224.249 626.893 222.094 625.112 222.094H587.131C586.601 222.094 586.092 222.305 585.717 222.68L449.772 358.625Z"
				fill="#C8B55D"
			/>
			<path
				d="M489.091 222.094C488.561 222.094 488.052 222.305 487.677 222.68L355.729 354.628C355.628 354.729 355.516 354.819 355.396 354.897L350.009 358.357C348.329 359.436 349.093 362.039 351.09 362.039H397.478C398.009 362.039 398.517 361.829 398.892 361.454L534.838 225.508C536.098 224.249 535.205 222.094 533.423 222.094H489.091Z"
				fill="#C8B55D"
			/>
			<path
				d="M539.08 222.094C538.55 222.094 538.041 222.305 537.666 222.68L401.721 358.625C400.461 359.885 401.353 362.039 403.135 362.039H445.529C446.059 362.039 446.568 361.829 446.943 361.454L582.888 225.508C584.148 224.249 583.256 222.094 581.474 222.094H539.08Z"
				fill="#D6C785"
			/>
			<path
				d="M500.061 252.656C501.691 252.995 503.389 252.725 504.833 251.897C506.277 251.068 507.367 249.739 507.897 248.161C508.805 243.747 503.773 240.613 499.937 238.509C480.634 228.322 466.033 210.215 445.823 201.966C444.627 201.43 442.936 201.059 442.152 201.966C441.96 202.357 441.859 202.788 441.859 203.224C441.859 203.66 441.96 204.091 442.152 204.482C443.398 208.546 445.493 212.3 448.298 215.494C455.666 224.143 464.352 231.576 474.035 237.519C480.758 242.304 491.771 251.543 500.061 252.656Z"
				fill="#D6C785"
			/>
			<path
				d="M426.355 186.953L405.031 202.502C403.352 203.636 401.862 205.028 400.618 206.627C399.141 208.937 398.184 211.541 397.813 214.257C392.281 242.118 392.703 270.834 399.05 298.521C400.123 303.264 401.113 303.1 403.67 307.265C416.415 294.892 447.761 265.401 446.936 263.587C438.934 246.181 445.286 225.723 449.081 206.916"
				fill="#8581AE"
			/>
			<path
				d="M490.491 181.261C492.305 177.255 493.251 172.911 493.265 168.514C493.279 164.116 492.361 159.766 490.573 155.748C488.785 151.731 486.166 148.138 482.888 145.206C479.611 142.274 475.75 140.069 471.56 138.737C465.485 137.122 459.188 136.509 452.917 136.922C444.026 136.436 435.158 138.21 427.138 142.078C411.506 151.069 405.98 173.754 388.698 178.869C384.427 180.075 379.901 180.032 375.654 178.746C371.407 177.459 367.618 174.983 364.734 171.61C364.939 174.516 365.835 177.332 367.349 179.822C368.863 182.311 370.95 184.403 373.437 185.922C378.423 188.89 383.854 191.035 389.523 192.273L422.766 201.43"
				fill="#66629A"
			/>
			<path
				d="M471.602 158.411C467.647 158.488 463.692 158.295 459.764 157.833C457.8 157.556 455.955 156.726 454.443 155.441C452.932 154.156 451.816 152.468 451.227 150.574C451.217 149.356 450.89 148.162 450.278 147.109C449.868 146.681 449.363 146.354 448.805 146.154C448.247 145.953 447.65 145.885 447.061 145.955C445.392 146.111 443.784 146.662 442.37 147.563C440.957 148.464 439.778 149.689 438.931 151.136C438.085 152.583 437.595 154.211 437.503 155.885C437.41 157.558 437.718 159.23 438.399 160.762C439.033 161.691 439.532 162.704 439.884 163.772C440.668 167.897 435.43 169.671 432.254 172.021C427.552 175.651 426.686 182.374 427.016 188.272C427.024 191.888 427.931 195.446 429.655 198.625C431.487 201.057 433.853 203.036 436.57 204.409C439.288 205.782 442.284 206.512 445.329 206.544C451.392 206.998 457.702 206.09 463.559 206.544C466.533 206.564 469.422 207.532 471.808 209.307C472.179 209.623 472.621 209.843 473.096 209.95C473.571 210.057 474.065 210.048 474.535 209.922C475.006 209.796 475.439 209.558 475.797 209.229C476.155 208.899 476.428 208.487 476.593 208.029C477.253 205.531 478.326 203.161 479.768 201.017C481.296 199.485 482.967 198.104 484.759 196.892C490.245 192.397 491.565 184.519 492.513 177.59C494.122 164.515 484.099 159.194 471.602 158.411Z"
				fill="#D6C785"
			/>
			<path
				d="M414.063 139.108C416.167 140.544 418.372 141.826 420.662 142.944C426.97 138.89 432.364 133.566 436.5 127.312L444.749 116.382C446.358 114.382 447.741 112.21 448.874 109.906C443.264 107.184 443.017 107.473 438.933 111.845C435.932 114.955 432.766 117.902 429.447 120.671C428.21 121.95 411.794 137.499 414.063 139.108Z"
				fill="#95822A"
			/>
			<path
				d="M422.354 123.971C421.857 122.843 421.69 121.597 421.872 120.378C422.055 119.159 422.579 118.016 423.385 117.083C424.557 116.049 425.979 115.338 427.51 115.021C438.522 111.804 446.524 116.918 456.917 121.744C459.35 122.754 461.606 124.147 463.599 125.868C466.751 129.48 469.35 133.538 471.312 137.912C478.217 149.6 481.45 163.093 480.592 176.641C480.38 182.071 478.775 187.354 475.932 191.984C474.252 194.45 472.239 196.67 469.951 198.584C455.598 210.957 435.965 214.339 417.322 217.35C414.225 218.098 411.003 218.168 407.877 217.557C406.454 217.374 405.125 216.747 404.08 215.765C403.035 214.783 402.327 213.495 402.057 212.087C401.787 210.678 401.969 209.22 402.577 207.921C403.186 206.622 404.189 205.548 405.443 204.853C407.513 203.571 409.766 202.611 412.125 202.007C421.075 199.202 429.861 195.944 438.44 192.314C445.699 189.221 453.329 185.385 456.917 178.374C458.825 174.134 459.606 169.475 459.186 164.845C459.067 161.506 458.369 158.212 457.124 155.111C453.577 147.192 445.864 143.934 439.388 138.984C433.985 135.025 425.035 130.323 422.354 123.971Z"
				fill="#BAA234"
			/>
			<path
				d="M437.41 154.451C437.285 155.24 436.934 155.975 436.4 156.568C435.865 157.161 435.17 157.587 434.399 157.792C433.046 158.142 431.627 158.142 430.274 157.792C424.342 156.696 418.629 154.634 413.364 151.688C412.275 151.223 411.299 150.53 410.502 149.655C409.704 148.779 409.105 147.742 408.744 146.615C408.373 144.965 408.744 143.192 408.414 141.542C408.326 140.768 408.047 140.027 407.602 139.388C407.157 138.748 406.56 138.229 405.865 137.877C405.17 137.526 404.398 137.352 403.619 137.373C402.84 137.393 402.079 137.607 401.403 137.995C400.946 138.402 400.448 138.761 399.918 139.067C397.979 140.016 396.041 138.077 394.68 136.345L398.474 130.9C400.207 128.426 402.599 125.662 405.197 126.776C405.957 127.283 406.528 128.027 406.819 128.893C407.11 129.759 407.105 130.697 406.806 131.56L423.964 143.48C426.81 145.46 425.036 150.203 427.346 152.843C428.336 154.204 437.039 156.225 437.41 154.451Z"
				fill="#D6C785"
			/>
		</svg>
	);
}
