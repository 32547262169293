import React, { useState, useEffect } from "react";
import Header from "../../../components/Layout/Header";
import Footer from "../../../components/Layout/Footer";
import PageContainer from "../../../components/Layout/PageContainer";
import { Link, useParams } from "react-router-dom";
import NewsletterBanner from "../../../components/Layout/NewsletterBanner";
import CardVolume, { volumeTitle } from "../../../components/Cards/CardVolume";
import Metatags from "../../../components/Layout/Metatags";
import Loader, { useLoaderCounter } from "../../../components/Layout/Loader";
import { CedocVolume } from "../../../types/CedocVolume";
import Slider from "../../../components/Layout/Slider";
import {
	useServiceCedocParams,
	useServiceVolumeFisicoDetail,
	useServiceVolumeFisicoRelated,
} from "../Services";
import { CedocParams } from "../../../types/CedocParams";
import {
	AgendarCedocItemTypeEnum,
	AgendarCedocModal,
} from "../../../components/Modals/AgendarCedocModal";
import { ButtonShare } from "../../../components/Layout/ButtonShare";
import Page from "../../../components/Layout/Page";

export default function VolumeFisicoDetail() {
	const { id } = useParams<{ id: string }>();

	const [volume, setVolume] = useState<CedocVolume>();
	const [relateds, setRelated] = useState<CedocVolume[]>();
	const loaders = useLoaderCounter(2);

	const service = useServiceVolumeFisicoDetail(id);
	const serviceVolumesRelated = useServiceVolumeFisicoRelated(id);

	const [cedocParams, setCedocParams] = useState<CedocParams>();
	const serviceCedoc = useServiceCedocParams();

	const [showAgendarModal, setShowAgendarModal] = useState<boolean>(false);
	const handleClose = () => setShowAgendarModal(false);

	useEffect(() => {
		if (serviceCedoc.status === "loaded") {
			setCedocParams(serviceCedoc.payload.data);
		}
	}, [serviceCedoc]);

	useEffect(() => {
		if (service.status === "loaded") {
			setVolume(service.payload.data);
		}
	}, [service]);

	useEffect(() => {
		if (serviceVolumesRelated.status === "loaded") {
			setRelated(serviceVolumesRelated.payload.data);
		}
	}, [serviceVolumesRelated]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [id]);

	return (
		<Page service={service} item={volume}>
			<>
				{volume && (
					<Metatags
						title={volumeTitle(volume)}
						description={volume.description}
					/>
				)}

				<Header />

				<main className="VolumeDetail">
					<PageContainer>
						<div className="container">
							{volume ? (
								<>
									<div className="row">
										<div className="col-md-12">
											<nav>
												<ol className="breadcrumb">
													<li className="breadcrumb-item">
														<Link to="/cedoc">CEDOC</Link>
													</li>
													<li className="breadcrumb-item">
														<Link to="/cedoc/revistas">Revistas</Link>
													</li>
													<li className="breadcrumb-item">
														<Link to={"/cedoc/revistas/" + volume.magazine.id}>
															{volume.magazine.title}
														</Link>
													</li>

													<li className="breadcrumb-item">
														<Link
															to={
																"/cedoc/revistas/" +
																volume.magazine.id +
																"/volumes"
															}
														>
															Volumes
														</Link>
													</li>

													<li className="breadcrumb-item active">
														{volume.title}
													</li>
												</ol>
											</nav>
										</div>
									</div>

									<div className="row">
										{volume.image && (
											<div className="col-md-6 d-flex justify-content-center align-items-center">
												<img
													className="volume-image"
													src={
														process.env.REACT_APP_STORAGE_URL! + volume.image
													}
													alt={volumeTitle(volume)}
												/>
											</div>
										)}

										<div className={volume.image ? "col-md-6" : "col-md-8"}>
											<p className="magazine">{volume.magazine.title}</p>

											<h1>{volumeTitle(volume)}</h1>

											{volume.description && (
												<p className="lead-1 text-center">
													{volume.description}
												</p>
											)}

											<div className="d-flex">
												<button
													className="btn btn-lg btn-primary"
													onClick={() => setShowAgendarModal(true)}
													style={{ marginRight: "16px" }}
												>
													Agendar consulta
												</button>

												<ButtonShare
													kind="text"
													size="lg"
													skin="outline"
													link={window.location.pathname}
													text="Compartilhar"
												/>
											</div>

											<AgendarCedocModal
												show={showAgendarModal}
												consultationText={cedocParams?.consultation_text}
												item={{
													type: AgendarCedocItemTypeEnum.CedocVolume,
													value: volume,
												}}
												onHide={handleClose}
											/>
										</div>
									</div>
								</>
							) : (
								<>
									<div className="row">
										<div className="col-md-12">
											<Loader className="loader-breadcrumb" />
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<Loader style={{ height: "600px", margin: "0 64px" }} />
										</div>
										<div className="col-md-6">
											<div className="loader-title">
												<Loader className="line-1" />

												<Loader className="line-2" />
											</div>

											<div className="loader-description">
												<Loader className="line-1" />

												<Loader className="line-2" />

												<Loader className="line-1" />

												<Loader className="line-2" />

												<Loader className="line-1" />

												<Loader className="line-2" />

												<Loader className="line-3" />
											</div>
										</div>
									</div>
								</>
							)}

							<hr />

							<div className="row">
								<div className="col-md-12">
									<h2 className="mt-4">Outros volumes</h2>
								</div>
							</div>

							{relateds && relateds.length > 0 ? (
								<div className="row">
									<div className="col-md-12">
										<Slider numberOf={2}>
											{relateds?.map((volume) => (
												<CardVolume
													key={volume.id}
													volume={volume}
													consultationText={cedocParams?.consultation_text}
												/>
											))}
										</Slider>
									</div>
								</div>
							) : (
								<div className="row">
									{loaders.map((key) => (
										<div className="col-md-6" key={key}>
											<Loader className="loader-card-library" />
										</div>
									))}
								</div>
							)}
						</div>
					</PageContainer>
				</main>

				<NewsletterBanner />
				<Footer />
			</>
		</Page>
	);
}
