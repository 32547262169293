import React, { useEffect, useState } from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import PageContainer from "../../components/Layout/PageContainer";
import { Event } from "../../types/Event";
import IconCalendar from "../../components/Icons/IconCalendar";
import IconPlace from "../../components/Icons/IconPlace";
import { dateFormatFull, formatTime } from "../../util/DateTime";
import { useServiceEventsRelated, useServiceEvent } from "./Services";
import Slider from "../../components/Layout/Slider";
import CardEvent from "../../components/Cards/CardEvent";
import NewsletterBanner from "../../components/Layout/NewsletterBanner";
import Loader, { useLoaderCounter } from "../../components/Layout/Loader";
import Metatags from "../../components/Layout/Metatags";
import IconTime from "../../components/Icons/IconTime";
import { ButtonShare } from "../../components/Layout/ButtonShare";
import Page from "../../components/Layout/Page";

export default function EventDetail(props: any) {
	const id = props.match.params.id;

	const [event, setEvent] = useState<Event>();
	const [otherEvents, setOtherEvents] = useState<Event[]>();

	const serviceEvent = useServiceEvent(id);
	const serviceEventsRelated = useServiceEventsRelated(id);
	const loaders = useLoaderCounter(3);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [id]);

	useEffect(() => {
		if (serviceEvent.status === "loaded") {
			setEvent(serviceEvent.payload.data);
		}
	}, [serviceEvent]);

	useEffect(() => {
		if (serviceEventsRelated.status === "loaded") {
			setOtherEvents(serviceEventsRelated.payload.data);
		}
	}, [serviceEventsRelated]);

	return (
		<Page service={serviceEvent} item={event}>
			<>
				<Header />

				<main className="EventDetail">
					<PageContainer>
						{serviceEvent.status === "loaded" && event ? (
							<>
								<Metatags
									title={event.title}
									description={event.description}
									image={
										event.image &&
										process.env.REACT_APP_STORAGE_URL + event.image
									}
								/>

								{event.image && (
									<img
										className="event-image"
										src={process.env.REACT_APP_STORAGE_URL + event.image}
										alt={event.title}
									/>
								)}

								<div className="container">
									<div className="row">
										<div className="col-md-9">
											<h1 className="display-1">{event.title}</h1>

											{event.description && (
												<p className="lead-1">{event.description}</p>
											)}

											{(event.date_start ||
												event.date_end ||
												event.location) && (
												<ul className="event-infos">
													{event.location && (
														<li>
															<IconPlace />
															<span>{event.location}</span>
														</li>
													)}

													{(event.date_start || event.date_end) && (
														<li>
															<IconCalendar />
															<span>
																{dateFormatFull(
																	event.date_start,
																	event.date_end
																)}
															</span>
														</li>
													)}

													{(event.time_start || event.time_end) && (
														<li>
															<IconTime />

															<span>
																{formatTime(event.time_start, event.time_end)}
															</span>
														</li>
													)}
												</ul>
											)}
										</div>
									</div>
									<hr />
									<div className="row">
										<div className="col-md-9">
											<div
												className="headings-formation"
												dangerouslySetInnerHTML={{
													__html: event.body,
												}}
											/>
										</div>
									</div>

									<div className="row mt-3">
										<div className="col-md-9">
											<ButtonShare
												kind="text"
												size="lg"
												link={window.location.pathname}
												text="Compartilhar"
											/>
										</div>
									</div>
								</div>
							</>
						) : (
							<>
								<Loader className="loader-image" />

								<div className="container">
									<div className="row">
										<div className="col-md-9">
											<div className="loader-title">
												<Loader className="line-1" />

												<Loader className="line-2" />
											</div>

											<div className="loader-description">
												<Loader className="line-1" />

												<Loader className="line-2" />

												<Loader className="line-3" />
											</div>
										</div>
									</div>

									<hr />

									<div className="row">
										<div className="col-md-9">
											<div className="loader-paragraph">
												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-2" />
											</div>

											<div className="loader-paragraph">
												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-2" />
											</div>

											<div className="loader-paragraph">
												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-1" />

												<Loader className="line-2" />
											</div>
										</div>
									</div>
								</div>
							</>
						)}

						<div className="container">
							<hr />

							<div className="row">
								<div className="col-md-12">
									<h2 className="mt-4">Outros eventos</h2>
								</div>
							</div>

							{otherEvents && otherEvents.length > 0 ? (
								<div className="row">
									<div className="col-md-12">
										<Slider numberOf={3}>
											{otherEvents?.map((event) => (
												<CardEvent key={event.id} event={event} />
											))}
										</Slider>
									</div>
								</div>
							) : (
								<div className="row">
									{loaders.map((key) => (
										<div className="col-md-4" key={key}>
											<Loader className="loader-card-event" />
										</div>
									))}
								</div>
							)}
						</div>
					</PageContainer>
				</main>

				<NewsletterBanner />
				<Footer />
			</>
		</Page>
	);
}
