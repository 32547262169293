import React from "react";
import { Link } from "react-router-dom";
import { Article } from "../../types/Article";
import NoFolder from "../../assets/no-folder.svg";
import TruncateMarkup from "react-truncate-markup";
import { getFileLink } from "../../util/FileUploaded";
import { FileUploaded } from "../../types/FileUploaded";
import { ButtonShare } from "../Layout/ButtonShare";
import IconAuthors from "../Icons/IconAuthors";
import { volumeTitle } from "./CardVolume";
import IconMagazine from "../Icons/IconMagazine";
import { CedocItemType, Volume } from "../../types/Volume";

interface CardArticleProps {
	large?: boolean;
	article: Article;
	showImage?: boolean;
	type?: CedocItemType;
}

export default function CardArticle(props: CardArticleProps) {
	const { large, article, showImage } = props;

	const url =
		"/cedoc/revistas/" +
		(article.volume?.magazine?.id || "0") +
		"/volumes-eletronicos/" +
		(article.volume?.id || "0") +
		"/" +
		article.id;

	return (
		<div
			className={
				"card card-library card-article" +
				(large ? " card-large" : " card-small")
			}
		>
			{article.image
				? showImage && (
						<img
							className="card-folder"
							src={process.env.REACT_APP_STORAGE_URL + article.image}
							alt={article.title}
						/>
				  )
				: article.volume?.image &&
				  showImage && (
						<img
							className="card-folder"
							src={process.env.REACT_APP_STORAGE_URL + article.volume.image}
							alt={article.title}
						/>
				  )}

			{!article.volume?.image && !article.image && showImage && (
				<img className="card-folder" src={NoFolder} alt="Capa não cadastrada" />
			)}

			<div className="card-content">
				<div className="card-body">
					<div className="card-label">Artigo</div>

					<TruncateMarkup lines={2}>
						<div className="card-title">{article.title}</div>
					</TruncateMarkup>

					{article.resumo && (
						<TruncateMarkup lines={3}>
							<p className="card-description">{article.resumo}</p>
						</TruncateMarkup>
					)}

					{(article.author || article.volume) && (
						<ul className="card-large-list">
							{article.author && (
								<li>
									<IconAuthors />

									{article.author}
								</li>
							)}

							{!article.table_name
								? article.volume && (
										<li>
											<div style={{ marginRight: "3px" }}>
												<IconMagazine />
											</div>

											{volumeTitle(article.volume)}
										</li>
								  )
								: (article.volume_id || article.magazine_id) && (
										<li>
											<div style={{ marginRight: "3px" }}>
												<IconMagazine />
											</div>

											{volumeTitle({
												magazine: {
													id: article.magazine_id,
													title: article.magazine_title,
												},
												title: article.volume_title,
											} as Volume)}
										</li>
								  )}
						</ul>
					)}
				</div>

				<div className="card-actions">
					<Link to={url} className="btn btn-primary-outline">
						Ver mais
					</Link>

					{article.link ? (
						<a
							className="btn btn-secondary-outline"
							href={
								process.env.REACT_APP_STORAGE_URL! +
								getFileLink(article.link as FileUploaded)
							}
							target="_blank"
							rel="noopener noreferrer"
						>
							Ler
						</a>
					) : (
						article.file && (
							<a
								className="btn btn-secondary-outline"
								href={
									process.env.REACT_APP_STORAGE_URL! + getFileLink(article.file)
								}
								target="_blank"
								rel="noopener noreferrer"
							>
								Ler
							</a>
						)
					)}

					<ButtonShare kind="icon" size="md" link={url} />
				</div>
			</div>
		</div>
	);
}
