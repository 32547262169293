import React, { useEffect, useState } from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import PageBanner from "../../components/Layout/PageBanner";
import PageContainer from "../../components/Layout/PageContainer";
import IllustrationNewsletter from "../../components/Illustrations/IllustrationNewsletter";
import Metatags from "../../components/Layout/Metatags";
import { useServiceNewsletterParams } from "./Services";
import { NewsletterParams } from "../../types/NewsletterParams";
import { Metatag } from "../../types/Metatag";
import { useServiceMetatag } from "../Services";

export default function NewsletterPage() {
	const [newsletterParams, setNewsletterParams] = useState<NewsletterParams>();
	const service = useServiceNewsletterParams();

	const [loadingIframe, setLoadingIframe] = useState(true);
	const [iframeClass, setIframeClass] = useState("");

	const [metatag, setMetatag] = useState<Metatag>();
	const serviceMetatag = useServiceMetatag("boletim");

	useEffect(() => {
		if (serviceMetatag.status === "loaded") {
			setMetatag(serviceMetatag.payload.data[0]);
		}
	}, [serviceMetatag]);

	useEffect(() => {
		if (service.status === "loaded") {
			setNewsletterParams(service.payload.data[0]);
		}
	}, [service]);

	const loadedIframe = () => {
		setLoadingIframe(false);
		setIframeClass("loaded");
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			{metatag && (
				<Metatags title={metatag.title} description={metatag.description} />
			)}

			<Header />

			<main className="Newsletter">
				<PageBanner loading={!metatag}>
					<div className="container">
						<div className="row">
							<div className="col-md-7 d-flex flex-column align-items-start">
								<h1 className="display-1">{metatag?.title}</h1>

								<p className="lead-1">{metatag?.description}</p>

								<a
									href={newsletterParams?.newsletter_sign_link}
									target="_blank"
									rel="noopener noreferrer"
									className="btn btn-lg btn-white"
								>
									Inscreva-se!
								</a>
							</div>
						</div>

						<IllustrationNewsletter />
					</div>
				</PageBanner>

				<PageContainer>
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="inner">
									{loadingIframe && (
										<div className="newsletter-loader">
											<h3>Carregando boletim</h3>

											<div className="loader-bar"></div>
										</div>
									)}

									<iframe
										className={iframeClass}
										onLoad={loadedIframe}
										title="Boletim do IEG"
										height="590"
										src={newsletterParams?.newsletter_last_link}
									></iframe>

									<a
										className="btn btn-lg btn-primary"
										href={newsletterParams?.newsletter_all_link}
										target="_blank"
										rel="noopener noreferrer"
									>
										Ver todas as edições
									</a>
								</div>
							</div>
						</div>
					</div>
				</PageContainer>
			</main>
			<Footer />
		</>
	);
}
