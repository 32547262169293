import React, { useState, useEffect } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import { Link } from "react-router-dom";
import FrontsBanner from "../components/Layout/FrontsBanner";
import CardNew from "../components/Cards/CardNew";
import CardEvent from "../components/Cards/CardEvent";
import { Service } from "../types/Service";
import { Front } from "../types/Front";
import { Event } from "../types/Event";
import { New } from "../types/New";
import Slider from "../components/Layout/Slider";
import Carousel from "react-bootstrap/Carousel";
import IconArrowRight from "../components/Icons/IconArrowRight";
import IconArrowLeft from "../components/Icons/IconArrowLeft";
import { useServiceNewsLatest } from "./News/Sevices";
import { useServiceEventsLatest } from "./Events/Services";
import NewsletterBanner from "../components/Layout/NewsletterBanner";
import IllustrationHomeLibrary from "../components/Illustrations/IllustrationHomeLibrary";
import { Banner } from "../types/Banner";
import Loader, { useLoaderCounter } from "../components/Layout/Loader";
import Metatags from "../components/Layout/Metatags";
import { Metatag } from "../types/Metatag";
import { useServiceMetatag } from "./Services";
import { CedocParams } from "../types/CedocParams";
import { useServiceCedocParams } from "./Cedoc/Services";

const apiUrl = process.env.REACT_APP_API_URL;

interface FrontsProps {
	data: Front[];
}

const useServiceFronts = () => {
	const [service, setService] = useState<Service<FrontsProps>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "fronts")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

interface BannersProps {
	data: Banner[];
}

const useServiceBanners = () => {
	const [service, setService] = useState<Service<BannersProps>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "banners")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export default function Home() {
	const [fronts, setFronts] = useState<Front[]>();
	const [events, setEvents] = useState<Event[]>();
	const [news, setNews] = useState<New[]>();
	const [banners, setBanners] = useState<Banner[]>();

	const serviceBanners = useServiceBanners();
	const serviceFronts = useServiceFronts();
	const serviceEventsLatest = useServiceEventsLatest();
	const serviceNewLatest = useServiceNewsLatest();
	const loaders = useLoaderCounter(3);

	const [metatag, setMetatag] = useState<Metatag>();
	const serviceMetatag = useServiceMetatag("home");

	const [cedocParams, setCedocParams] = useState<CedocParams>();
	const serviceCedocParams = useServiceCedocParams();

	useEffect(() => {
		if (serviceMetatag.status === "loaded") {
			setMetatag(serviceMetatag.payload.data[0]);
		}
	}, [serviceMetatag]);

	useEffect(() => {
		if (serviceCedocParams.status === "loaded") {
			setCedocParams(serviceCedocParams.payload.data);
		}
	}, [serviceCedocParams]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (serviceBanners.status === "loaded") {
			setBanners(serviceBanners.payload.data);
		}
	}, [serviceBanners]);

	useEffect(() => {
		if (serviceFronts.status === "loaded") {
			setFronts(serviceFronts.payload.data);
		}
	}, [serviceFronts]);

	useEffect(() => {
		if (serviceNewLatest.status === "loaded") {
			setNews(serviceNewLatest.payload.data);
		}
	}, [serviceNewLatest]);

	useEffect(() => {
		if (serviceEventsLatest.status === "loaded") {
			setEvents(serviceEventsLatest.payload.data);
		}
	}, [serviceEventsLatest]);

	return (
		<>
			{metatag && (
				<Metatags title={metatag.title} description={metatag.description} />
			)}

			<Header />

			<main className="Home">
				{banners ? (
					<Carousel
						interval={2000}
						className="banner"
						indicators={true}
						prevIcon={<IconArrowLeft />}
						nextIcon={<IconArrowRight />}
					>
						{banners?.map((banner) => (
							<Carousel.Item key={banner.id}>
								<img
									className="banner-image"
									src={process.env.REACT_APP_STORAGE_URL + banner.image}
									alt={banner.title}
								/>

								<div className="jumbotron">
									<div className="container">
										<div className="row">
											<div className="col-md-8 d-flex">
												<div className="inner">
													<h1>{banner.title}</h1>

													<p>{banner.description}</p>

													{banner.table_name === "news" && (
														<Link
															to={"noticias/" + banner.id}
															className="btn btn-lg btn-white"
														>
															Ler
														</Link>
													)}

													{banner.table_name === "events" && (
														<Link
															to={"eventos-e-cursos/" + banner.id}
															className="btn btn-lg btn-white"
														>
															Ver mais
														</Link>
													)}

													{/* {!banner.is_from_event && !banner.is_from_new && (
														<Link
															to={banner.link}
															className="btn btn-lg btn-white"
														>
															Ver mais
														</Link>
													)} */}
												</div>
											</div>
										</div>
									</div>
								</div>
							</Carousel.Item>
						))}
					</Carousel>
				) : (
					<Loader className="loader-banner" />
				)}

				{fronts ? (
					<FrontsBanner fronts={fronts} />
				) : (
					<Loader className="loader-fronts" />
				)}

				<section className="last-news">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<h2>Últimas notícias</h2>
							</div>
						</div>

						{news ? (
							<div className="row">
								<div className="col-md-12">
									<Slider numberOf={3}>
										{news?.map((niu) => (
											<CardNew key={niu.id} new={niu} />
										))}
									</Slider>
								</div>
							</div>
						) : (
							<div className="row mb-5">
								{loaders.map((key) => (
									<div className="col-md-4" key={key}>
										<Loader className="loader-card-new" />
									</div>
								))}
							</div>
						)}

						<div className="row">
							<div className="col-md-12 d-flex justify-content-center">
								<Link to="/noticias" className="btn btn-lg btn-primary">
									Ver todas
								</Link>
							</div>
						</div>
					</div>
				</section>

				<section className="last-events">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<h2>Eventos & Cursos</h2>
							</div>
						</div>

						{events ? (
							<div className="row">
								<div className="col-md-12">
									<Slider numberOf={3}>
										{events?.map((event) => (
											<CardEvent key={event.id} event={event} />
										))}
									</Slider>
								</div>
							</div>
						) : (
							<div className="row mb-5">
								{loaders.map((key) => (
									<div className="col-md-4" key={key}>
										<Loader className="loader-card-event" />
									</div>
								))}
							</div>
						)}

						<div className="row">
							<div className="col-md-12 d-flex justify-content-center">
								<Link to="/eventos-e-cursos" className="btn btn-lg btn-primary">
									Ver todos
								</Link>
							</div>
						</div>
					</div>
				</section>

				<section className="library">
					<div className="container">
						<div className="row">
							<div className="col-md-6">
								<div className="inner">
									<h2>CEDOC</h2>

									<p className="lead-1">{cedocParams?.text_home}</p>

									<Link to="cedoc" className="btn btn-lg btn-primary">
										Visitar CEDOC
									</Link>
								</div>
							</div>
						</div>

						<IllustrationHomeLibrary />
					</div>
				</section>
			</main>

			<NewsletterBanner />
			<Footer />
		</>
	);
}
