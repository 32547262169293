import React from "react";
import { Icon } from "../../types/Icon";

export default function IconMenu(props: Icon) {
	return (
		<svg
			className="icon icon-menu"
			width="24"
			height="16"
			viewBox="0 0 24 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 15C0 15.5523 0.447715 16 1 16H23C23.5523 16 24 15.5523 24 15V14.3333C24 13.781 23.5523 13.3333 23 13.3333H1C0.447716 13.3333 0 13.781 0 14.3333V15ZM0 8.33333C0 8.88562 0.447715 9.33333 1 9.33333H23C23.5523 9.33333 24 8.88562 24 8.33333V7.66667C24 7.11438 23.5523 6.66667 23 6.66667H1C0.447716 6.66667 0 7.11438 0 7.66667V8.33333ZM1 0C0.447716 0 0 0.447715 0 1V1.66667C0 2.21895 0.447715 2.66667 1 2.66667H23C23.5523 2.66667 24 2.21895 24 1.66667V1C24 0.447716 23.5523 0 23 0H1Z"
				fill="#66629A"
				className="fill"
			/>
		</svg>
	);
}
