import React, { useEffect } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import PageBanner from "../components/Layout/PageBanner";
import PageContainer from "../components/Layout/PageContainer";
import IllustrationContact from "../components/Illustrations/IllustrationContact";
import NewsletterBanner from "../components/Layout/NewsletterBanner";
import Metatags from "../components/Layout/Metatags";

export default function Contact() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Metatags title="Contato" />

			<Header />

			<main className="Contact">
				<PageBanner loading={false}>
					<div className="container">
						<div className="row">
							<div className="col-md-6">
								<h1 className="display-1">Contato</h1>

								<p className="lead-1">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip..
								</p>
							</div>
						</div>

						<IllustrationContact />
					</div>
				</PageBanner>

				<PageContainer>
					<div className="container">
						<div className="row">
							<div className="col-md-8">newss</div>

							<div className="col-md-4">
								<div className="card card-filter">
									<h5>Pesquisar</h5>

									<input
										type="text"
										placeholder="Busque por nome, temática..."
									></input>

									<button className="btn btn-primary-outline">Pesquisar</button>

									<hr />

									<h5>Tipos de Evento</h5>

									<input
										type="text"
										placeholder="Busque por nome, temática..."
									></input>

									<button className="btn btn-primary-outline">Pesquisar</button>
								</div>
							</div>
						</div>
					</div>
				</PageContainer>
			</main>

			<NewsletterBanner />
			<Footer />
		</>
	);
}
