import { useEffect, useState } from "react";
import { Metatag } from "../types/Metatag";
import { Service } from "../types/Service";

const apiUrl = process.env.REACT_APP_API_URL;

export interface MetatagModel {
	data: Metatag[];
}

export const useServiceMetatag = (id: string) => {
	const [service, setService] = useState<Service<MetatagModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "metatags/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};
