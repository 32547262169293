import React from "react";

export default function IllustrationWhatWeDo() {
	return (
		<svg
			className="illustration illustration-what-we-do"
			width="309"
			height="509"
			viewBox="0 0 309 509"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M84.6846 376.081C86.2087 357.626 91.6836 318.532 101.292 302.7C105.913 295.101 111.461 287.221 119.875 284.403C123.369 283.369 126.997 282.859 130.641 282.891C160.601 282 190.218 288.648 219.47 295.235C224.506 296.381 229.676 297.577 233.931 300.492C241.369 305.603 244.552 314.897 246.539 323.668C249.734 337.829 251.185 399.915 250.527 414.43"
				fill="#C8B55D"
			/>
			<path
				d="M171.794 285.672C170.94 302.212 165.844 304.127 157.833 318.606C149.822 333.084 139.652 346.282 129.52 359.395C122.594 368.36 115.473 377.484 106.011 383.692C95.4635 390.621 82.8433 393.45 70.4305 395.768C63.9924 396.988 57.0787 398.073 51.0918 395.402C64.0899 388.986 72.8936 375.886 76.6979 361.858C80.5022 347.831 79.8438 333.035 77.7953 318.679C76.1004 306.81 73.5154 294.442 77.3076 283.062C82.3312 268.059 97.8778 258.374 113.571 256.446C129.264 254.519 145.066 259.093 159.686 265.156C164.015 266.949 168.429 268.912 173.099 268.986C176.627 268.851 180.121 268.243 183.487 267.18C199.583 263.131 209.52 257.813 225.75 261.313C236.724 263.68 240.918 269.303 242.04 271.462C258.282 302.346 274.548 335.304 272.743 370.153C272.414 376.679 271.353 383.387 267.866 388.913C264.378 394.438 257.965 398.512 251.49 397.61C250.332 397.479 249.225 397.059 248.271 396.39C247.429 395.685 246.726 394.828 246.198 393.865C234.334 374.715 233.066 349.014 216.422 333.828C208.057 326.205 196.559 322.131 189.596 313.165C182.427 303.871 180.195 293.759 171.745 285.599"
				fill="#D6C785"
			/>
			<path
				d="M179 388.022C180.525 388.022 182.047 388.156 183.548 388.425C185.192 388.818 186.796 389.361 188.34 390.047C189.314 390.37 190.205 390.903 190.95 391.608C191.564 392.416 191.981 393.356 192.169 394.353C192.645 396.195 193.035 398.012 193.388 399.939C193.644 401.172 193.681 402.439 193.498 403.684C193.023 406.233 190.962 408.185 188.804 409.637C187 410.846 185.06 411.836 183.024 412.589C180.399 413.484 177.708 414.173 174.976 414.65C171.073 415.549 167.107 416.153 163.112 416.455C159.905 416.614 156.321 416.223 154.187 413.82C152.786 412.001 152.097 409.733 152.248 407.441C152.151 405.158 152.859 402.913 154.248 401.098C156.162 398.89 159.381 398.134 161.234 395.865C161.474 395.612 161.646 395.304 161.736 394.967C161.825 394.63 161.829 394.277 161.747 393.938C161.637 393.499 161.332 393.011 161.454 392.548C161.759 391.413 164.002 390.486 164.978 390.035C167.782 388.825 170.79 388.154 173.842 388.059C175.537 387.961 177.269 387.974 179 388.022Z"
				fill="#E3DAAE"
			/>
			<path
				d="M186.293 402.391C186.134 399.793 186.207 387.632 187.183 386.156C189.18 383.512 191.699 381.308 194.584 379.679C199.55 376.48 204.71 373.592 210.033 371.031C218.614 367.133 227.47 363.871 236.529 361.273C244.878 358.731 253.351 356.618 261.916 354.942C264.452 354.43 266.964 353.723 269.537 353.43C272.436 353.127 275.354 353.037 278.267 353.162C288.241 353.332 298.264 353.515 308.117 355.174C302.934 360.602 298.155 366.262 293.485 372.129C285.461 382.168 279.023 393.353 272.622 404.489C271.402 406.587 270.915 408.661 269.768 410.795C268.622 412.93 265.793 418.761 264.781 420.956C263.769 423.152 263.367 425.701 262.343 428.067C260.377 432.152 258.204 436.134 255.831 439.997C252.667 445.618 250.045 451.528 248.003 457.647C241.273 459.464 234.456 461.05 227.604 462.306C225.748 462.601 223.911 463.008 222.105 463.526C220.134 464.201 218.215 465.016 216.361 465.965C208.168 469.844 200.51 475.968 193.438 481.603C192.418 482.324 191.535 483.22 190.829 484.25C190.316 485.091 189.999 486.128 189.158 486.689C188.263 487.103 187.248 487.172 186.305 486.884C185.102 485.572 184.196 484.016 183.647 482.323C183.258 480.43 182.986 478.515 182.83 476.59L181.61 464.16"
				fill="#E3DAAE"
			/>
			<path
				d="M180.415 413.857C180.361 411.251 180.581 408.647 181.074 406.087C181.112 404.919 181.581 403.807 182.391 402.965C183.366 402.16 185.024 402.294 186.293 402.391C188.043 402.668 189.832 402.58 191.548 402.135C192.623 401.717 193.643 401.168 194.584 400.501C199.55 397.301 204.71 394.413 210.033 391.853C218.614 387.954 227.47 384.692 236.529 382.094C244.878 379.552 253.351 377.439 261.916 375.764C264.452 375.251 266.964 374.544 269.537 374.251C272.436 373.942 275.354 373.852 278.267 373.983C288.241 374.154 298.264 374.337 308.117 375.996C302.934 381.423 298.155 387.083 293.484 392.95C285.461 402.989 279.023 414.174 272.622 425.311C271.402 427.409 270.915 429.482 269.768 431.617C268.622 433.751 265.793 439.582 264.781 441.777C263.769 443.973 263.367 446.522 262.343 448.889C260.379 452.971 258.206 456.949 255.831 460.806C252.669 466.432 250.048 472.346 248.003 478.468C241.272 480.285 234.456 481.871 227.604 483.127C225.748 483.422 223.911 483.83 222.105 484.347C220.134 485.022 218.215 485.837 216.361 486.787C208.167 490.666 200.51 496.789 193.438 502.424C192.418 503.145 191.535 504.041 190.828 505.071C190.316 505.913 189.999 506.949 189.158 507.511C188.263 507.924 187.247 507.994 186.305 507.706C185.1 506.39 184.193 504.83 183.647 503.132C183.258 501.243 182.985 499.333 182.83 497.411L181.61 484.982"
				fill="#F1ECD6"
			/>
			<path
				d="M162.491 494.276C160.272 493.923 159.869 490.946 158.54 489.141C157.428 487.886 156.007 486.944 154.419 486.409L143.067 481.432C137.743 478.942 132.251 476.83 126.63 475.114C123.765 474.309 120.863 473.711 117.948 473.113L101.561 469.759C92.2204 467.832 82.8194 465.904 73.2963 465.392C71.1978 458.927 68.7057 452.597 65.834 446.437C64.956 444.644 64.0172 442.875 63.3221 441.009C62.5166 438.552 61.9051 436.036 61.4931 433.483C59.3899 422.781 56.3293 412.289 52.3481 402.135C50.0435 396.293 47.4707 390.511 46.0807 384.375C45.5463 382.332 44.6457 380.402 43.4226 378.679C43.1999 378.343 43.0605 377.959 43.0157 377.559C42.9709 377.158 43.0222 376.753 43.1652 376.376C43.3082 376 43.5389 375.662 43.8381 375.393C44.1373 375.123 44.4964 374.928 44.8858 374.825C71.1869 367.799 102.317 364.14 128.349 371.8C132.611 372.991 136.767 374.533 140.774 376.41C145.859 378.85 151.59 380.716 155.955 384.302C158.869 386.742 163.003 388.462 164.844 391.743C167.551 396.549 163.857 408.734 164.052 414.248C165.142 440.931 164.621 467.656 162.491 494.276Z"
				fill="#E3DAAE"
			/>
			<path
				d="M164.173 507.303C161.966 506.962 161.552 503.986 160.223 502.18C159.114 500.925 157.698 499.979 156.114 499.436L144.762 494.459C139.432 491.978 133.935 489.871 128.313 488.153C125.46 487.348 122.545 486.75 119.643 486.153L103.255 482.786C93.9031 480.871 84.5142 478.944 74.9789 478.432C72.8899 471.965 70.4017 465.634 67.5288 459.476C66.6004 457.706 65.7621 455.891 65.017 454.036C64.2114 451.579 63.5999 449.063 63.1879 446.51C61.0879 435.81 58.023 425.322 54.0307 415.175C51.7384 409.32 49.1534 403.55 47.7755 397.415C47.2359 395.373 46.3357 393.444 45.1174 391.718C44.8947 391.383 44.7553 390.999 44.7105 390.598C44.6658 390.198 44.717 389.792 44.86 389.416C45.003 389.039 45.2337 388.702 45.5329 388.432C45.8321 388.162 46.1912 387.967 46.5806 387.864C73.4436 380.886 101.69 381.277 128.349 388.998C132.613 390.183 136.77 391.725 140.774 393.609C145.835 396.144 150.579 399.268 154.907 402.916C157.929 405.184 160.502 407.997 162.491 411.21C165.198 416.016 165.539 421.749 165.734 427.262C166.831 453.95 166.31 480.679 164.173 507.303Z"
				fill="#F1ECD6"
			/>
			<path
				d="M187.719 505.937C187.719 505.095 187.719 503.119 187.719 502.058C187.89 491.91 188.012 485.299 187.439 476.675C187.28 474.235 187.987 470.674 187.744 468.234C187.536 466.1 187.463 463.941 187.39 461.794C186.683 441.936 186.097 422.115 186.097 402.233C178.72 401.769 171.075 401.342 164.149 403.904C162.299 404.405 160.71 405.597 159.711 407.234L162.967 491.056V490.922L163.503 504.595L163.649 506.315C163.648 506.472 163.686 506.627 163.759 506.767C163.852 506.875 163.964 506.965 164.088 507.035C165.527 508.058 167.209 508.684 168.966 508.852C170.569 508.65 172.144 508.265 173.66 507.706C175.046 507.439 176.458 507.337 177.867 507.401C181.257 507.401 184.61 507.401 188 507.401C187.823 506.933 187.728 506.437 187.719 505.937Z"
				fill="#C8B55D"
			/>
			<path
				d="M113.376 466.795C105.121 467.868 96.7686 471.32 88.7453 473.479C87.209 473.894 80.5636 474.138 79.2101 474.699C84.0874 471.454 95.0615 463.941 100.536 461.599C103.499 460.379 106.633 458.83 108.279 456.085C110.218 452.938 109.852 452.426 109.498 448.767C109.481 448.438 109.347 448.127 109.12 447.889C108.511 447.242 107.73 447.657 107.121 448.047L85.5019 461.916C81.9536 464.197 77.8322 465.941 73.6377 465.575C79.9395 459.684 86.8235 454.449 94.1836 449.95C97.9792 447.484 101.158 444.179 103.475 440.289C104.694 438.374 106.096 437.228 105.828 435.008C105.732 434.303 105.449 433.637 105.009 433.077C104.569 432.518 103.988 432.087 103.326 431.827C102.664 431.567 101.944 431.489 101.242 431.6C100.539 431.711 99.879 432.007 99.3292 432.459C89.744 440.404 79.4175 447.409 68.4921 453.378C72.1746 448.059 76.6722 443.353 81.8195 439.436C85.1726 436.898 88.7819 434.691 91.9156 431.897C95.0493 429.104 99.2316 421.688 97.9391 420.236C97.2717 419.564 96.4407 419.076 95.5279 418.821C94.615 418.567 93.6517 418.554 92.7326 418.785C90.9011 419.245 89.1832 420.075 87.6845 421.225C74.7595 430.165 64.956 438.984 56.7865 452.414C53.0675 457.476 45.093 462.575 44.715 468.844C44.3371 475.114 47.6536 481.164 51.1166 486.519C52.0302 488.216 53.371 489.646 55.0063 490.666C56.7322 491.399 58.6318 491.624 60.4811 491.312C74.4425 489.873 86.1847 479.846 99.9754 477.261C103.219 476.651 106.633 476.431 109.535 474.821C112.437 473.211 114.644 469.783 113.376 466.795Z"
				fill="#C8B55D"
			/>
			<path
				d="M214.373 466.795C222.616 467.868 230.968 471.32 239.004 473.479C240.54 473.894 247.186 474.138 248.527 474.699C243.65 471.454 232.676 463.941 227.201 461.599C224.25 460.379 221.104 458.83 219.47 456.085C217.531 452.938 217.897 452.426 218.251 448.767C218.279 448.441 218.411 448.133 218.629 447.889C219.238 447.242 220.019 447.657 220.641 448.047L242.247 461.916C245.783 464.197 249.905 465.941 254.111 465.575C247.805 459.69 240.922 454.455 233.566 449.95C229.764 447.488 226.58 444.182 224.262 440.289C223.043 438.374 221.641 437.228 221.909 435.008C222.008 434.303 222.292 433.637 222.734 433.078C223.175 432.52 223.757 432.088 224.419 431.829C225.082 431.569 225.802 431.491 226.505 431.602C227.208 431.712 227.869 432.008 228.42 432.459C238.001 440.404 248.323 447.409 259.245 453.378C255.566 448.056 251.068 443.35 245.918 439.436C242.577 436.898 238.967 434.691 235.834 431.897C232.7 429.104 228.518 421.688 229.798 420.236C230.466 419.565 231.297 419.078 232.21 418.823C233.122 418.569 234.085 418.556 235.004 418.785C236.836 419.245 238.554 420.075 240.053 421.225C252.978 430.165 262.781 438.984 270.951 452.414C274.682 457.476 282.656 462.575 283.022 468.844C283.388 475.114 280.096 481.164 276.633 486.519C275.715 488.214 274.375 489.642 272.743 490.666C271.013 491.4 269.109 491.625 267.256 491.312C253.307 489.873 241.564 479.846 227.774 477.261C224.518 476.651 221.104 476.431 218.202 474.821C215.3 473.211 213.105 469.783 214.373 466.795Z"
				fill="#C8B55D"
			/>
			<path
				d="M56.5203 225.924C56.759 225.686 60.2191 223.896 60.5771 223.657C62.9634 220.794 62.4861 218.527 65.3497 214.351C81.4573 191.561 104.485 152.903 122.263 134.29C141.831 166.267 144.575 180.465 175.598 216.856C175.359 216.26 179.654 215.305 179.535 214.709C169.632 182.732 162.592 174.619 160.683 141.449C160.564 138.347 158.058 135.483 159.013 132.5C160.206 129.04 165.217 127.727 168.677 125.938C188.842 115.677 211.75 106.37 234.54 99.211C233.943 99.211 238.358 95.5122 237.761 95.6315C208.887 98.4951 172.376 100.881 144.456 96.944C143.382 67.115 138.848 0.775391 138.848 0.775391C138.848 0.775391 134.911 3.16171 134.911 2.92308C129.064 33.7066 114.031 66.6378 105.917 97.0633C68.094 94.9156 42.6797 81.5522 4.85654 72.6036C4.73723 72.6036 0.919121 74.7512 0.799805 74.7512C22.7539 82.984 37.5491 95.0349 52.3443 103.387C62.0089 108.756 90.0481 126.296 90.0481 126.296C90.0481 126.296 80.9801 157.676 77.0427 169.13C71.1962 186.55 64.7531 209.34 56.5203 225.924Z"
				fill="#C8B55D"
			/>
			<path
				d="M60.4578 223.777C75.9689 205.879 104.724 154.573 126.201 132.261C145.769 164.238 148.513 178.437 179.535 214.828C169.155 181.3 161.996 133.335 163.07 130.472C164.263 127.012 168.319 125.341 171.899 123.551C192.063 113.29 215.211 103.029 238 95.8701C209.483 97.8984 177.149 98.9723 148.632 95.0349C147.32 63.4161 146.007 31.9167 138.848 1.01392C133.002 31.7974 117.968 64.7286 109.855 95.1542C72.0315 92.7678 42.3218 77.9727 4.7373 72.7228C26.6914 80.9556 41.4866 93.0065 56.2818 101.359C65.9464 106.728 93.9856 124.267 93.9856 124.267C93.9856 124.267 84.9176 155.647 80.9802 167.102C75.1337 184.402 68.6906 207.072 60.4578 223.777Z"
				fill="#F1ECD6"
			/>
		</svg>
	);
}
