import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Audiovisual } from "../../types/Audiovisual";
import TruncateMarkup from "react-truncate-markup";
import { CedocItemType } from "../../types/Volume";
import NoFolder from "../../assets/no-folder.svg";
import { CedocAudiovisual } from "../../types/CedocAudiovisual";
import {
	AgendarCedocModal,
	AgendarCedocItemTypeEnum,
} from "../Modals/AgendarCedocModal";
import { CardCedocProps } from "./types";
import getYouTubeID from "get-youtube-id";
import { ButtonShare } from "../Layout/ButtonShare";
import { getFiles, getFirstFileLink } from "../../util/FileUploaded";

interface CardAudiovisualProps extends CardCedocProps {
	large?: boolean;
	audiovisual: Audiovisual | CedocAudiovisual;
	type?: CedocItemType;
}

export default function CardAudiovisual(props: CardAudiovisualProps) {
	const { large, audiovisual, consultationText, type } = props;
	const [thumb, setThumb] = useState<string>();
	const [showAgendarModal, setShowAgendarModal] = useState<boolean>(false);

	const isYoutube =
		(audiovisual as Audiovisual).link &&
		(audiovisual as Audiovisual).link?.indexOf("youtu");

	const handleClose = () => setShowAgendarModal(false);

	useEffect(() => {
		if (isYoutube) {
			const videoId = getYouTubeID((audiovisual as Audiovisual).link);

			videoId &&
				setThumb("https://img.youtube.com/vi/" + videoId + "/default.jpg");
		}
	}, [audiovisual, isYoutube]);

	return (
		<>
			<div
				className={
					"card card-library card-video" + (large ? " card-large" : "")
				}
			>
				{isYoutube ? (
					<div className="card-folder">
						<img src={thumb} alt={audiovisual.title} />

						{/* <IconPlay /> */}
					</div>
				) : audiovisual.folder || audiovisual.image ? (
					audiovisual.table_name ? (
						<img
							className="card-folder"
							src={process.env.REACT_APP_STORAGE_URL! + audiovisual.image}
							alt={audiovisual.title}
						/>
					) : (
						<img
							className="card-folder"
							src={process.env.REACT_APP_STORAGE_URL! + audiovisual.folder}
							alt={audiovisual.title}
						/>
					)
				) : (
					<div className="card-folder">
						<img src={NoFolder} alt={audiovisual.title} />
					</div>
				)}

				<div className="card-content">
					<div className="card-body">
						{audiovisual.cedoc_audiovisual_type_name ? (
							<div className="card-label">
								{audiovisual.cedoc_audiovisual_type_name}
							</div>
						) : (
							<div className="card-label">
								{audiovisual.cedoc_audiovisual_type?.title}
							</div>
						)}

						<TruncateMarkup lines={2}>
							<div className="card-title">{audiovisual.title}</div>
						</TruncateMarkup>

						<TruncateMarkup lines={3}>
							<p className="card-description">{audiovisual.description}</p>
						</TruncateMarkup>
					</div>

					<div className="card-actions">
						{(audiovisual.type === CedocItemType.FISICO ||
							type === CedocItemType.FISICO) && (
							<>
								<Link
									to={"/cedoc/midias-fisicas/" + audiovisual.id}
									className="btn btn-primary-outline"
								>
									Ver mais
								</Link>

								<button
									className="btn btn-secondary-outline"
									onClick={() => setShowAgendarModal(true)}
								>
									Agendar consulta
								</button>

								<ButtonShare
									kind="icon"
									size="md"
									link={"/cedoc/midias-fisicas/" + audiovisual.id}
								/>
							</>
						)}

						{(audiovisual.type === CedocItemType.ONLINE ||
							type === CedocItemType.ONLINE) && (
							<>
								<Link
									to={"/cedoc/midias-eletronicas/" + audiovisual.id}
									className="btn btn-primary-outline"
								>
									Ver mais
								</Link>

								{(audiovisual as Audiovisual).link && (
									<a
										href={(audiovisual as Audiovisual).link}
										className="btn btn-secondary-outline"
										target="_blank"
										rel="noopener noreferrer"
									>
										Acessar
									</a>
								)}

								{getFiles(
									(audiovisual as Audiovisual).file as unknown as string
								)?.length > 0 && (
									<a
										href={
											process.env.REACT_APP_STORAGE_URL! +
											getFirstFileLink((audiovisual as Audiovisual).file)
										}
										target="_blank"
										rel="noopener noreferrer"
										className="btn btn-secondary-outline"
									>
										Ler
									</a>
								)}

								<ButtonShare
									kind="icon"
									size="md"
									link={"/cedoc/midias-eletronicas/" + audiovisual.id}
								/>
							</>
						)}
					</div>
				</div>
			</div>

			{audiovisual.type === CedocItemType.FISICO && (
				<AgendarCedocModal
					show={showAgendarModal}
					consultationText={consultationText}
					item={{
						type: AgendarCedocItemTypeEnum.CedocAudiovisual,
						value: audiovisual as CedocAudiovisual,
					}}
					onHide={handleClose}
				/>
			)}
		</>
	);
}
