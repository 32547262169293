import React, { useState, useEffect } from "react";
import IllustrationNewsletterBanner from "../Illustrations/IllustrationNewsletterBanner";
import { NewsletterParams } from "../../types/NewsletterParams";
import { useServiceNewsletterParams } from "../../pages/Newsletter/Services";

export default function NewsletterBanner() {
	const [newsletterParams, setNewsletterParams] = useState<NewsletterParams>();
	const service = useServiceNewsletterParams();

	useEffect(() => {
		if (service.status === "loaded") {
			setNewsletterParams(service.payload.data[0]);
		}
	}, [service]);

	return (
		<div className="newsletter-banner">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="inner">
							<div className="row">
								<div className="col-md-7 d-flex flex-column align-items-start">
									<h3>
										Inscreva-se para
										<br /> receber nosso boletim
									</h3>

									<a
										href={newsletterParams?.newsletter_sign_link}
										target="_blank"
										rel="noopener noreferrer"
										className="btn btn-lg btn-white"
									>
										Inscrever-se
									</a>
								</div>
							</div>

							<IllustrationNewsletterBanner />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
