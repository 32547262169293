import React, { useState, useEffect } from "react";
import Header from "../../../components/Layout/Header";
import Footer from "../../../components/Layout/Footer";
import PageContainer from "../../../components/Layout/PageContainer";
import {
	useServiceAudiovisualFisicoDetail,
	useServiceAudiovisualFisicoRelated,
	useServiceCedocParams,
} from "../Services";
import Slider from "../../../components/Layout/Slider";
import { Link } from "react-router-dom";
import NewsletterBanner from "../../../components/Layout/NewsletterBanner";
import Loader, { useLoaderCounter } from "../../../components/Layout/Loader";
import Metatags from "../../../components/Layout/Metatags";
import CardAudiovisual from "../../../components/Cards/CardAudiovisual";
import { CedocAudiovisual } from "../../../types/CedocAudiovisual";
import { CedocParams } from "../../../types/CedocParams";
import {
	AgendarCedocModal,
	AgendarCedocItemTypeEnum,
} from "../../../components/Modals/AgendarCedocModal";
import { CedocItemType } from "../../../types/Volume";
import { ButtonShare } from "../../../components/Layout/ButtonShare";
import Page from "../../../components/Layout/Page";

export default function AudiovisualFisicoDetail(props: any) {
	const id = props.match.params.id;
	const [audiovisual, setAudiovisual] = useState<CedocAudiovisual | null>();
	const [relateds, setRelateds] = useState<CedocAudiovisual[] | null>();
	const loaders = useLoaderCounter(2);

	const service = useServiceAudiovisualFisicoDetail(id);
	const serviceRelated = useServiceAudiovisualFisicoRelated(id);

	const [cedocParams, setCedocParams] = useState<CedocParams>();
	const serviceCedoc = useServiceCedocParams();

	const [showAgendarModal, setShowAgendarModal] = useState<boolean>(false);
	const handleClose = () => setShowAgendarModal(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [id]);

	useEffect(() => {
		if (service.status === "loaded") {
			setAudiovisual(service.payload.data);
		}
	}, [service]);

	useEffect(() => {
		if (serviceRelated.status === "loaded") {
			setRelateds(serviceRelated.payload.data);
		}
	}, [serviceRelated]);

	useEffect(() => {
		if (serviceCedoc.status === "loaded") {
			setCedocParams(serviceCedoc.payload.data);
		}
	}, [serviceCedoc]);

	return (
		<Page service={service} item={audiovisual}>
			<>
				{audiovisual && (
					<Metatags
						title={audiovisual.title}
						description={audiovisual.description}
					/>
				)}

				<Header />

				<main className="VideoDetail">
					<PageContainer>
						{audiovisual ? (
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<nav>
											<ol className="breadcrumb">
												<li className="breadcrumb-item">
													<Link to="/cedoc">CEDOC</Link>
												</li>
												<li className="breadcrumb-item">
													<Link to="/cedoc/midias">Mídias</Link>
												</li>
												<li className="breadcrumb-item active">
													{audiovisual.title}
												</li>
											</ol>
										</nav>
									</div>
								</div>

								<div className="row">
									<div className="col-md-8 d-flex flex-column align-items-start">
										<h1 className="display-1">{audiovisual.title}</h1>

										<p className="lead-2">{audiovisual.description}</p>

										{(audiovisual.institution ||
											audiovisual.font ||
											audiovisual.date_release ||
											audiovisual.publisher) && (
											<ul className="list-detail">
												{audiovisual.institution && (
													<li>
														<div className="label">Instituição</div>

														<div className="value">
															{audiovisual.institution.title}
														</div>
													</li>
												)}

												{audiovisual.publisher?.title && (
													<li>
														<div className="label">Editora</div>

														<div className="value">
															{audiovisual.publisher.title}
														</div>
													</li>
												)}

												{audiovisual.font && (
													<li>
														<div className="label">Fonte</div>

														<div className="value">{audiovisual.font}</div>
													</li>
												)}

												{audiovisual.date_release && (
													<li>
														<div className="label">Data de publicação</div>

														<div className="value">
															{audiovisual.date_release}
														</div>
													</li>
												)}
											</ul>
										)}
										<div className="buttons-spacing-actions">
											<button
												className="btn btn-lg btn-primary"
												onClick={() => setShowAgendarModal(true)}
											>
												Agendar consulta
											</button>

											<ButtonShare
												kind="text"
												size="lg"
												skin="outline"
												link={window.location.pathname}
												text="Compartilhar"
											/>
										</div>

										<AgendarCedocModal
											show={showAgendarModal}
											consultationText={cedocParams?.consultation_text}
											item={{
												type: AgendarCedocItemTypeEnum.CedocAudiovisual,
												value: audiovisual,
											}}
											onHide={handleClose}
										/>
									</div>
								</div>
							</div>
						) : (
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<Loader className="loader-breadcrumb" />
									</div>
								</div>
								<div className="row">
									<div className="col-md-8">
										<div className="loader-title">
											<Loader className="line-1" />

											<Loader className="line-2" />
										</div>

										<div className="loader-description">
											<Loader className="line-1" />

											<Loader className="line-2" />

											<Loader className="line-3" />
										</div>
									</div>
								</div>
							</div>
						)}

						<div className="container">
							<hr />

							<div className="row">
								<div className="col-md-12">
									<h2 className="mt-4">Outras mídias</h2>
								</div>
							</div>

							{relateds && relateds.length > 0 ? (
								<div className="row">
									<div className="col-md-12">
										<Slider numberOf={2}>
											{relateds?.map((related) => (
												<CardAudiovisual
													large
													key={related.id}
													audiovisual={related}
													consultationText={cedocParams?.consultation_text}
													type={CedocItemType.FISICO}
												/>
											))}
										</Slider>
									</div>
								</div>
							) : (
								<div className="row">
									{loaders.map((key) => (
										<div className="col-md-6" key={key}>
											<Loader className="loader-card-library" />
										</div>
									))}
								</div>
							)}
						</div>
					</PageContainer>
				</main>

				<NewsletterBanner />
				<Footer />
			</>
		</Page>
	);
}
