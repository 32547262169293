import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Volume, CedocItemType } from "../../types/Volume";
import TruncateMarkup from "react-truncate-markup";
import NoFolder from "../../assets/no-folder.svg";
import { CedocVolume } from "../../types/CedocVolume";
import {
	AgendarCedocItemTypeEnum,
	AgendarCedocModal,
} from "../Modals/AgendarCedocModal";
import { CardCedocProps } from "./types";
import { ButtonShare } from "../Layout/ButtonShare";
import IconMagazine from "../Icons/IconMagazine";

export interface CardVolumeProps extends CardCedocProps {
	large?: boolean;
	volume: Volume | CedocVolume;
}

export default function CardVolume(props: CardVolumeProps) {
	const { volume, large, consultationText } = props;
	const [showAgendarModal, setShowAgendarModal] = useState<boolean>(false);
	const handleClose = () => setShowAgendarModal(false);

	return (
		<>
			<div
				className={
					"card card-library card-volume" +
					(large ? " card-large" : " card-small")
				}
			>
				{volume.image ? (
					<img
						className="card-folder"
						src={process.env.REACT_APP_STORAGE_URL + volume.image}
						alt={volumeTitle(volume)}
					/>
				) : (
					<img
						className="card-folder"
						src={NoFolder}
						alt="Capa não cadastrada"
					/>
				)}

				<div className="card-content">
					<div className="card-body">
						{volume.type === CedocItemType.ONLINE ? (
							<div className="card-label">Volume eletrônico</div>
						) : (
							<div className="card-label">Volume físico</div>
						)}

						<TruncateMarkup lines={2}>
							<div className="card-title">{volumeTitle(volume)}</div>
						</TruncateMarkup>

						{volume.description && (
							<TruncateMarkup lines={3}>
								<p className="card-description">{volume.description}</p>
							</TruncateMarkup>
						)}

						{(volume.magazine?.title || volume.magazine_title) && (
							<ul className="card-large-list">
								<li>
									<IconMagazine />

									{volume.magazine_title || volume.magazine.title}
								</li>
							</ul>
						)}
					</div>

					<div className="card-actions">
						{volume.type === CedocItemType.FISICO && (
							<>
								<Link
									to={
										"/cedoc/revistas/" +
										volume.magazine_id +
										"/volumes-fisicos/" +
										volume.id
									}
									className="btn btn-primary-outline"
								>
									Ver mais
								</Link>

								<button
									className="btn btn-secondary-outline"
									onClick={() => setShowAgendarModal(true)}
								>
									Agendar consulta
								</button>

								<ButtonShare
									kind="icon"
									size="md"
									link={
										"/cedoc/revistas/" +
										volume.magazine_id +
										"/volumes-fisicos/" +
										volume.id
									}
								/>
							</>
						)}

						{volume.type === CedocItemType.ONLINE && (
							<>
								<Link
									to={
										"/cedoc/revistas/" +
										volume.magazine_id +
										"/volumes-eletronicos/" +
										volume.id
									}
									className="btn btn-primary-outline"
								>
									Ver mais
								</Link>

								{(volume as Volume).link && (
									<a
										href={(volume as Volume).link}
										className="btn btn-secondary-outline"
										target="_blank"
										rel="noopener noreferrer"
									>
										Ler
									</a>
								)}

								<ButtonShare
									kind="icon"
									size="md"
									link={
										"/cedoc/revistas/" +
										volume.magazine_id +
										"/volumes-eletronicos/" +
										volume.id
									}
								/>
							</>
						)}
					</div>
				</div>
			</div>

			{volume.type === CedocItemType.FISICO && (
				<AgendarCedocModal
					show={showAgendarModal}
					consultationText={consultationText}
					item={{
						type: AgendarCedocItemTypeEnum.CedocVolume,
						value: volume as CedocVolume,
					}}
					onHide={handleClose}
				/>
			)}
		</>
	);
}

export function volumeTitle(volume: Volume | CedocVolume) {
	return volume.magazine?.title
		? volume.magazine?.title + (volume.title ? ", " + volume.title : "")
		: volume.magazine_title
		? volume.magazine_title + (volume.title ? ", " + volume.title : "")
		: volume.title;
}
