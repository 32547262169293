import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CedocAudiovisual } from "../../types/CedocAudiovisual";
import { CedocBook } from "../../types/CedocBook";
import { CedocThesi } from "../../types/CedocThesi";
import { CedocVolume } from "../../types/CedocVolume";
import { useServiceCedocEmail } from "../../pages/Cedoc/Services";
import IconClose from "../Icons/IconClose";
import { useServiceContact } from "../Layout/Services";
import { Contact } from "../../types/Contact";

export enum AgendarCedocItemTypeEnum {
	CedocBook,
	CedocAudiovisual,
	CedocVolume,
	CedocThesi,
}

export interface AgendarCedocIdemModel {
	type: AgendarCedocItemTypeEnum;
	value: CedocBook | CedocVolume | CedocThesi | CedocAudiovisual;
}

export interface AgendarCedocCallbackModalProps {
	show?: boolean;
	email: string;
	onHide(): void;
}

export interface CedocEmailModel {
	email: string;
}

export const AgendarCedocCallbackModal = (
	props: AgendarCedocCallbackModalProps
) => {
	const { show, email, onHide } = props;
	const [status, setStatus] = useState<string>();
	const [contact, setContact] = useState<Contact>();

	const service = useServiceCedocEmail(email);
	const contactService = useServiceContact();

	useEffect(() => {
		if (contactService.status === "loaded") {
			setContact(contactService.payload.data[0]);
		}
	}, [contactService]);

	useEffect(() => {
		if (service.status === "loaded") {
			setStatus(service.payload.data.status);
		}
	}, [service]);

	return (
		<Modal className="modal-cedoc-agendar-callback" show={show}>
			<Modal.Header>
				<Modal.Title>
					{service.status === "loading" && "Enviando solicitação..."}

					{service.status === "loaded" &&
						status === "ok" &&
						"Solicitação enviada!"}

					{((service.status === "loaded" && status === "erro") ||
						service.status === "error") &&
						"Erro ao enviar solicitação"}
				</Modal.Title>

				<button className="btn btn-ghost btn-close" onClick={onHide}>
					<IconClose />
				</button>
			</Modal.Header>
			<Modal.Body>
				{service.status === "loading" && (
					<svg
						className="loading"
						width="120"
						height="120"
						viewBox="0 0 120 120"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M6.00293 60.0002C6.26987 30.2974 30.2979 6.26938 60.0007 6.00244V10.0026C32.5071 10.2693 10.2698 32.5066 10.0031 60.0002H6.00293Z"
							fill="#2D9CDB"
						/>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60ZM60 118C27.9675 118 2 92.0325 2 60C2 27.9675 27.9675 2 60 2C92.0325 2 118 27.9675 118 60C118 92.0325 92.0325 118 60 118Z"
							fill="#2D9CDB"
						/>
					</svg>
				)}

				{service.status === "loaded" && status === "ok" && (
					<>
						<svg
							width="120"
							height="120"
							viewBox="0 0 120 120"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M95.7188 36.6572C96.0967 36.2751 96.0933 35.659 95.7112 35.2812C95.3291 34.9033 94.7131 34.9067 94.3352 35.2888L46.5281 83.6271L25.6648 62.532C25.2869 62.15 24.6709 62.1466 24.2888 62.5244C23.9067 62.9023 23.9033 63.5183 24.2812 63.9004L45.8363 85.695C46.019 85.8798 46.2682 85.9838 46.5281 85.9838C46.788 85.9838 47.0371 85.8798 47.2199 85.695L95.7188 36.6572Z"
								fill="#237847"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60ZM60 118C27.9675 118 2 92.0325 2 60C2 27.9675 27.9675 2 60 2C92.0325 2 118 27.9675 118 60C118 92.0325 92.0325 118 60 118Z"
								fill="#237847"
							/>
						</svg>

						<p>
							Sua solicitação de agendamento foi confirmada. Em breve, o IEG
							entrará em contato com você por este e-mail.
						</p>
					</>
				)}

				{((service.status === "loaded" && status === "erro") ||
					service.status === "error") && (
					<>
						<svg
							width="120"
							height="120"
							viewBox="0 0 120 120"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M60.5 60.5L35 86L33.5858 84.5858L59.0858 59.0858L35 35L36.4142 33.5858L60.5 57.6716L84.4975 33.6741L85.9117 35.0883L61.9142 59.0858L87.3259 84.4975L85.9117 85.9117L60.5 60.5Z"
								fill="#EB5757"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M60 0C26.8629 0 0 26.8629 0 60C0 93.1371 26.8629 120 60 120C93.1371 120 120 93.1371 120 60C120 26.8629 93.1371 0 60 0ZM2 60C2 92.0325 27.9675 118 60 118C92.0325 118 118 92.0325 118 60C118 27.9675 92.0325 2 60 2C27.9675 2 2 27.9675 2 60Z"
								fill="#EB5757"
							/>
						</svg>

						<p>
							Ocorreu algum erro ao enviar sua solicitação, tente novamente mais
							tarde.
							<br />
							{contact && (
								<span>
									Ou, entre em contato diretamente pelo{" "}
									<a href={`mailto:${contact.email}`}>{contact.email}</a>.
								</span>
							)}
						</p>
					</>
				)}

				<button className="btn btn-dark-outline" onClick={onHide}>
					{service.status === "loading" ? "Cancelar" : "Fechar"}
				</button>
			</Modal.Body>
		</Modal>
	);
};
