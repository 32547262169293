import React from "react";
import { Event } from "../../types/Event";
import { Link } from "react-router-dom";
import IconCalendar from "../Icons/IconCalendar";
import { createTime, dateFormatFull, parseTime } from "../../util/DateTime";
import IconPlace from "../Icons/IconPlace";
import TruncateMarkup from "react-truncate-markup";
import { ButtonShare } from "../Layout/ButtonShare";

interface CardEventProps {
	key?: number;
	large?: boolean;
	event: Event;
}

export default function CardEvent(props: CardEventProps) {
	const event = props.event;
	const large = props.large;
	const link = "/eventos-e-cursos/" + event.id;

	return (
		<div className={"card card-event" + (large ? " card-large" : "")}>
			{event.image && (
				<img
					className="card-img-top"
					src={process.env.REACT_APP_STORAGE_URL + event.image}
					alt={event.title}
				/>
			)}

			<div className="card-body">
				<div className="card-label">{event.event_type.name}</div>

				<TruncateMarkup lines={2}>
					<div className="card-title">{event.title}</div>
				</TruncateMarkup>

				{(event.date_start || event.date_end || event.location) && (
					<ul className="card-list">
						{(event.date_start || event.date_end) && (
							<li>
								<IconCalendar />

								<span>
									{dateFormatFull(event.date_start, event.date_end)}
									{event.time_start &&
										!event.date_end &&
										", às " + parseTime(createTime(event.time_start))}
								</span>
							</li>
						)}

						{event.location && (
							<li>
								<IconPlace />
								<span>{event.location}</span>
							</li>
						)}
					</ul>
				)}

				<TruncateMarkup lines={5}>
					<div className="card-description">{event.description}</div>
				</TruncateMarkup>
			</div>

			<div className="card-actions">
				<Link to={link} className="btn btn-primary-outline">
					Ver mais
				</Link>

				<ButtonShare kind="icon" size="md" link={link} />
			</div>
		</div>
	);
}
