import React, { useState, useEffect, useMemo } from "react";
// @ts-ignore
import ItemsCarousel from "react-items-carousel";
import IconArrowLeft from "../Icons/IconArrowLeft";
import IconArrowRight from "../Icons/IconArrowRight";
import { Overflow } from "./Overflow";

interface SliderProps {
	autoplay?: boolean;
	children: React.ReactNode;
	numberOf: number;
}

export default function Slider(props: SliderProps) {
	const { children } = props;
	const [activeItemIndex, setActiveItemIndex] = useState<number>(0);
	const [numberOf, setNumberOf] = useState<number>(props.numberOf);
	const [numberOfItems, setNumberOfItems] = useState<number>();
	const [dots, setDots] = useState<number[]>();

	useEffect(() => {
		if (window.innerWidth <= Overflow.mdMax) {
			setNumberOf(2);
		}

		if (window.innerWidth <= Overflow.smMax) {
			setNumberOf(1);
		}

		children && setNumberOfItems(children?.toString().split(",").length);
	}, [children]);

	useMemo(() => {
		if (numberOfItems) {
			let rows = [];
			let numberOfDots = numberOfItems / numberOf;

			for (let i = 0; i < numberOfDots; i++) {
				rows.push(i);
			}

			rows.length > 1 && setDots(rows);
		}
	}, [numberOfItems, numberOf]);

	return (
		<>
			<ItemsCarousel
				requestToChangeActive={setActiveItemIndex}
				activeItemIndex={activeItemIndex}
				infiniteLoop={false}
				slidesToScroll={numberOf}
				numberOfCards={numberOf}
				gutter={Overflow.gutter}
				firstAndLastGutter={false}
				chevronWidth={33}
				outsideChevron={true}
				leftChevron={
					<button>
						<IconArrowLeft />
					</button>
				}
				rightChevron={
					<button>
						<IconArrowRight />
					</button>
				}
				classes={{
					wrapper: "Slider",
					itemsWrapper: "slider-items",
					itemWrapper: "slider-item",
					leftChevronWrapper: "arrow arrow-left",
					rightChevronWrapper: "arrow arrow-right",
				}}
			>
				{children}
			</ItemsCarousel>

			{dots && (
				<ul className="slider-dots">
					{dots.map((key) => {
						return (
							<li
								key={key}
								className={
									"slider-dot " +
									(Math.abs(Math.round(activeItemIndex / numberOf)) === key
										? "active"
										: "")
								}
							/>
						);
					})}
				</ul>
			)}
		</>
	);
}
