import { useState, useEffect } from "react";
import { Contact } from "../../types/Contact";
import { Service } from "../../types/Service";

const apiUrl = process.env.REACT_APP_API_URL;

export interface ContactModel {
	data: Contact[];
}

export const useServiceContact = () => {
	const [service, setService] = useState<Service<ContactModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "contact")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};