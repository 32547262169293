import React from "react";
import { Language } from "../../types/Language";
import { FieldArray } from "react-final-form-arrays";

const LanguagesCheckListChildren = (props: any) => {
	const languages: Language[] = props.languages;

	const toggle = (
		event: React.ChangeEvent<HTMLInputElement>,
		option: Language
	) => {
		if (event.target.checked) {
			if (props.fields.value && props.fields.value.length) {
				const index = props.fields.value.indexOf(option.id.toString());
				if (index >= 0) props.fields.remove(index);
				else props.fields.push(option.id.toString());
			} else {
				props.fields.push(option.id.toString());
			}
		} else {
			if (props.fields.value && props.fields.value.length) {
				const index = props.fields.value.indexOf(option.id.toString());
				if (index >= 0) props.fields.remove(index);
			}
		}
	};

	return (
		<>
			{languages.map((language: Language) => {
				const checked =
					props.fields.value?.indexOf(language.id.toString()) >= 0;

				return (
					<div key={language.id} className="form-check">
						<input
							checked={checked}
							className="form-check-input"
							type="checkbox"
							id={props.fields.name + language.id}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
								toggle(event, language)
							}
						/>

						<label
							className="form-check-label"
							htmlFor={props.fields.name + language.id}
						>
							{language.title}
						</label>
					</div>
				);
			})}
		</>
	);
};

export type LanguagesCheckListProps = {
	name: string;
	languages: Language[];
};

export default function LanguagesCheckListField(
	props: LanguagesCheckListProps
) {
	return (
		<div className="form-check-list">
			<FieldArray<Language[]>
				name={props.name}
				component={LanguagesCheckListChildren}
				languages={props.languages}
			/>
		</div>
	);
}
