import React, { useState, useEffect } from "react";
import Header from "../../../components/Layout/Header";
import Footer from "../../../components/Layout/Footer";
import PageContainer from "../../../components/Layout/PageContainer";
import {
	useServiceAudiovisualDetail,
	useServiceAudiovisualsRelated,
} from "../Services";
import { Audiovisual } from "../../../types/Audiovisual";
import YouTube from "react-youtube";
import { Options } from "react-youtube";
import Slider from "../../../components/Layout/Slider";
import { Link } from "react-router-dom";
import NewsletterBanner from "../../../components/Layout/NewsletterBanner";
import Loader, { useLoaderCounter } from "../../../components/Layout/Loader";
import Metatags from "../../../components/Layout/Metatags";
import CardAudiovisual from "../../../components/Cards/CardAudiovisual";
import getYouTubeID from "get-youtube-id";
import { CedocItemType } from "../../../types/Volume";
import { ButtonShare } from "../../../components/Layout/ButtonShare";
import Page from "../../../components/Layout/Page";
import { getFiles, getFirstFileLink } from "../../../util/FileUploaded";

export default function AudiovisualDetail(props: any) {
	const id = props.match.params.id;
	const [audiovisual, setAudiovisual] = useState<Audiovisual | null>(null);
	const [videoId, setVideoId] = useState<string | null>(null);
	const [relateds, setRelateds] = useState<Audiovisual[] | null>(null);
	const loaders = useLoaderCounter(2);

	const service = useServiceAudiovisualDetail(id);
	const serviceRelated = useServiceAudiovisualsRelated(id);

	const playerOpts: Options = {
		width: "100%",
		height: "600px",
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [id]);

	useEffect(() => {
		if (service.status === "loaded") {
			setAudiovisual(service.payload.data);
		}
	}, [service]);

	useEffect(() => {
		if (audiovisual) {
			const isYoutube = audiovisual.link && audiovisual.link?.indexOf("youtu");

			isYoutube && setVideoId(getYouTubeID(audiovisual.link));
		}
	}, [audiovisual]);

	useEffect(() => {
		if (serviceRelated.status === "loaded") {
			setRelateds(serviceRelated.payload.data);
		}
	}, [serviceRelated]);

	return (
		<Page service={service} item={audiovisual}>
			<>
				{audiovisual && (
					<Metatags
						title={audiovisual.title}
						description={audiovisual.description}
					/>
				)}

				<Header />

				<main className="VideoDetail">
					{videoId && <YouTube videoId={videoId} opts={playerOpts} />}

					<PageContainer>
						{audiovisual ? (
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<nav>
											<ol className="breadcrumb">
												<li className="breadcrumb-item">
													<Link to="/cedoc">CEDOC</Link>
												</li>
												<li className="breadcrumb-item">
													<Link to="/cedoc/midias">Mídias</Link>
												</li>
												<li className="breadcrumb-item active">
													{audiovisual.title}
												</li>
											</ol>
										</nav>
									</div>
								</div>

								<div className="row">
									<div className="col-md-8 d-flex flex-column align-items-start">
										<h1 className="display-1">{audiovisual.title}</h1>

										<p className="lead-2">{audiovisual.description}</p>

										{audiovisual.institution && (
											<ul className="list-detail">
												<li>
													<div className="label">Instituição</div>

													<div className="value">
														{audiovisual.institution.title}
													</div>
												</li>
											</ul>
										)}

										<div className="buttons-spacing-actions">
											{audiovisual.link && (
												<a
													className="btn btn-lg btn-primary"
													href={audiovisual.link}
													rel="noopener noreferrer"
													target="_blank"
												>
													Acessar
												</a>
											)}

											{getFiles(
												(audiovisual as Audiovisual).file as unknown as string
											)?.length > 0 && (
												<a
													href={
														process.env.REACT_APP_STORAGE_URL! +
														getFirstFileLink((audiovisual as Audiovisual).file)
													}
													target="_blank"
													rel="noopener noreferrer"
													className="btn btn-secondary-outline"
												>
													Ler
												</a>
											)}

											<ButtonShare
												kind="text"
												size="lg"
												skin="outline"
												link={window.location.pathname}
												text="Compartilhar"
											/>
										</div>
									</div>
								</div>
							</div>
						) : (
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<Loader className="loader-breadcrumb" />
									</div>
								</div>
								<div className="row">
									<div className="col-md-8">
										<div className="loader-title">
											<Loader className="line-1" />

											<Loader className="line-2" />
										</div>

										<div className="loader-description">
											<Loader className="line-1" />

											<Loader className="line-2" />

											<Loader className="line-3" />
										</div>
									</div>
								</div>
							</div>
						)}

						<div className="container">
							<hr />

							<div className="row">
								<div className="col-md-12">
									<h2 className="mt-4">Outras mídias</h2>
								</div>
							</div>

							{relateds && relateds.length > 0 ? (
								<div className="row">
									<div className="col-md-12">
										<Slider numberOf={2}>
											{relateds?.map((related) => (
												<CardAudiovisual
													large
													key={related.id}
													audiovisual={related}
													type={CedocItemType.ONLINE}
												/>
											))}
										</Slider>
									</div>
								</div>
							) : (
								<div className="row">
									{loaders.map((key) => (
										<div className="col-md-6" key={key}>
											<Loader className="loader-card-library" />
										</div>
									))}
								</div>
							)}
						</div>
					</PageContainer>
				</main>

				<NewsletterBanner />
				<Footer />
			</>
		</Page>
	);
}
