import React, { useEffect, useState } from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import PageBanner from "../../components/Layout/PageBanner";
import PageContainer from "../../components/Layout/PageContainer";
import NewsletterBanner from "../../components/Layout/NewsletterBanner";
import IllustrationTeam from "../../components/Illustrations/IllustrationTeam";
import Metatags from "../../components/Layout/Metatags";
import { TeamMember, TeamGroup } from "../../types/Team";
import { useServiceTeam } from "./Services";
import { useServiceMetatag } from "../Services";
import { Metatag } from "../../types/Metatag";

export type CardTeamMemberProps = {
	teamMember: TeamMember;
};

const CardTeamMember = (props: CardTeamMemberProps) => {
	const { teamMember } = props;

	return (
		<div className="card-team">
			<div className="card-title">{teamMember.name}</div>

			<div className="card-description">{teamMember.description}</div>

			{teamMember.curriculum && (
				<a
					href={teamMember.curriculum}
					target="_blank"
					rel="noopener noreferrer"
				>
					Currículo
				</a>
			)}
		</div>
	);
};

export default function Team() {
	const [teamGroups, setTeamGroups] = useState<TeamGroup[]>();
	const service = useServiceTeam();
	const [metatag, setMetatag] = useState<Metatag>();
	const serviceMetatag = useServiceMetatag("institucional/equipe");
	useEffect(() => {
		if (serviceMetatag.status === "loaded") {
			setMetatag(serviceMetatag.payload.data[0]);
		}
	}, [serviceMetatag]);

	useEffect(() => {
		if (service.status === "loaded") {
			setTeamGroups(service.payload.data);
		}
	}, [service]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			{metatag && (
				<Metatags title={metatag.title} description={metatag.description} />
			)}

			<Header />

			<main className="Team">
				<PageBanner loading={!metatag}>
					<div className="container">
						<div className="row">
							<div className="col-md-7">
								<h1 className="display-1">{metatag?.title}</h1>

								<p className="lead-1">{metatag?.description}</p>
							</div>
						</div>

						<IllustrationTeam />
					</div>
				</PageBanner>

				<PageContainer>
					<div className="container">
						{teamGroups &&
							teamGroups.length > 0 &&
							teamGroups.map((teamGroup) => (
								<>
									<div className="row">
										<div className="col-md-12">
											<h3>{teamGroup.title}</h3>
										</div>
									</div>

									{teamGroup.members && teamGroup.members.length > 0 && (
										<div className="row">
											{teamGroup.members.map((member) => (
												<div className="col-md-4">
													<CardTeamMember teamMember={member} />
												</div>
											))}
										</div>
									)}

									{teamGroup.subgroups &&
										teamGroup.subgroups.length > 0 &&
										teamGroup.subgroups.map(
											(subGroup) =>
												subGroup.members &&
												subGroup.members.length > 0 && (
													<>
														<div className="row">
															<div className="col-md-12">
																<h4>{subGroup.title}</h4>
															</div>
														</div>

														<div className="row">
															{subGroup.members.map((member) => (
																<div className="col-md-4">
																	<CardTeamMember teamMember={member} />
																</div>
															))}
														</div>
													</>
												)
										)}
								</>
							))}
					</div>
				</PageContainer>
			</main>

			<NewsletterBanner />
			<Footer />
		</>
	);
}
