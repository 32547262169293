import React from "react";
import { Icon } from "../../types/Icon";

export default function IconArrowLeft(props: Icon) {
 return (
  <svg
   className="icon icon-arrow-left"
   width="19"
   height="33"
   viewBox="0 0 19 33"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    d="M18.2719 31.1994C19.2306 30.2268 19.2314 28.6649 18.2738 27.6913L8.74457 18.0031C7.78767 17.0302 7.78767 15.4698 8.74457 14.4969L18.2738 4.80875C19.2314 3.83513 19.2306 2.2732 18.2719 1.30063L17.7845 0.806167C16.8053 -0.187128 15.2028 -0.187129 14.2236 0.806166L0.730004 14.495C-0.229444 15.4683 -0.229442 17.0317 0.730006 18.005L14.2236 31.6938C15.2028 32.6871 16.8053 32.6871 17.7845 31.6938L18.2719 31.1994Z"
    fill="black"
    className="fill"
   />
  </svg>
 );
}
