import React, { useEffect, useState } from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import PageBanner from "../../components/Layout/PageBanner";
import PageContainer from "../../components/Layout/PageContainer";
import NewsletterBanner from "../../components/Layout/NewsletterBanner";
import IllustrationAbout from "../../components/Illustrations/IllustrationAbout";
import IllustrationWhoWeAre from "../../components/Illustrations/IllustrationWhoWeAre";
import IllustrationWhatWeDo from "../../components/Illustrations/IllustrationWhatWeDo";
import IllustrationAboutThread from "../../components/Illustrations/IllustrationAboutThread";
import Metatags from "../../components/Layout/Metatags";
import { useServiceAbout } from "./Services";
import { About } from "../../types/About";
import { useServiceMetatag } from "../Services";
import { Metatag } from "../../types/Metatag";
import Loader from "../../components/Layout/Loader";

export default function AboutPage() {
	const [about, setAbout] = useState<About>();
	const [metatag, setMetatag] = useState<Metatag>();
	const service = useServiceAbout();
	const serviceMetatag = useServiceMetatag("institucional/o-instituto");

	useEffect(() => {
		if (service.status === "loaded") {
			setAbout(service.payload.data[0]);
		}
	}, [service]);

	useEffect(() => {
		if (serviceMetatag.status === "loaded") {
			setMetatag(serviceMetatag.payload.data[0]);
		}
	}, [serviceMetatag]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			{metatag && (
				<Metatags title={metatag.title} description={metatag.description} />
			)}

			<Header />

			<main className="About">
				<IllustrationAboutThread />

				<PageBanner loading={!metatag}>
					<div className="container">
						<div className="row">
							<div className="col-xl-6 col-md-6">
								<h1 className="display-1">{metatag?.title}</h1>

								<p className="lead-1">{metatag?.description}</p>
							</div>
						</div>

						<IllustrationAbout />
					</div>
				</PageBanner>

				<PageContainer>
					<div className="container">
						<div className="row quem-somos">
							<div className="col-xl-5 col-lg-6 text-center">
								<h2>Quem somos</h2>

								{about?.somos ? (
									<p
										dangerouslySetInnerHTML={{
											__html: about?.somos,
										}}
									/>
								) : (
									<div className="loader-description d-flex flex-column align-items-center">
										<Loader className="line-1" />
										<Loader className="line-2" />
										<Loader className="line-3" />
										<Loader className="line-1" />
										<Loader className="line-2" />
										<Loader className="line-3" />
										<Loader className="line-2" />
									</div>
								)}
							</div>

							<div className="col-xl-7 col-lg-6 illustration-container">
								<IllustrationWhoWeAre />
							</div>
						</div>

						<div className="row o-que-fazemos">
							<div className="col-xl-7 col-md-6 illustration-container">
								<IllustrationWhatWeDo />
							</div>

							<div className="col-xl-5 col-md-6 text-center">
								<h2>O que fazemos</h2>

								{about?.fazemos ? (
									<p
										dangerouslySetInnerHTML={{
											__html: about?.fazemos,
										}}
									/>
								) : (
									<div className="loader-description d-flex flex-column align-items-center">
										<Loader className="line-1" />
										<Loader className="line-2" />
										<Loader className="line-3" />
										<Loader className="line-1" />
										<Loader className="line-2" />
										<Loader className="line-3" />
										<Loader className="line-2" />
									</div>
								)}
							</div>
						</div>

						<div className="row nossos-objetivos">
							<div className="col-xl-6 col-md-6 text-center">
								<h2>Nossos objetivos</h2>

								{about?.objetivos ? (
									<p
										dangerouslySetInnerHTML={{
											__html: about?.objetivos,
										}}
									/>
								) : (
									<div className="loader-description d-flex flex-column align-items-center">
										<Loader className="line-1" />
										<Loader className="line-2" />
										<Loader className="line-3" />
										<Loader className="line-1" />
										<Loader className="line-2" />
										<Loader className="line-3" />
										<Loader className="line-2" />
									</div>
								)}
							</div>
						</div>
					</div>
				</PageContainer>
			</main>

			<NewsletterBanner />
			<Footer />
		</>
	);
}
