import React, { useEffect, useState } from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import PageBanner from "../../components/Layout/PageBanner";
import PageContainer from "../../components/Layout/PageContainer";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { NavTab } from "react-router-tabs";
import AllContent from "./All/AllContent";
import EbooksContent from "./Books/BooksContent";
import ThesisContent from "./Thesis/ThesisContent";
import VideosContent from "./Audiovisuals/AudiovisualsContent";
import MagazinesContent from "./Magazines/MagazinesContent";
import IllustrationLibrary from "../../components/Illustrations/IllustrationLibrary";
import NewsletterBanner from "../../components/Layout/NewsletterBanner";
import Metatags from "../../components/Layout/Metatags";
import IconExternalLink from "../../components/Icons/IconExternalLink";
import LegislationsContent from "./Legislations/LegislationsContent";
import { Modal } from "react-bootstrap";
import { useServiceCedocParams } from "./Services";
import { CedocParams } from "../../types/CedocParams";
import { Metatag } from "../../types/Metatag";
import { useServiceMetatag } from "../Services";

export default function Cedoc() {
	const { path } = useRouteMatch();
	const [modalOpen, setModalOpen] = useState<boolean>();
	const [cedocParams, setCedocParams] = useState<CedocParams>();
	const service = useServiceCedocParams();

	const [metatag, setMetatag] = useState<Metatag>();
	const serviceMetatag = useServiceMetatag("cedoc");

	useEffect(() => {
		if (serviceMetatag.status === "loaded") {
			setMetatag(serviceMetatag.payload.data[0]);
		}
	}, [serviceMetatag]);

	useEffect(() => {
		if (service.status === "loaded") {
			setCedocParams(service.payload.data);
		}
	}, [service]);

	const openModal = () => setModalOpen(true);
	const closeModal = () => setModalOpen(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			{metatag && (
				<Metatags title={metatag.title} description={metatag.description} />
			)}

			<Header />

			<main className="Library">
				<PageBanner loading={!metatag} className="banner-library">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-xl-6">
								<h1 className="display-1">{metatag?.title}</h1>

								<p className="lead-1 mb-4">{metatag?.description}</p>

								<button className="btn btn-lg btn-white" onClick={openModal}>
									Saber mais
								</button>
							</div>
						</div>

						<IllustrationLibrary />
					</div>
				</PageBanner>

				<PageContainer>
					<div className="container">
						<div className="row">
							<div className="col-md-12 col-tabs">
								<nav className="nav-tabs">
									<NavTab to={`${path}/todos`}>Todos</NavTab>
									<NavTab to={`${path}/revistas`}>Revistas</NavTab>
									<NavTab to={`${path}/livros`}>Livros</NavTab>
									<NavTab to={`${path}/teses-e-dissertacoes`}>
										Teses e Dissertações
									</NavTab>
									<NavTab to={`${path}/legislacoes`}>Legislações</NavTab>
									<NavTab to={`${path}/midias`}>Mídias</NavTab>

									<a
										href="http://ieg.ufsc.br/public/storage/Cat%C3%A1logo%20Acervo%20Tito%20Sena.pdf"
										target="_blank"
										rel="noopener noreferrer"
										className="btn btn-external btn-icon-right"
									>
										Acervo Tito Sena
										<IconExternalLink />
									</a>
								</nav>
							</div>
						</div>

						<Switch>
							<Route
								exact
								path={`${path}`}
								render={() => <Redirect to={`${path}/todos`} />}
							/>

							<Route
								exact
								path={`${path}/todos`}
								render={() => (
									<AllContent
										consultationText={cedocParams?.consultation_text!}
									/>
								)}
							/>

							<Route
								exact
								path={`${path}/todos/page/:page`}
								render={() => (
									<AllContent
										consultationText={cedocParams?.consultation_text!}
									/>
								)}
							/>

							<Route
								exact
								path={`${path}/revistas`}
								render={() => (
									<MagazinesContent
										consultationText={cedocParams?.consultation_text!}
									/>
								)}
							/>
							<Route
								exact
								path={`${path}/revistas/page/:page`}
								render={() => (
									<MagazinesContent
										consultationText={cedocParams?.consultation_text!}
									/>
								)}
							/>

							<Route
								exact
								path={`${path}/livros`}
								render={() => (
									<EbooksContent
										consultationText={cedocParams?.consultation_text!}
									/>
								)}
							/>

							<Route
								exact
								path={`${path}/livros/page/:page`}
								render={() => (
									<EbooksContent
										consultationText={cedocParams?.consultation_text!}
									/>
								)}
							/>

							<Route
								exact
								path={`${path}/teses-e-dissertacoes`}
								render={() => (
									<ThesisContent
										consultationText={cedocParams?.consultation_text!}
									/>
								)}
							/>
							<Route
								exact
								path={`${path}/teses-e-dissertacoes/page/:page`}
								render={() => (
									<ThesisContent
										consultationText={cedocParams?.consultation_text!}
									/>
								)}
							/>

							<Route
								exact
								path={`${path}/midias`}
								render={() => (
									<VideosContent
										consultationText={cedocParams?.consultation_text!}
									/>
								)}
							/>

							<Route
								exact
								path={`${path}/midias/page/:page`}
								render={() => (
									<VideosContent
										consultationText={cedocParams?.consultation_text!}
									/>
								)}
							/>

							<Route
								exact
								path={`${path}/legislacoes`}
								render={() => (
									<LegislationsContent
										consultationText={cedocParams?.consultation_text!}
									/>
								)}
							/>
						</Switch>
					</div>
				</PageContainer>
			</main>

			<Modal className="modal cedoc-modal" show={modalOpen} onHide={closeModal}>
				<Modal.Body>
					{cedocParams && (
						<div className="content">
							<p
								className="paragraph"
								dangerouslySetInnerHTML={{
									__html: cedocParams.know_more,
								}}
							/>

							<button className="btn btn-primary" onClick={closeModal}>
								Fechar
							</button>
						</div>
					)}
				</Modal.Body>
			</Modal>

			<NewsletterBanner />
			<Footer />
		</>
	);
}
