import React, { useState, useEffect } from "react";
import { Pagination, Page } from "../../types/Pagination";
import { Link } from "react-router-dom";
import IconArrowLeft from "../Icons/IconArrowLeft";
import IconArrowRight from "../Icons/IconArrowRight";

export interface PaginatorProps {
	pagination: Pagination;
	path: string;
	queryParams?: string;
}

export default function Paginator(props: PaginatorProps) {
	const nVisiblePages: number = 4;
	const { pagination, path, queryParams } = props;
	const [prevPageUrl, setPrevPageUrl] = useState<string>();
	const [nextPageUrl, setNextPageUrl] = useState<string>();
	const [visiblePages, setVisiblePages] = useState<Page[]>([]);

	useEffect(() => {
		setPrevPageUrl(undefined);
		setNextPageUrl(undefined);

		if (pagination.current_page > 1) {
			setPrevPageUrl(
				path + "/page/" + (pagination.current_page - 1) + queryParams
			);
		}

		if (pagination.current_page < pagination.last_page) {
			setNextPageUrl(
				path + "/page/" + (pagination.current_page + 1) + queryParams
			);
		}

		if (pagination.last_page <= nVisiblePages) {
			setVisiblePages(
				mountPages(
					1,
					pagination.last_page + 1,
					pagination.current_page,
					path,
					queryParams
				)
			);
		} else {
			if (pagination.current_page === 1) {
				setVisiblePages(
					mountPages(
						1,
						nVisiblePages + 1,
						pagination.current_page,
						path,
						queryParams
					)
				);
			} else if (pagination.current_page === pagination.last_page - 2) {
				setVisiblePages(
					mountPages(
						pagination.current_page - 1,
						pagination.last_page + 1,
						pagination.current_page,
						path,
						queryParams
					)
				);
			} else if (pagination.current_page === pagination.last_page - 1) {
				setVisiblePages(
					mountPages(
						pagination.current_page - 2,
						pagination.last_page + 1,
						pagination.current_page,
						path,
						queryParams
					)
				);
			} else if (pagination.current_page === pagination.last_page) {
				setVisiblePages(
					mountPages(
						pagination.current_page - 3,
						pagination.last_page + 1,
						pagination.current_page,
						path,
						queryParams
					)
				);
			} else {
				setVisiblePages(
					mountPages(
						pagination.current_page - 1,
						pagination.current_page + nVisiblePages - 1,
						pagination.current_page,
						path,
						queryParams
					)
				);
			}
		}
	}, [pagination, path, queryParams]);

	if (pagination.last_page === 1) return null;

	return (
		<nav className="paginator">
			<ul className="pagination">
				{prevPageUrl && (
					<li className="page-item prev">
						<Link className="page-link" to={prevPageUrl}>
							<IconArrowLeft />
						</Link>
					</li>
				)}

				{visiblePages.map((page) => (
					<li
						key={page.id}
						className={"page-item" + (page.active ? " active" : "")}
					>
						<Link className="page-link" to={page.url}>
							{page.title}
						</Link>
					</li>
				))}

				{nextPageUrl && (
					<li className="page-item next">
						<Link className="page-link" to={nextPageUrl}>
							<IconArrowRight />
						</Link>
					</li>
				)}
			</ul>
		</nav>
	);
}

function mountPages(
	initialCount: number,
	nTotal: number,
	currentPage: number,
	path: string,
	queryParams?: string
) {
	let pages: Page[] = [];

	for (let i = initialCount; i < nTotal; i++) {
		let active = false;
		const id = i;

		if (id === currentPage) {
			active = true;
		}

		const page: Page = {
			id: id,
			title: id.toString(),
			active: active,
			url: path + "/page/" + id + queryParams,
		};
		pages.push(page);
	}

	return pages;
}
