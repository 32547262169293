import { Language } from "./Language";
import { Magazine } from "./Magazine";
import { MgzCategory } from "./MgzCategory";
import { BreadConfig } from "./BreadConfig";
import { CedocConfig } from "./CedocConfig";

export interface Volume extends CedocConfig, BreadConfig {
	id: number;
	volume?: string;
	number?: string;
	year?: string;
	title: string;
	description?: string;
	image: string;
	link?: string;
	language: Language;
	magazine: Magazine;
	magazine_id: number;
	magazine_title?: string;
	mgz_categories?: MgzCategory[];
}

export enum CedocItemType {
	ONLINE = "Eletrônico",
	FISICO = "Físico",
}
