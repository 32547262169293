import React from "react";
import { Laboratory } from "../../types/Laboratory";

interface CardLaboratoryProps {
	laboratory: Laboratory;
}

export default function CardLaboratory(props: CardLaboratoryProps) {
	const laboratory = props.laboratory;

	return (
		<div className="card card-laboratory">
			{laboratory.image && (
				<img
					className="card-img-top"
					src={process.env.REACT_APP_STORAGE_URL + laboratory.image}
					alt={laboratory.title}
				/>
			)}

			<div className="card-body">
				<div className="card-title">{laboratory.title}</div>

				{laboratory.description && (
					<p
						className="card-description"
						dangerouslySetInnerHTML={{
							__html: laboratory.description,
						}}
					/>
				)}

				{laboratory.email && (
					<ul className="card-list">
						<li>
							<svg
								className="icon icon-mail"
								width="18"
								height="14"
								viewBox="0 0 18 14"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2.10937 0H15.8906C17.0537 0 18 0.966399 18 2.15426V11.3457C18 12.5336 17.0537 13.5 15.8906 13.5H2.10937C0.946266 13.5 0 12.5336 0 11.3457V2.15426C0 0.966399 0.946266 0 2.10937 0ZM15.8906 12.0638C16.2783 12.0638 16.5937 11.7417 16.5937 11.3457V2.59475L9.24768 8.17506C9.10142 8.28617 8.89903 8.28617 8.75277 8.17506L1.40625 2.59441V11.3457C1.40625 11.7417 1.72167 12.0638 2.10937 12.0638H15.8906ZM2.23781 1.43617L8.75277 6.38516C8.89903 6.49627 9.10142 6.49627 9.24768 6.38516L15.7626 1.43617H2.23781Z"
									fill="#666666"
								/>
							</svg>

							<span>{laboratory.email}</span>
						</li>
					</ul>
				)}
			</div>

			{laboratory.link && (
				<div className="card-actions">
					<a
						target="_blank"
						href={laboratory.link}
						className="btn btn-primary"
						rel="noopener noreferrer"
					>
						Visitar página
					</a>
				</div>
			)}
		</div>
	);
}
