import React from "react";

export default function IllustrationLibrary() {
 return (
  <svg
   className="illustration illustration-library"
   width="429"
   height="409"
   viewBox="0 0 429 409"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    d="M67.4028 194.166C69.8674 164.325 78.7199 101.112 94.2563 75.5119C101.729 63.2244 110.7 50.4832 124.304 45.9272C129.954 44.2547 135.821 43.4305 141.713 43.4815C190.156 42.0417 238.047 52.7908 285.346 63.4413C293.489 65.2953 301.848 67.2282 308.729 71.942C320.756 80.206 325.902 95.235 329.116 109.416C334.281 132.314 336.628 232.705 335.563 256.176"
    fill="#C8B55D"
   />
   <path
    d="M208.255 47.9784C206.875 74.723 198.634 77.8195 185.68 101.231C172.727 124.642 156.283 145.983 139.899 167.185C128.7 181.681 117.186 196.434 101.886 206.473C84.8318 217.676 64.4255 222.252 44.3544 225.999C33.9442 227.972 22.7651 229.727 13.0845 225.408C34.102 215.033 48.3371 193.851 54.4885 171.169C60.64 148.487 59.5753 124.563 56.263 101.349C53.5224 82.1586 49.3426 62.1593 55.4743 43.7576C63.5974 19.4982 88.7356 3.83803 114.11 0.72178C139.485 -2.39447 165.037 5.00173 188.677 14.8041C195.676 17.7034 202.814 20.8789 210.365 20.9972C216.069 20.779 221.719 19.7971 227.163 18.0781C253.189 11.53 269.257 2.93075 295.5 8.59128C313.244 12.4176 320.026 21.5099 321.84 25.0009C348.102 74.9399 374.404 128.232 371.486 184.581C370.953 195.133 369.238 205.98 363.599 214.915C357.961 223.85 347.59 230.437 337.12 228.978C335.248 228.765 333.458 228.087 331.915 227.005C330.554 225.864 329.418 224.48 328.564 222.922C309.38 191.957 307.329 150.401 280.417 125.845C266.891 113.518 248.299 106.931 237.041 92.4343C225.448 77.4053 221.84 61.0548 208.176 47.8601"
    fill="#D6C785"
   />
   <path
    d="M219.907 213.475C222.373 213.474 224.834 213.691 227.261 214.126C229.919 214.762 232.512 215.64 235.01 216.749C236.584 217.271 238.025 218.133 239.229 219.273C240.222 220.579 240.897 222.099 241.201 223.711C241.97 226.689 242.601 229.628 243.172 232.744C243.586 234.737 243.646 236.786 243.35 238.799C242.581 242.922 239.249 246.077 235.759 248.424C232.843 250.379 229.706 251.981 226.414 253.197C222.169 254.645 217.819 255.759 213.401 256.53C207.089 257.985 200.676 258.96 194.217 259.449C189.032 259.706 183.235 259.075 179.785 255.189C177.52 252.247 176.406 248.579 176.65 244.874C176.494 241.182 177.638 237.553 179.883 234.618C182.979 231.048 188.184 229.825 191.181 226.157C191.568 225.748 191.847 225.248 191.991 224.704C192.136 224.16 192.142 223.588 192.009 223.041C191.831 222.331 191.339 221.542 191.536 220.792C192.029 218.958 195.656 217.459 197.234 216.729C201.768 214.772 206.631 213.688 211.567 213.534C214.308 213.376 217.108 213.396 219.907 213.475Z"
    fill="#E3DAAE"
   />
   <path
    d="M231.698 236.709C231.442 232.508 231.56 212.844 233.137 210.457C236.367 206.182 240.44 202.618 245.105 199.984C253.135 194.811 261.478 190.141 270.086 186.001C283.961 179.697 298.28 174.423 312.929 170.222C326.428 166.111 340.129 162.695 353.978 159.986C358.079 159.158 362.141 158.014 366.301 157.54C370.989 157.05 375.708 156.905 380.418 157.106C396.546 157.383 412.752 157.678 428.683 160.361C420.303 169.137 412.575 178.289 405.023 187.776C392.05 204.008 381.64 222.094 371.289 240.101C369.317 243.494 368.529 246.847 366.675 250.298C364.822 253.75 360.248 263.177 358.612 266.728C356.975 270.278 356.324 274.4 354.668 278.226C351.49 284.831 347.976 291.269 344.14 297.515C339.022 306.604 334.784 316.16 331.482 326.055C320.599 328.993 309.577 331.557 298.497 333.589C295.496 334.066 292.526 334.724 289.605 335.561C286.419 336.652 283.315 337.97 280.318 339.506C267.069 345.778 254.687 355.679 243.252 364.791C241.603 365.957 240.175 367.406 239.033 369.071C238.205 370.432 237.692 372.108 236.332 373.016C234.885 373.684 233.242 373.796 231.718 373.331C229.774 371.208 228.308 368.693 227.42 365.955C226.792 362.894 226.351 359.799 226.099 356.685L224.127 336.587"
    fill="#E3DAAE"
   />
   <path
    d="M222.195 255.248C222.107 251.035 222.463 246.823 223.259 242.685C223.322 240.796 224.08 238.998 225.389 237.636C226.966 236.334 229.648 236.551 231.698 236.708C234.529 237.155 237.422 237.014 240.196 236.294C241.934 235.618 243.583 234.731 245.105 233.652C253.135 228.478 261.478 223.808 270.085 219.668C283.96 213.364 298.28 208.09 312.929 203.889C326.428 199.779 340.129 196.362 353.978 193.653C358.079 192.825 362.14 191.681 366.301 191.207C370.989 190.708 375.708 190.563 380.417 190.773C396.545 191.05 412.752 191.345 428.683 194.028C420.303 202.805 412.574 211.956 405.023 221.443C392.05 237.675 381.64 255.761 371.289 273.768C369.317 277.161 368.529 280.514 366.675 283.965C364.822 287.417 360.248 296.844 358.611 300.395C356.975 303.945 356.324 308.067 354.668 311.893C351.494 318.494 347.979 324.926 344.14 331.163C339.026 340.26 334.787 349.822 331.482 359.722C320.598 362.66 309.577 365.224 298.496 367.256C295.495 367.733 292.526 368.391 289.605 369.228C286.419 370.319 283.315 371.637 280.318 373.173C267.069 379.445 254.687 389.346 243.252 398.458C241.603 399.623 240.175 401.073 239.032 402.738C238.204 404.099 237.692 405.775 236.331 406.682C234.884 407.351 233.242 407.463 231.718 406.998C229.769 404.871 228.303 402.348 227.42 399.602C226.791 396.548 226.349 393.459 226.099 390.352L224.127 370.254"
    fill="#F1ECD6"
   />
   <path
    d="M193.212 385.283C189.624 384.711 188.973 379.899 186.824 376.98C185.026 374.951 182.729 373.428 180.16 372.562L161.804 364.515C153.196 360.488 144.315 357.074 135.227 354.298C130.593 352.997 125.901 352.03 121.189 351.064L94.6901 345.64C79.5875 342.524 64.3863 339.407 48.988 338.579C45.5947 328.126 41.565 317.89 36.9216 307.93C35.5021 305.03 33.9839 302.17 32.8601 299.153C31.5576 295.18 30.5687 291.111 29.9027 286.983C26.5019 269.678 21.5529 252.714 15.1155 236.295C11.3891 226.848 7.22902 217.499 4.98137 207.578C4.11729 204.273 2.66101 201.153 0.683248 198.368C0.32326 197.825 0.0977503 197.204 0.0253885 196.556C-0.0469733 195.908 0.0358812 195.253 0.267118 194.644C0.498355 194.035 0.871301 193.489 1.3551 193.053C1.83889 192.617 2.41962 192.302 3.04919 192.135C45.5771 180.774 95.9126 174.858 138.007 187.244C144.898 189.17 151.618 191.663 158.097 194.699C166.319 198.644 175.586 201.661 182.644 207.46C187.356 211.405 194.04 214.185 197.017 219.491C201.394 227.262 195.42 246.965 195.736 255.88C197.498 299.026 196.656 342.239 193.212 385.283Z"
    fill="#E3DAAE"
   />
   <path
    d="M195.933 406.348C192.364 405.795 191.694 400.983 189.545 398.064C187.752 396.034 185.462 394.505 182.9 393.626L164.545 385.579C155.926 381.567 147.039 378.16 137.947 375.382C133.334 374.081 128.622 373.114 123.929 372.148L97.4306 366.704C82.3083 363.608 67.1268 360.492 51.7087 359.663C48.3308 349.207 44.3075 338.971 39.6621 329.013C38.161 326.152 36.8054 323.216 35.6006 320.217C34.2981 316.244 33.3092 312.175 32.6431 308.048C29.2474 290.745 24.2916 273.787 17.8363 257.379C14.1296 247.912 9.94978 238.583 7.72185 228.662C6.84924 225.36 5.39365 222.241 3.42373 219.451C3.06374 218.908 2.83823 218.288 2.76587 217.64C2.69351 216.992 2.77636 216.337 3.0076 215.728C3.23883 215.119 3.61178 214.573 4.09557 214.137C4.57937 213.701 5.1601 213.386 5.78967 213.219C49.2261 201.935 94.8996 202.569 138.007 215.053C144.901 216.968 151.622 219.462 158.097 222.509C166.28 226.607 173.95 231.658 180.948 237.557C185.836 241.224 189.995 245.773 193.212 250.969C197.589 258.74 198.141 268.01 198.456 276.925C200.23 320.077 199.387 363.297 195.933 406.348Z"
    fill="#F1ECD6"
   />
   <path
    d="M234.005 404.138C234.005 402.777 234.005 399.582 234.005 397.866C234.281 381.457 234.478 370.767 233.551 356.823C233.295 352.878 234.438 347.119 234.044 343.174C233.709 339.723 233.591 336.232 233.472 332.76C232.329 300.651 231.382 268.601 231.382 236.452C219.454 235.703 207.092 235.012 195.893 239.154C192.901 239.965 190.333 241.892 188.717 244.539L193.981 380.076V379.859L194.848 401.969L195.085 404.75C195.083 405.004 195.144 405.255 195.262 405.48C195.413 405.654 195.593 405.801 195.795 405.913C198.121 407.567 200.84 408.581 203.681 408.852C206.274 408.524 208.82 407.903 211.272 406.998C213.512 406.567 215.795 406.401 218.074 406.505C223.555 406.505 228.977 406.505 234.458 406.505C234.172 405.748 234.019 404.947 234.005 404.138Z"
    fill="#C8B55D"
   />
   <path
    d="M113.795 340.847C100.447 342.583 86.9415 348.165 73.9683 351.656C71.484 352.326 60.7387 352.721 58.5502 353.628C66.4367 348.382 84.1813 336.232 93.0338 332.445C97.8249 330.473 102.892 327.968 105.554 323.53C108.689 318.442 108.097 317.613 107.525 311.697C107.497 311.165 107.281 310.662 106.914 310.276C105.928 309.231 104.666 309.902 103.681 310.533L68.7238 332.958C62.9863 336.646 56.3223 339.467 49.5399 338.875C59.7297 329.349 70.8608 320.884 82.7617 313.61C88.8991 309.622 94.0392 304.278 97.7855 297.989C99.7571 294.892 102.024 293.038 101.591 289.449C101.434 288.309 100.977 287.232 100.266 286.327C99.5546 285.423 98.6153 284.725 97.5446 284.305C96.4739 283.885 95.3106 283.759 94.1746 283.938C93.0386 284.118 91.971 284.597 91.0819 285.327C75.5831 298.174 58.8856 309.501 41.2196 319.152C47.174 310.551 54.4466 302.943 62.7694 296.608C68.1914 292.506 74.0274 288.936 79.0945 284.419C84.1615 279.903 90.9242 267.911 88.8343 265.564C87.755 264.476 86.4114 263.688 84.9354 263.276C83.4594 262.865 81.9017 262.844 80.4155 263.217C77.4541 263.961 74.6763 265.304 72.253 267.162C51.3538 281.619 35.5019 295.879 22.2921 317.594C16.2786 325.779 3.38424 334.023 2.77304 344.161C2.16184 354.299 7.52465 364.081 13.124 372.74C14.6014 375.485 16.7693 377.796 19.4135 379.446C22.2043 380.632 25.276 380.994 28.2661 380.491C50.8411 378.164 69.8279 361.951 92.1269 357.77C97.3714 356.784 102.892 356.429 107.584 353.825C112.277 351.222 115.845 345.68 113.795 340.847Z"
    fill="#C8B55D"
   />
   <path
    d="M277.104 340.847C290.432 342.583 303.938 348.164 316.931 351.655C319.415 352.326 330.161 352.72 332.329 353.628C324.443 348.381 306.698 336.232 297.846 332.445C293.074 330.473 287.988 327.968 285.346 323.53C282.211 318.442 282.802 317.613 283.374 311.696C283.419 311.169 283.633 310.671 283.985 310.276C284.971 309.231 286.233 309.902 287.238 310.533L322.176 332.958C327.893 336.646 334.557 339.467 341.359 338.875C331.162 329.358 320.032 320.893 308.138 313.609C301.99 309.628 296.842 304.283 293.094 297.989C291.123 294.892 288.855 293.038 289.289 289.449C289.449 288.309 289.909 287.232 290.623 286.328C291.336 285.425 292.277 284.728 293.348 284.308C294.42 283.889 295.584 283.762 296.721 283.941C297.858 284.12 298.926 284.598 299.817 285.326C315.309 298.174 332 309.501 349.66 319.152C343.711 310.547 336.438 302.938 328.11 296.608C322.708 292.506 316.872 288.936 311.805 284.419C306.738 279.903 299.975 267.911 302.045 265.564C303.126 264.477 304.469 263.69 305.945 263.279C307.421 262.867 308.978 262.846 310.464 263.217C313.426 263.961 316.203 265.303 318.627 267.161C339.526 281.619 355.378 295.878 368.588 317.593C374.621 325.779 387.515 334.023 388.107 344.161C388.698 354.298 383.375 364.081 377.775 372.74C376.292 375.48 374.125 377.79 371.486 379.445C368.689 380.633 365.61 380.996 362.614 380.491C340.058 378.163 321.071 361.951 298.772 357.77C293.508 356.783 287.988 356.428 283.295 353.825C278.603 351.221 275.054 345.679 277.104 340.847Z"
    fill="#C8B55D"
   />
  </svg>
 );
}
