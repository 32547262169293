import React from "react";
import { Icon } from "../../types/Icon";

export default function IconArrowDown(props: Icon) {
 return (
  <svg
   className="icon icon-arrow-down"
   width={props.width || "12"}
   height={props.height || "8"}
   viewBox={
    props.width && props.height
     ? "0 0 " + props.width + " " + props.height
     : "0 0 12 8"
   }
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    d="M0.677157 0.791193C1.07264 0.364225 1.74776 0.363857 2.1437 0.790396L5.2671 4.15513C5.66275 4.58136 6.33725 4.58136 6.7329 4.15514L9.8563 0.790396C10.2522 0.363858 10.9274 0.364224 11.3228 0.791193L11.3706 0.842731C11.7257 1.22615 11.7257 1.81838 11.3706 2.2018L6.73364 7.20794C6.33787 7.63523 5.66213 7.63523 5.26636 7.20794L0.62942 2.2018C0.274276 1.81838 0.274276 1.22615 0.62942 0.842731L0.677157 0.791193Z"
    className="fill"
   />
  </svg>
 );
}
