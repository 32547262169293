import React from "react";

export default function IconPlace() {
 return (
  <svg
   className="icon icon-place"
   width="15"
   height="18"
   viewBox="0 0 15 18"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    d="M7.2 9C6.21 9 5.4 8.19 5.4 7.2C5.4 6.21 6.21 5.4 7.2 5.4C8.19 5.4 9 6.21 9 7.2C9 8.19 8.19 9 7.2 9ZM12.6 7.38C12.6 4.113 10.215 1.8 7.2 1.8C4.185 1.8 1.8 4.113 1.8 7.38C1.8 9.486 3.555 12.276 7.2 15.606C10.845 12.276 12.6 9.486 12.6 7.38ZM7.2 0C10.98 0 14.4 2.898 14.4 7.38C14.4 10.368 11.997 13.905 7.2 18C2.403 13.905 0 10.368 0 7.38C0 2.898 3.42 0 7.2 0Z"
    className="fill"
   />
  </svg>
 );
}
