import React from "react";
import { Link } from "react-router-dom";
import TruncateMarkup from "react-truncate-markup";
import { FileUploaded } from "../../types/FileUploaded";
import { Legislation } from "../../types/Legislation";
import { getFileLink } from "../../util/FileUploaded";
import { ButtonShare } from "../Layout/ButtonShare";

interface CardLegislationProps {
	large?: boolean;
	legislation: Legislation;
}

export default function CardLegislation(props: CardLegislationProps) {
	const { legislation, large } = props;

	const url = "/cedoc/legislacoes/" + legislation.id;

	return (
		<div
			className={
				"card card-library card-legislation" +
				(large ? " card-large" : " card-small")
			}
		>
			<div className="card-content">
				<div className="card-body">
					<div className="card-label">Legislações</div>

					<TruncateMarkup lines={2}>
						<div className="card-title">{legislation.title}</div>
					</TruncateMarkup>

					<TruncateMarkup lines={4}>
						<p className="card-description">{legislation.description}</p>
					</TruncateMarkup>
				</div>

				<div className="card-actions">
					<Link to={url} className="btn btn-primary-outline">
						Ver mais
					</Link>

					<a
						href={
							process.env.REACT_APP_STORAGE_URL! +
							getFileLink(
								(legislation.link as FileUploaded) || legislation.file
							)
						}
						className="btn btn-secondary-outline"
						target="_blank"
						rel="noopener noreferrer"
					>
						Ler
					</a>

					<ButtonShare kind="icon" size="md" link={url} />
				</div>
			</div>
		</div>
	);
}
