import React from "react";

export default function IllustrationNewsletterBanner() {
 return (
  <svg
   className="illustration illustration-newsletter-banner"
   width="275"
   height="361"
   viewBox="0 0 275 361"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    d="M145.986 344.158L150.481 344.131C152.352 338.566 154.367 346.713 154.832 340.864C155.172 336.673 154.109 322.829 154.832 318.686C155.352 315.727 154.638 308.279 154.88 305.282C156.547 284.498 156.741 263.622 155.461 242.81C152.403 242.81 149.743 242.274 146.679 242.274"
    fill="#66629A"
   />
   <path
    d="M149.027 241.409C148.439 279.752 147.848 321.573 147.257 359.923C142.128 360.666 138.442 360.127 133.357 359.115C132.501 320.806 131.658 276.461 130.792 238.151"
    fill="#E0E0EB"
   />
   <path
    d="M143.378 108.385C144.302 97.5578 145.416 86.652 144.173 75.8583C142.929 65.0645 139.077 54.2131 131.369 46.5444C118.821 34.0473 99.3086 32.5238 81.5899 32.293C78.5233 32.1983 75.4538 32.2856 72.3976 32.5543C71.4906 32.6527 70.587 32.7749 69.68 32.9411C63.9425 34.0032 58.5923 36.6465 53.7617 39.9209C38.2205 50.4398 27.8835 67.3346 21.8708 85.1047C15.8581 102.875 13.7656 121.694 11.7104 140.339L4.65482 204.43C3.94145 210.884 3.23149 217.341 2.52491 223.802C2.37884 225.132 2.30071 226.665 3.25866 227.602C4.03657 228.362 5.23232 228.447 6.31596 228.477C23.8207 228.986 41.1895 230.313 58.701 230.127C70.7161 229.998 82.9725 231.117 94.8687 232.8C100.949 233.662 126.461 237.795 130.367 236.784C143.252 233.459 140.055 181.136 140.242 169.938C140.587 149.393 141.632 128.876 143.378 108.385Z"
    fill="#66629A"
   />
   <path
    d="M128.003 186.32C121.854 176.042 112.737 167.936 103.762 160.003L83.6247 142.199C68.4843 128.812 25.1964 91.3007 7.94312 80.7818C7.37582 88.8271 11.5949 96.3192 15.1006 103.584C22.1833 118.26 26.7726 133.98 32.5475 149.212C37.4698 162.195 43.2582 174.834 48.6255 187.644C50.4259 191.943 52.1787 196.262 53.9316 200.579C57.9128 210.378 61.893 220.179 65.872 229.981C72.0579 229.282 78.305 230.082 84.4773 230.88L140.714 238.151L143.639 162.646C141.139 165.432 139.152 168.754 137.355 172.062C134.552 177.223 132.198 182.215 128.003 186.32Z"
    fill="#E0E0EB"
   />
   <path
    d="M85.9857 106.546C75.7506 104.28 65.264 102.858 55.4332 99.2306C49.8519 97.1743 44.5628 94.4326 39.1582 91.9454C29.1031 87.3254 18.6644 83.589 7.96021 80.7784C25.2135 91.2973 68.5014 128.813 83.6418 142.195L103.755 160.003C112.73 167.936 121.848 176.042 127.996 186.32C132.192 182.208 134.546 177.223 137.348 172.069C139.145 168.761 141.132 165.439 143.633 162.653L145.029 126.77C125.73 118.932 106.323 111.059 85.9857 106.546Z"
    fill="#C2C0D7"
   />
   <path
    d="M146.486 118.249C143.697 121.415 140.847 124.676 139.39 128.636C138.031 132.369 137.98 136.44 138.15 140.41C138.578 150.57 140.225 160.804 138.653 170.851C138.269 173.311 137.695 175.74 137.419 178.214C136.574 185.832 138.608 193.511 138.17 201.166C137.905 205.835 136.723 210.395 135.809 214.983C134.324 222.429 133.528 229.996 133.431 237.588C136.033 237.768 139.043 238.976 141.645 239.156"
    fill="#66629A"
   />
   <path
    d="M263.326 128.171C261.23 125.881 258.855 123.858 256.491 121.843C250.947 117.12 244.941 111.721 237.644 111.816C218.945 101.324 200.902 89.7057 183.615 77.0256C177.534 72.5805 169.619 66.7238 163.518 62.3059C149.156 51.9023 134.389 40.8846 118.175 33.6843C114.857 32.2116 111.487 30.8034 107.94 30.0162C103.864 29.1102 99.6517 29.0492 95.4734 29.0356C87.6977 29.0152 79.2901 29.4224 72.3976 32.5441C75.4538 32.2754 78.5233 32.1882 81.5899 32.2829C99.3086 32.5238 118.821 34.0473 131.369 46.5343C139.074 54.2029 142.919 65.0544 144.173 75.8481C145.426 86.6419 144.302 97.5476 143.378 108.375C141.632 128.843 140.585 149.356 140.239 169.914C140.052 181.112 143.249 233.449 130.364 236.76C126.444 237.778 100.946 233.639 94.8653 232.777C82.9758 231.08 70.7127 229.974 58.6975 230.103C41.186 230.289 23.8172 228.963 6.31252 228.454C5.22549 228.423 4.02975 228.338 3.25523 227.578C2.29728 226.642 2.37541 225.108 2.52148 223.778C3.23485 217.322 3.94709 210.865 4.65819 204.406C1.39028 212.804 -0.776997 221.644 0.262482 230.462C17.313 233.235 34.5606 234.622 51.8356 234.609C57.1893 234.609 62.5599 234.47 67.8796 235.077C75.0914 235.905 82.0756 238.09 89.2399 239.261C107.903 242.315 128.438 237.066 146.228 243.482C164.018 249.899 183.737 246.875 202.193 250.801C211.572 252.803 220.479 256.57 229.753 259.03C233.174 259.936 236.639 260.659 240.1 261.385L253.009 264.099C255.557 264.632 258.155 265.168 260.74 264.87C263.326 264.571 266.974 263.095 268.214 260.798C267.585 257.886 268.421 254.887 268.893 251.945C270.252 243.234 268.244 234.3 269.423 225.549C269.763 223.12 270.327 220.727 270.782 218.318C272.351 209.974 272.48 201.424 273.802 193.036C275.184 184.264 274.318 175.754 274.852 166.908C275.643 153.094 272.664 138.388 263.326 128.171Z"
    fill="#C2C0D7"
   />
   <path
    d="M192.539 146.735C192.923 141.71 195.661 137.204 197.074 132.365C198.12 128.782 198.433 125.036 198.728 121.314C200.016 105.091 201.011 88.8474 202.01 72.6042L204.004 40.172L206.473 0C215.04 2.44007 222.475 7.81149 227.477 15.1744C228.435 16.5995 229.315 18.1366 229.57 19.8332C229.773 21.1905 229.57 22.5478 229.376 23.8813C228.255 31.4685 227.135 39.0546 226.016 46.6395C222.21 42.9942 217.759 40.0867 212.89 38.0649C208.895 67.5065 206.374 97.1031 205.325 126.855C205.25 128.955 205.183 131.062 205.267 133.163C205.461 138.039 206.463 142.874 206.351 147.753C206.259 151.91 205.159 160.05 199.35 159.406C194.047 158.819 192.216 151.018 192.539 146.735Z"
    fill="#A3A1C2"
   />
   <path
    d="M216.98 234.544C212.251 236.742 207.026 237.635 201.854 237.53C187.027 237.221 177.541 223.747 178.884 209.618C180.549 192.076 194.35 179.679 212.219 182.614C224.268 184.592 235.608 196.374 234.178 209.558C233.247 218.165 227.116 224.921 217.717 224.639C214.385 224.539 211.488 222.276 212.201 217.515L211.866 217.484C208.965 221.474 204.378 224.169 199.434 223.735C193.801 223.242 190.892 217.861 191.343 212.595C192.241 202.114 202.311 194.246 212.927 195.818C216.465 196.343 219.658 197.371 221.765 198.532C220.338 203.073 218.924 207.62 217.524 212.171C215.688 217.94 216.56 220.868 219.179 220.92C225.344 221.045 229.026 213.046 229.664 207.625C231.007 196.107 221.833 187.945 211.208 186.135C195.973 183.542 185.064 195.276 183.679 209.73C182.506 221.974 190.59 233.833 203.132 233.702C207.43 233.658 211.909 233.242 215.982 231.223C216.376 232.558 216.579 233.221 216.98 234.544ZM215.666 200.906C214.336 200.379 212.942 200.032 211.521 199.871C203.748 198.936 197.543 205.452 196.881 212.853C196.549 216.459 198.341 219.435 201.836 219.514C206.807 219.628 211.69 214.384 213.053 209.54C214.095 206.071 214.618 204.344 215.666 200.906Z"
    fill="#E0E0EB"
   />
   <path
    d="M199.737 154.278C200.349 154.957 201.493 154.835 202.2 154.278C202.906 153.722 203.27 152.833 203.559 151.978C204.204 150.074 206.616 139.27 201.3 141.554C199.319 142.396 198.599 153.033 199.737 154.278Z"
    fill="#C2C0D7"
   />
  </svg>
 );
}
