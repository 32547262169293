import React, { useState, useEffect } from "react";
import Header from "../../../components/Layout/Header";
import Footer from "../../../components/Layout/Footer";
import PageContainer from "../../../components/Layout/PageContainer";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
	useServiceCedocParams,
	useServiceMagazinesRelated,
	useServiceMagazineWithVolumesDetail,
} from "../Services";
import { Magazine } from "../../../types/Magazine";
import NewsletterBanner from "../../../components/Layout/NewsletterBanner";
import CardVolume from "../../../components/Cards/CardVolume";
import Slider from "../../../components/Layout/Slider";
import CardMagazine from "../../../components/Cards/CardMagazine";
import Metatags from "../../../components/Layout/Metatags";
import Loader, { useLoaderCounter } from "../../../components/Layout/Loader";
import { Field, Form } from "react-final-form";
import CedocItemTypeCheckListField from "../../../components/Filter/CedocItemTypeCheckListField";
import { CedocItemType, Volume } from "../../../types/Volume";
import arrayMutators from "final-form-arrays";
import { usePrevious } from "react-use";
import { Pagination } from "../../../types/Pagination";
import NoResult from "../../../components/Layout/NoResult";
import { CedocParams } from "../../../types/CedocParams";
import { CedocVolume } from "../../../types/CedocVolume";
import Paginator from "../../../components/Layout/Paginator";
import Page from "../../../components/Layout/Page";

interface MagazineVolumesFilterModel {
	q?: string;
	languages?: string[];
	itemType?: CedocItemType[];
}

export default function MagazineVolumes(props: any) {
	const history = useHistory();
	const location = useLocation();
	const { id, page } = useParams();
	const queryParams = location.search;
	const prev = usePrevious(location.pathname);
	const [prevIncludesPath, setPrevIncludesPath] = useState<boolean>(false);

	const [cedocParams, setCedocParams] = useState<CedocParams>();
	const [magazine, setMagazine] = useState<Magazine>();
	const [volumes, setVolumes] = useState<(CedocVolume | Volume)[]>();
	const [magazinesPaginatorData, setMagazinesPaginatorData] =
		useState<Pagination>();
	const [filtered, setFiltered] = useState<boolean>(false);
	const [relateds, setRelated] = useState<Magazine[]>();
	const loaders = useLoaderCounter(2);

	const service = useServiceMagazineWithVolumesDetail(id, page, queryParams);
	const serviceMagazinesRelated = useServiceMagazinesRelated(id);
	const serviceCedoc = useServiceCedocParams();

	const searchParams = new URLSearchParams(queryParams);

	const initialFilterData: MagazineVolumesFilterModel = {
		q: searchParams.get("q") || undefined,
		languages: searchParams.get("languages")?.split(","),
		itemType: searchParams.get("itemType")?.split(",") as CedocItemType[],
	};

	const onFilter = (values: MagazineVolumesFilterModel) => {
		const searchParams = new URLSearchParams(queryParams);

		searchParams.delete("q");
		if (values.q) {
			searchParams.set("q", values.q);
		}

		searchParams.delete("languages");
		if (values.languages?.length) {
			searchParams.set("languages", values.languages.toString());
		}

		searchParams.delete("itemType");
		if (values.itemType?.length) {
			searchParams.set("itemType", values.itemType.toString());
		}

		return (
			magazine &&
			history.push(
				"/cedoc/revistas/" + magazine.id + "/volumes?" + searchParams.toString()
			)
		);
	};

	useEffect(() => {
		if (initialFilterData.q || initialFilterData.languages) {
			setFiltered(true);
		}
	}, [initialFilterData]);

	useEffect(() => {
		if (service.status === "loaded") {
			const paginatorData: Pagination = {
				current_page: service.payload.data.volumes?.current_page,
				first_page_url: service.payload.data.volumes?.first_page_url,
				from: service.payload.data.volumes?.from,
				last_page: service.payload.data.volumes?.last_page,
				last_page_url: service.payload.data.volumes?.last_page_url,
				next_page_url: service.payload.data.volumes?.next_page_url,
				path: service.payload.data.volumes?.path,
				per_page: service.payload.data.volumes?.per_page,
				prev_page_url: service.payload.data.volumes?.prev_page_url,
				to: service.payload.data.volumes?.to,
				total: service.payload.data.volumes?.total,
			};

			setMagazinesPaginatorData(paginatorData);
			setVolumes(service.payload.data.volumes?.data);
			setMagazine(service.payload.data.magazine);
		}
	}, [service]);

	useEffect(() => {
		if (prevIncludesPath && page) window.scrollTo(0, 0);
	}, [page, prevIncludesPath]);

	useEffect(() => {
		magazine &&
			setPrevIncludesPath(
				!!prev?.includes("/revistas/" + magazine.id + "/volumes")
			);
	}, [prev, magazine]);

	useEffect(() => {
		if (serviceMagazinesRelated.status === "loaded") {
			setRelated(serviceMagazinesRelated.payload.data);
		}
	}, [serviceMagazinesRelated]);

	useEffect(() => {
		if (serviceCedoc.status === "loaded") {
			setCedocParams(serviceCedoc.payload.data);
		}
	}, [serviceCedoc]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [id]);

	return (
		<Page service={service} item={magazine}>
			<>
				{magazine && (
					<Metatags title={magazine.title} description={magazine.description} />
				)}

				<Header />

				<main className="MagazineVolumes">
					<PageContainer>
						{magazine ? (
							<div className="container p-0">
								<div className="row">
									<div className="col-md-12">
										<nav>
											<ol className="breadcrumb">
												<li className="breadcrumb-item">
													<Link to="/cedoc">CEDOC</Link>
												</li>
												<li className="breadcrumb-item">
													<Link to="/cedoc/revistas">Revistas</Link>
												</li>
												<li className="breadcrumb-item">
													<Link to={"/cedoc/revistas/" + magazine.id}>
														{magazine.title}
													</Link>
												</li>
												<li className="breadcrumb-item active">Volumes</li>
											</ol>
										</nav>
									</div>
								</div>

								<div className="row">
									<div className="col-md-9">
										<h1 className="display-1">Volumes de {magazine.title}</h1>
									</div>
								</div>
							</div>
						) : (
							<div className="container p-0">
								<div className="row">
									<div className="col-md-12">
										<Loader className="loader-breadcrumb" />
									</div>
								</div>
								<div className="row">
									<div className="col-md-8">
										<div className="loader-title">
											<Loader className="line-1" />
										</div>
									</div>
								</div>
							</div>
						)}

						<div className="container p-0">
							<div className="row">
								<div className="col-md-12">
									<div className="filter">
										<Form<MagazineVolumesFilterModel>
											onSubmit={onFilter}
											mutators={{ ...arrayMutators }}
											initialValues={initialFilterData}
											render={({ handleSubmit }) => (
												<form onSubmit={handleSubmit}>
													<div className="filter-content">
														<div className="filter-group filter-text">
															<label htmlFor="filter-text">Pesquisar</label>

															<Field
																id="filter-text"
																type="text"
																name="q"
																component="input"
																className="form-control"
																placeholder="Busque por título, assunto, palavras-chave..."
															/>
														</div>

														<div className="filter-group">
															<label htmlFor="filter-type">Formato</label>

															<CedocItemTypeCheckListField
																id="filter-type"
																name="itemType"
															/>
														</div>

														<div className="filter-group">
															<label />

															<button
																className="btn btn-primary"
																onClick={handleSubmit}
															>
																Filtrar
															</button>
														</div>
													</div>
												</form>
											)}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="container p-0">
							<div className="row">
								<div className="col-md-12">
									{magazine && magazinesPaginatorData ? (
										volumes && volumes.length > 0 ? (
											<>
												<div className="row">
													<div className="col-md-12">
														<div className="row">
															{volumes?.map((volume, key) => (
																<div className="col-md-6" key={key}>
																	<CardVolume
																		volume={volume}
																		consultationText={
																			cedocParams?.consultation_text
																		}
																	/>
																</div>
															))}
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-md-12">{/* <Paginator /> */}</div>
												</div>
											</>
										) : (
											<NoResult filter={filtered} />
										)
									) : (
										<div className="row">
											{[1, 2, 3, 4, 5, 6, 7, 8].map((key) => (
												<div className="col-md-6" key={key}>
													<Loader className="loader-card-library card-large" />
												</div>
											))}
										</div>
									)}
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									{magazine &&
										volumes &&
										volumes.length > 0 &&
										magazinesPaginatorData && (
											<Paginator
												path={"/cedoc/revistas/" + magazine.id + "/volumes"}
												pagination={magazinesPaginatorData}
												queryParams={queryParams}
											/>
										)}
								</div>
							</div>

							<hr />

							<div className="row">
								<div className="col-md-12">
									<h2 className="mt-4">Outras revistas</h2>
								</div>
							</div>

							{relateds && relateds.length > 0 ? (
								<div className="row">
									<div className="col-md-12">
										<Slider numberOf={2}>
											{relateds?.map((magazine) => (
												<CardMagazine key={magazine.id} magazine={magazine} />
											))}
										</Slider>
									</div>
								</div>
							) : (
								<div className="row">
									{loaders.map((key) => (
										<div className="col-md-6" key={key}>
											<Loader className="loader-card-library" />
										</div>
									))}
								</div>
							)}
						</div>
					</PageContainer>
				</main>

				<NewsletterBanner />
				<Footer />
			</>
		</Page>
	);
}
