import React from "react";
import { Link } from "react-router-dom";
import { New } from "../../types/New";
import { dateFormatFull } from "../../util/DateTime";
import TruncateMarkup from "react-truncate-markup";
import { ButtonShare } from "../Layout/ButtonShare";

interface CardNewProps {
	key?: number;
	large?: boolean;
	new: New;
}

export default function CardNew(props: CardNewProps) {
	const news = props.new;
	const large = props.large;
	const link = "/noticias/" + news.id;

	return (
		<div className={"card card-new" + (large ? " card-large" : "")}>
			{news.image && (
				<img
					className="card-img-top"
					src={process.env.REACT_APP_STORAGE_URL + news.image}
					alt={news.title}
				/>
			)}

			<div className="card-body">
				{news.created_at &&
					(large ? (
						<div className="card-label">
							{"Publicada em " + dateFormatFull(news.created_at)}
						</div>
					) : (
						<div className="card-label">
							{"Em " + dateFormatFull(news.created_at)}
						</div>
					))}

				<TruncateMarkup lines={2}>
					<div className="card-title">{news.title}</div>
				</TruncateMarkup>

				<TruncateMarkup lines={5}>
					<div className="card-description">{news.description}</div>
				</TruncateMarkup>
			</div>

			<div className="card-actions">
				<Link to={link} className="btn btn-primary-outline">
					Ler
				</Link>

				<ButtonShare kind="icon" size="md" link={link} />
			</div>
		</div>
	);
}
