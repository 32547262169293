import { Service } from "../../types/Service";
import { useEffect, useState } from "react";
import { New } from "../../types/New";
import { Pagination } from "../../types/Pagination";

const apiUrl = process.env.REACT_APP_API_URL;

export interface NewModel {
	data: New;
}

export const useServiceNew = (id: number) => {
	const [service, setService] = useState<Service<NewModel>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "news/" + id)
			.then((response) => response.json())
			.then((response) => {
				setService({ status: "loaded", payload: response });
			})
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};

export interface NewsModel extends Pagination {
	data: New[];
}

export interface NewsPaginatorModel {
	data: NewsModel;
}

export const useServiceNews = (page: number, queryParams?: string) => {
	const [service, setService] = useState<Service<NewsPaginatorModel>>({
		status: "loading",
	});

	if (!queryParams) queryParams = "";

	useEffect(() => {
		if (!page) {
			fetch(apiUrl + "news" + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		} else {
			fetch(apiUrl + "news/page/" + page + queryParams)
				.then((response) => response.json())
				.then((response) => setService({ status: "loaded", payload: response }))
				.catch((error) => setService({ status: "error", error }));
		}
	}, [page, queryParams]);

	return service;
};

interface NewsLatestProps {
	data: New[];
}

export const useServiceNewsLatest = () => {
	const [service, setService] = useState<Service<NewsLatestProps>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "news/latest")
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, []);

	return service;
};

export const useServiceNewsRelated = (id: number) => {
	const [service, setService] = useState<Service<NewsLatestProps>>({
		status: "loading",
	});

	useEffect(() => {
		fetch(apiUrl + "news/related/" + id)
			.then((response) => response.json())
			.then((response) => setService({ status: "loaded", payload: response }))
			.catch((error) => setService({ status: "error", error }));
	}, [id]);

	return service;
};
