import React from "react";
import { Icon } from "../../types/Icon";

export default function IconCalendar(props: Icon) {
 return (
  <svg
   className="icon icon-calendar"
   width="16"
   height="17"
   viewBox="0 0 16 17"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.55858 0.790638V1.4032L10.4596 1.40321V0.788374C10.4664 0.350241 10.8318 0 11.2797 0C11.7311 0 12.0988 0.355871 12.0998 0.798815V1.40306L13.2221 1.40321C14.753 1.40323 16 2.6166 16 4.11201V14.2912C16 15.7866 14.753 17 13.2221 17H2.77788C1.247 17 0 15.7866 0 14.2912V4.11201C0 2.61662 1.24695 1.40326 2.77779 1.40321H3.91829V0.788373C3.92512 0.350242 4.29055 0 4.73839 0C5.18979 0 5.55858 0.303333 5.55858 0.790638ZM5.40898 0.745153C5.4104 0.761568 5.41119 0.778152 5.41134 0.794883V0.790638C5.41134 0.775524 5.41054 0.760348 5.40898 0.745153ZM5.55858 3.00477V3.60143C5.55749 4.04437 5.18979 4.4006 4.73839 4.4006C4.29055 4.4006 3.92514 4.05036 3.9183 3.61223L3.91827 3.60996L3.91829 3.00477H2.77788C2.1495 3.00477 1.64038 3.50307 1.64038 4.11201V5.13527H14.3596V4.11201C14.3596 3.53357 13.8998 3.05483 13.3152 3.00844L13.2691 3.00479H13.2293L13.2221 3.00477H12.0998V3.60143C12.0988 4.04437 11.7311 4.4006 11.2797 4.4006C10.8318 4.4006 10.4664 4.05036 10.4596 3.61223L10.4595 3.60996L10.4596 3.00477H5.55858ZM13.2221 15.3984C13.8501 15.3984 14.3596 14.9001 14.3596 14.2912V6.73683H1.64038V14.2912C1.64038 14.9001 2.1495 15.3984 2.77788 15.3984H13.2221Z"
    className="fill"
   />
  </svg>
 );
}
