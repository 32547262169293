import React from "react";
import { Link } from "react-router-dom";
import { Magazine } from "../../types/Magazine";
import TruncateMarkup from "react-truncate-markup";
import NoFolder from "../../assets/no-folder.svg";
import IconCedocBook from "../Icons/IconCedocBook";
import IconEbook from "../Icons/IconEbook";
import { ButtonShare } from "../Layout/ButtonShare";

interface CardMagazineProps {
	large?: boolean;
	magazine: Magazine;
}

export default function CardMagazine(props: CardMagazineProps) {
	const magazine = props.magazine;
	const large = props.large;

	const url = "/cedoc/revistas/" + magazine.id;

	return (
		<div
			className={
				"card card-library card-magazine" +
				(large ? " card-large" : " card-small")
			}
		>
			{magazine.image ? (
				<img
					className="card-folder"
					src={process.env.REACT_APP_STORAGE_URL + magazine.image}
					alt={magazine.title}
				/>
			) : (
				<img className="card-folder" src={NoFolder} alt="Capa não cadastrada" />
			)}

			<div className="card-content">
				<div className="card-body">
					<div className="card-label">Revista</div>

					<TruncateMarkup lines={2}>
						<div className="card-title">{magazine.title}</div>
					</TruncateMarkup>

					{magazine.description && (
						<TruncateMarkup lines={3}>
							<p className="card-description">{magazine.description}</p>
						</TruncateMarkup>
					)}

					<ul className="volumes-counter-list">
						{!!(
							magazine.nVolumesOnline && Number(magazine.nVolumesOnline) > 0
						) && (
							<li>
								<IconEbook />

								<span>
									{magazine.nVolumesOnline} volume
									{Number(magazine.nVolumesOnline) > 1 ? "s" : ""} eletrônico
									{Number(magazine.nVolumesOnline) > 1 ? "s" : ""}
								</span>
							</li>
						)}

						{!!(
							magazine.nVolumesFisicos && Number(magazine.nVolumesFisicos) > 0
						) && (
							<li>
								<IconCedocBook />

								<span>
									{magazine.nVolumesFisicos} volume
									{Number(magazine.nVolumesFisicos) > 1 ? "s" : ""} físico
									{Number(magazine.nVolumesFisicos) > 1 ? "s" : ""}
								</span>
							</li>
						)}
					</ul>
				</div>

				<div className="card-actions">
					<Link to={url} className="btn btn-primary-outline">
						Ver mais
					</Link>

					<ButtonShare kind="icon" size="md" link={url} />
				</div>
			</div>
		</div>
	);
}
