import React from "react";
import { Icon } from "../../types/Icon";

export default function IconArrowRight(props: Icon) {
 return (
  <svg
   className="icon icon-arrow-right"
   width="19"
   height="33"
   viewBox="0 0 19 33"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    d="M0.728121 1.30063C-0.230583 2.2732 -0.231436 3.83513 0.726208 4.80875L10.2554 14.4969C11.2123 15.4698 11.2123 17.0302 10.2554 18.0031L0.726204 27.6913C-0.231438 28.6649 -0.230583 30.2268 0.728119 31.1994L1.21554 31.6938C2.19467 32.6871 3.79723 32.6871 4.77636 31.6938L18.27 18.005C19.2294 17.0317 19.2294 15.4683 18.27 14.495L4.77636 0.806164C3.79723 -0.18713 2.19467 -0.18713 1.21554 0.806168L0.728121 1.30063Z"
    fill="black"
    className="fill"
   />
  </svg>
 );
}
