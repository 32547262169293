import React, { useState, useEffect } from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import PageBanner from "../../components/Layout/PageBanner";
import CardEvent from "../../components/Cards/CardEvent";
import Paginator from "../../components/Layout/Paginator";
import PageContainer from "../../components/Layout/PageContainer";
import IllustrationEvents from "../../components/Illustrations/IllustrationEvents";
import { Event, EventType } from "../../types/Event";
import { useServiceEvents, useServiceEventsTypes } from "../Events/Services";
import { Pagination } from "../../types/Pagination";
import NewsletterBanner from "../../components/Layout/NewsletterBanner";
import { Form, Field } from "react-final-form";
import { FormApi } from "final-form";
import { useHistory } from "react-router-dom";
import arrayMutators from "final-form-arrays";
import EventTypesCheckListField from "../../components/Filter/EventTypesCheckListField";
import Loader from "../../components/Layout/Loader";
import NoResult from "../../components/Layout/NoResult";
import Metatags from "../../components/Layout/Metatags";
import { Metatag } from "../../types/Metatag";
import { useServiceMetatag } from "../Services";

interface EventsFilterModel {
	q?: string;
	types?: string[];
	dateStart?: string;
	dateEnd?: string;
	onlyNext?: string;
	place?: string;
}

export default function Events(props: any) {
	const history = useHistory();
	const page = props.match.params.page;
	const queryParams = props.location.search;

	let loaders = [];
	for (let i = 0; i < 8; i++) {
		loaders.push(i);
	}

	const [events, setEvents] = useState<Event[]>();
	const [eventTypes, setEventTypes] = useState<EventType[]>();
	const [filtered, setFiltered] = useState<boolean>(false);

	const [paginatorData, setPaginatorData] = useState<Pagination>();
	const service = useServiceEvents(page, queryParams);
	const serviceEventTypes = useServiceEventsTypes();

	const [metatag, setMetatag] = useState<Metatag>();
	const serviceMetatag = useServiceMetatag("eventos-e-cursos");

	const searchParams = new URLSearchParams(queryParams);

	const initialFilterData: EventsFilterModel = {
		q: searchParams.get("q") || undefined,
		types: searchParams.get("types")?.split(","),
		dateStart: searchParams.get("dateStart") || undefined,
		dateEnd: searchParams.get("dateEnd") || undefined,
		onlyNext: searchParams.get("onlyNext") || undefined,
		place: searchParams.get("place") || undefined,
	};

	const onFilter = (values: EventsFilterModel, form: FormApi) => {
		const searchParams = new URLSearchParams(queryParams);

		searchParams.delete("q");
		if (values.q) {
			searchParams.set("q", values.q);
		}

		searchParams.delete("types");
		if (values.types?.length) {
			searchParams.set("types", values.types.toString());
		}

		searchParams.delete("dateStart");
		if (values.dateStart) {
			searchParams.set("dateStart", values.dateStart);
		}

		searchParams.delete("dateEnd");
		if (values.dateEnd) {
			searchParams.set("dateEnd", values.dateEnd);
		}

		searchParams.delete("onlyNext");
		if (values.onlyNext) {
			searchParams.set("onlyNext", values.onlyNext);
		}

		searchParams.delete("place");
		if (values.place && values.place !== "all") {
			searchParams.set("place", values.place);
		}

		return history.push("/eventos-e-cursos?" + searchParams.toString());
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [page]);

	useEffect(() => {
		if (service.status === "loaded") {
			const paginatorData: Pagination = {
				current_page: service.payload.data.current_page,
				first_page_url: service.payload.data.first_page_url,
				from: service.payload.data.from,
				last_page: service.payload.data.last_page,
				last_page_url: service.payload.data.last_page_url,
				next_page_url: service.payload.data.next_page_url,
				path: service.payload.data.path,
				per_page: service.payload.data.per_page,
				prev_page_url: service.payload.data.prev_page_url,
				to: service.payload.data.to,
				total: service.payload.data.total,
			};

			setPaginatorData(paginatorData);
			setEvents(service.payload.data.data);
		}
	}, [service]);

	useEffect(() => {
		if (serviceEventTypes.status === "loaded") {
			setEventTypes(serviceEventTypes.payload.data);
		}
	}, [serviceEventTypes]);

	useEffect(() => {
		if (serviceMetatag.status === "loaded") {
			setMetatag(serviceMetatag.payload.data[0]);
		}
	}, [serviceMetatag]);

	useEffect(() => {
		if (
			initialFilterData.q ||
			initialFilterData.types ||
			initialFilterData.place ||
			initialFilterData.onlyNext ||
			initialFilterData.dateStart ||
			initialFilterData.dateEnd
		) {
			setFiltered(true);
		}
	}, [initialFilterData]);

	return (
		<>
			{metatag && (
				<Metatags title={metatag.title} description={metatag.description} />
			)}

			<Header />

			<main className="Events">
				<PageBanner loading={!metatag}>
					<div className="container">
						<div className="row">
							<div className="col-md-6">
								<h1 className="display-1">{metatag?.title}</h1>

								<p className="lead-1">{metatag?.description}</p>
							</div>
						</div>

						<IllustrationEvents />
					</div>
				</PageBanner>

				<PageContainer>
					<div className="container">
						<div className="row">
							{events && paginatorData ? (
								<>
									{events.length > 0 ? (
										<div className="col-md-8">
											{events?.map((event) => (
												<CardEvent large key={event.id} event={event} />
											))}
										</div>
									) : (
										<div className="col-md-8">
											<NoResult filter={filtered} />
										</div>
									)}
								</>
							) : (
								<div className="col-md-8">
									{loaders?.map((loader) => (
										<Loader
											key={loader}
											className="loader-card-event card-large"
										/>
									))}
								</div>
							)}

							<div className="col-md-4">
								<div className="card filter">
									<Form<EventsFilterModel>
										onSubmit={onFilter}
										mutators={{ ...arrayMutators }}
										initialValues={initialFilterData}
										render={({ handleSubmit }) => (
											<form onSubmit={handleSubmit}>
												<div className="card-filter">
													<div className="card-body">
														<h5 className="mt-4">Pesquisar</h5>

														<Field
															type="text"
															name="q"
															component="input"
															className="form-control"
															placeholder="Busque por assunto, palavras-chave..."
														/>

														{eventTypes && eventTypes.length > 1 && (
															<>
																<h5 className="mt-2">Tipo de Evento</h5>

																<EventTypesCheckListField
																	name="types"
																	eventTypes={eventTypes}
																/>
															</>
														)}

														<button
															className="btn btn-primary"
															onClick={handleSubmit}
														>
															Filtrar
														</button>
													</div>
												</div>
											</form>
										)}
									/>
								</div>
							</div>
						</div>

						{events && paginatorData && events.length > 0 && (
							<div className="row">
								<div className="col-md-12">
									<Paginator
										path="/eventos-e-cursos"
										pagination={paginatorData}
										queryParams={queryParams}
									/>
								</div>
							</div>
						)}
					</div>
				</PageContainer>
			</main>

			<NewsletterBanner />
			<Footer />
		</>
	);
}
