import React from "react";

export default function IconShare() {
	return (
		<svg
			width="16"
			height="20"
			viewBox="0 0 15 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="icon"
		>
			<path
				d="M10.1077 4.47384L7.18032 1.54651L4.25299 4.47384L3.47974 3.70059L7.18032 0L10.8809 3.70059L10.1077 4.47384Z"
				fill="#666666"
				className="fill"
			/>
			<path
				d="M6.62793 0.773315H7.73258V12.3722H6.62793V0.773315Z"
				fill="#666666"
				className="fill"
			/>
			<path
				d="M12.7035 19.0001H1.65698C0.718024 19.0001 0 18.2821 0 17.3431V7.40124C0 6.46229 0.718024 5.74426 1.65698 5.74426H5.52326V6.84892H1.65698C1.32558 6.84892 1.10465 7.06985 1.10465 7.40124V17.3431C1.10465 17.6745 1.32558 17.8954 1.65698 17.8954H12.7035C13.0349 17.8954 13.2558 17.6745 13.2558 17.3431V7.40124C13.2558 7.06985 13.0349 6.84892 12.7035 6.84892H8.83722V5.74426H12.7035C13.6425 5.74426 14.3605 6.46229 14.3605 7.40124V17.3431C14.3605 18.2821 13.6425 19.0001 12.7035 19.0001Z"
				fill="#666666"
				className="fill"
			/>
		</svg>
	);
}
